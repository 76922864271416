
import {Deserializable} from "./deserializable.model";
import { BaseForm } from "./BaseForm"

export class DropdownForm extends BaseForm {

  form_no:string;
  type:string = 'dropdown';
  description:string;
  question:string;
  choice_list:any [];
  

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
