import { Component, OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { ModalService } from '../helper/modal';
import { ElementService } from '../service/';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AboutComponent implements OnInit {

    menuTopLeftPosition =  {x: '0', y: '0'}
    @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

    app_name = environment.app_name
    apiUrl:string =environment.apiUrl

    innerHTMLEdited : any = {elementName:'',innerHTML:''};
    headerEdited : string = '';
    typeEdited : string = '';

    element_text_about_1 : any = null;
    element_text_about_2: any =null;
    element_text_about_3: any =null;
    element_text_about_4: any =null;

    element_image_1 : any = null;
    element_image_2 : any = null;
    element_image_3 : any = null;
    element_image_4 : any = null;

    constructor(private modalService: ModalService,private elementService:ElementService) {

    }

 
  ngOnInit(): void {
 
  }



  // getElementImage(element_name): Observable<string> {

  //   var subject = new Subject<string>();
  //   this.elementService.get_elementImage(element_name).pipe(first())
  //   .subscribe({
  //       next: (response:any) => {

  //         if (response.serviceStatus = 'success')
  //         {
  //           subject.next(response.data);
  //         }
  //         else
  //         {
  //           subject.next(null);
  //         }
          
  //       },
  //       error: error => {
  //       }
  //   });

  //   return subject.asObservable();
  // }
  // getElementText(element_name): Observable<string> {

  //   var subject = new Subject<string>();
  //   this.elementService.get_elementText(element_name).pipe(first())
  //   .subscribe({
  //       next: (response:any) => {
    
  //         if (response.serviceStatus = 'success')
  //         {
  //           subject.next(response.data);
  //         }
  //         else
  //         {
  //           subject.next(null);
  //         }
          
  //       },
  //       error: error => {
  //       }
  //   });

  //   return subject.asObservable();
  // }

// onRightClick(event: MouseEvent,elementName,innerHTML,typeEdited) {

//     event.preventDefault();
//     this.menuTopLeftPosition.x = event.clientX + 'px';
//     this.menuTopLeftPosition.y = event.clientY + 'px';
//     if(typeEdited=='image'){

//         this.innerHTMLEdited = {elementName:elementName,innerHTML:this.apiUrl+"/media/"+ innerHTML};
//     }

//     if(typeEdited=='text'){

//         this.innerHTMLEdited = {elementName:elementName,innerHTML:innerHTML};
//     }
//     this.typeEdited = typeEdited;
//     this.headerEdited = elementName;
//     this.matMenuTrigger.openMenu()
    
// }
    
// openModal() {

//     this.modalService.open('modal');
// }    

}
