
import {Deserializable} from "./deserializable.model";
import { User } from "./User";
import { ChatChannel } from "./ChatChannel";
export class Chat {

    chat_id:number;
    message:string;
    sent_user:User;
    updated_date:Date;
    is_actived:boolean;
    is_sended:boolean;
    is_readed:boolean;
    chat_channel:ChatChannel;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



