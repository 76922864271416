import { DOCUMENT } from "@angular/common";
import {
    AfterViewInit,
    ContentChild,
    Directive,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    HostListener,
    ComponentFactoryResolver,
    ViewContainerRef
} from "@angular/core";
import { CourseRightClickComponent } from "@app/course/course-right-click/course-right-click.component";
import { TokenStorageService } from '@app/service';
import { ElementService } from '@app/service/';
import { Course, User } from '@app/model';

@Directive({
    selector: "[linkCourseElement]",
})

export class CourseEditedDirective implements AfterViewInit {

    @Input() course: Course;
    @Input() user_editor: string[];
    user: User = this.tokenStorageService.getUser();
    rightClickcomponentRef: any;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private elementService: ElementService,
        public vcRef: ViewContainerRef,
        public elementRef: ElementRef,
        private tokenStorageService: TokenStorageService
    ) {

    }

    ngAfterViewInit() {

    }

    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {

        if (this.user_editor.includes(this.user.user_role.role_name)) {

            event.preventDefault();

            let rightClickComponentFactory = this.componentFactoryResolver.resolveComponentFactory(CourseRightClickComponent)
            this.rightClickcomponentRef = this.vcRef.createComponent(rightClickComponentFactory);
            this.rightClickcomponentRef.instance.menuTopLeftPosition.x = event.clientX + 'px';
            this.rightClickcomponentRef.instance.menuTopLeftPosition.y = event.clientY + 'px';
            this.rightClickcomponentRef.instance.name = 'edited-course';
            //   this.rightClickcomponentRef.instance.name = this.name;
            //   this.rightClickcomponentRef.instance.file_element_edited = { name: this.name, file: this.file };
        }
    }

    private wasInside = false;
    @HostListener('document:click')
    clickInside() {

        if (this.rightClickcomponentRef) {

            this.rightClickcomponentRef.instance.close_right_click()
        }
        this.wasInside = true;
    }
}