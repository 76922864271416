export const lang: any = {
  loginPage: {
    TOPIC_TH: 'เข้าสู่ระบบ',
    TOPIC_EN: 'LOGIN',
    UESRNAMEPLACEHOLDER_TH: 'ชื่อผู้ใช้',
    USERNAMEPLACEHOLDER_EN: 'USERNAME',
    PASSWORDPLACEHOLDER_TH: 'รหัสผ่าน',
    PASSWORDPLACEHOLDER_EN: 'PASSWORD',
    REMEMBER_TH: 'จำรหัสผ่าน',
    REMEMBER_EN: 'remember me',
    BUTTON_TH: 'เข้าสู่ระบบ',
    BUTTON_EN: 'LOGIN',
    FORGETPASSWORD_TH: 'ลืมรหัสผ่าน?',
    FORGETPASSWORD_EN: 'FORGET PASSWORD?',
    REGISTER_TH: 'สมัครสมาชิก',
    REGISTER_EN: 'REGISTER',
    ORTEXT_TH: 'หรือ',
    ORTEXT_EN: 'OR',
    BUTTONFACEBOOK_TH: 'เข้าสู่ระบบด้วยเฟสบุ๊ค',
    BUTTONFACEBOOK_EN: 'LOGIN WITH FACEBOOK',
    BUTTONGOOGLE_TH: 'เข้าสู่ระบบด้วยกูเกิ้ล',
    BUTTONGOOGLE_EN: 'LOGIN WITH GOOGLE',
  },
  regiterPage: {
    TOPIC_TH: 'สมัครสมาชิก',
    TOPIC_EN: 'REGISTER',
    TITLENAME_TH: 'คำนำหน้าชื่อ',
    TITLENAME_EN: 'Title Name',
    GENDERMALE_TH: 'เพศชาย',
    GENDERMALE_EN: 'MALE',
    GENDERFEMALES_TH: 'เพศหญิง',
    GENDERFEMALES_EN: 'Females',
    GENDEROTHER_TH: 'อื่นๆ',
    GENDEROTHER_EN: 'Other',
    EMAILPLACEHOLDER_TH: 'อีเมล',
    EMAILPLACEHOLDER_EN: 'EMAIL',
    PASSWORDPLACEHOLDER_TH: 'รหัสผ่าน',
    PASSWORDPLACEHOLDER_EN: 'PASSWORD',
    CONFIRMPASSWORDPLACEHOLDER_TH: 'ยืนยันรหัสผ่าน',
    CONFIRMPASSWORDPLACEHOLDER_EN: 'CONFIRMED PASSWORD',
    FIRSTNAMEPLACEHOLDER_TH: 'ชื่อ',
    FIRSTNAMEPLACEHOLDER_EN: 'FIRST NAME',
    LASTNAMEPLACEHOLDER_TH: 'นามสกุล',
    LASTNAMEPLACEHOLDER_EN: 'LAST NAME',
    TELPLACEHOLDER_TH: 'เบอร์โทรศัพท์',
    TELPLACEHOLDER_EN: 'TELEPHONE',
    BUTTONREGISTER_TH: 'ลงทะเบียน',
    BUTTONREGISTER_EN: 'SIGN UP',
    BUTTONCANCEL_TH: 'ยกเลิก',
    BUTTONCANCEL_EN: 'CANCEL',
    TYPEUSER_TH: 'ประเภทผู้ใช้',
    TYPEUSER_EN: 'USER TYPE',
    TEACHER_TH: 'ครู',
    TEACHER_EN: 'TEACHER',
    DIRECTOR_TH: 'ผู้อำนวยการโรงเรียน',
    DIRECTOR_EN: 'DIRECTOR SCHOOL',
    SUPERVISOR_TH: 'ศึกษานิเทศก์',
    SUPERVISOR_EN: 'SUPERVISOR',
    VISITOR_TH: 'ผู้ใช้ทั่วไป',
    VISITOR_EN: 'VISITOR',
    LOGIN_TH: 'เข้าสู่ระบบ',
    LOGIN_EN: 'LOIN',
    BACK_TH: 'ย้อนกลับ',
    BACK_EH: 'USER',


  },
  userMenuPage: {
    EDITEDUSER_TH: 'แก้ไขผู้ใช้',
    EDITEDUSER_EN: 'EDITED USER',
    USERLIST_TH: 'รายชื่อผู้ใช้',
    USERLIST_EN: 'USER LIST',
    LEAVESYSTEM_TH: 'ออกจากระบบ',
    LEAVESYSTEM_EN: 'SIGN OUT'
  },
  userListPage: {
    USERKEY_TH: 'รหัสผู้ใช้',
    USERKEY_EN: 'USER KEY',
    EMAIL_TH: 'อีเมล',
    EMAIL_EN: 'EMAIL',
    ROLEID_TH: 'ประเภทผู้ใช้',
    ROLEID_EN: 'ROLE ID',
    PASSWOED_TH: 'รหัสผ่าน',
    PASSWOED_EN: 'PASSWORD',
    COMFIRMPASSWOED_TH: 'ยืนยันรหัสผ่าน',
    COMFIRMPASSWOED_EN: 'COMFIRM PASSWORD',
    FIRSTNAME_TH: 'ชื่อผู้ใช้',
    FIRSTNAME_EN: 'FIRST NAME',
    LASTNAME_TH: 'นาสกุล',
    LASTNAME_EN: 'LAST NAME',
    TEL_TH: 'เบอร์โทรศัพท์',
    TEL_EN: 'TEL',
    PROVIDER_TH: 'ผู้ให้บริการ',
    PROVIDER_EN: 'PROVIDER',
    ADDBOTTON_TH: 'เพิ่ม',
    ADDBOTTON_EN: 'ADD',
    DELETEBOTTON_TH: 'ลบ',
    DELETEBOTTON_EN: 'DELETE',
    CANCLEBOTTON_TH: 'ยกเลิก',
    CANCLEBOTTON_EN: 'CANCLE',
    ADDEDUSERBOTTON_TH: 'เพิ่มผู้ใช้',
    ADDEDUSERBOTTON_EN: 'ADD USER',
    EDITEDUSERBOTTON_TH: 'แก้ไขผู้ใช้',
    EDITEDUSERBOTTON_EN: 'EDITED USER',
    DELETEDUSERBOTTON_TH: 'ลบผู้ใช้',
    DELETEDUSERBOTTON_EN: 'DELETED USER',
    EMAILPLACEHOLDER_TH: 'อีเมล',
    EMAILPLACEHOLDER_EN: 'EMAIL',
    ROLEIDPLACEHOLDER_TH: 'เลือกประเภทผู้ใช้',
    ROLEIDPLACEHOLDER_EN: 'SELECTED ROLE ID',
    PASSWOEDPLACEHOLDER_EN: 'PASSWORD',
    COMFIRMPASSWOEDPLACEHOLDER_TH: 'ยืนยันรหัสผ่าน',
    COMFIRMPASSWOEDPLACEHOLDER_EN: 'COMFIRM PASSWORD',
    FIRSTNAMEPLACEHOLDER_TH: 'ชื่อผู้ใช้',
    FIRSTNAMEPLACEHOLDER_EN: 'FIRST NAME',
    LASTNAMEPLACEHOLDER_TH: 'นาสกุล',
    LASTNAMEPLACEHOLDER_EN: 'LAST NAME',
    TELPLACEHOLDER_TH: 'เบอร์โทรศัพท์',
    TELPLACEHOLDER_EN: 'TEL',
    EMAILPROVIDER_TH: 'อีเมล',
    EMAILPROVIDER_EN: 'EMAIL',
  },
  changePasswordPage: {

    TOPIC_TH: 'เปลี่ยนรหัสผ่าน',
    TOPIC_EN: 'CHANGE PASSWORD',
    EMAILLABEL_TH: 'อีเมล',
    EMAILLABEL_EN: 'EMAIL',
    EMAILHOLDER_TH: 'อีเมล',
    EMAILHOLDER_EN: 'EMAIL',
    BUTTONCHANGEPASSWORD_TH: 'เปลี่ยนรหัสผ่าน',
    BUTTONCHANGEPASSWORD_EN: 'CHANGE PASSWORD',
    BUTTONCANCEL_TH: 'ยกเลิก',
    BUTTONCANCEL_EN: 'CANCEL'
  },
  forgetPassword: {
    TOPIC_TH: 'เปลี่ยนรหัสผ่าน',
    TOPIC_EN: 'CHANGE PASSWORD',
    PASSWORDLABEL_TH: 'รหัสผ่าน',
    PASSWORDLABEL_EN: 'PASSWORD',
    PASSWORDHOLDER_TH: 'รหัสผ่าน',
    PASSWORDHOLDER_EN: 'PASSWORD',
    COMFIRMPASSWORDLABEL_TH: 'ยืนยันรหัสผ่าน',
    COMFIRMPASSWORDLABEL_EN: 'COMFIRM PASSWORD',
    COMFIRMPASSWORHOLDER_TH: 'ยืนยันรหัสผ่าน',
    COMFIRMPASSWORHOLDER_EN: 'COMFIRM PASSWORD',
    COMFIRMBUTTON_TH: 'เปลี่ยนรหัส',
    COMFIRMBUTTON_EN: 'CHANGE PASSWORD',
    CANCELBUTTON_TH: 'ยกเลิก',
    CANCELBUTTON_EN: 'CANCEL',
    BACKBUTTON_TH: 'กลับไปหน้าเข้าสู่ระบบ',
    BACKBUTTON_EN: 'BACK TO Login'
  },
  calendayDay: {
    SUN_TH: 'อาทิตย์',
    SUN_EN: 'SUN',
    MON_TH: 'จันทร์',
    MON_EN: 'MON',
    TUES_TH: 'อังคาร',
    TUES_EN: 'TUES',
    WED_TH: 'พุธ',
    WED_EN: 'WED',
    THURS_TH: 'พฤหัสบดี',
    THURS_EN: 'THURS',
    FRI_TH: 'ศุกร์',
    FRI_EN: 'FRI',
    SATUR_TH: 'เสาร์',
    SATUR_EN: 'SATUR'
  },
  calendayMonth: {
    JAN_TH: 'มกราคม',
    JAN_EN: 'JANUARY',
    FEB_TH: 'กุมภาพันธ์',
    FEB_EN: 'FEBRUARY',
    MAR_TH: 'มีนาคม',
    MAR_EN: 'MARCH',
    APR_TH: 'เมษายน',
    ARP_EN: 'APRIL',
    MAY_TH: 'พฤษภาคม',
    MAY_EN: 'MAY',
    JUN_TH: 'มิถุนายน',
    JUN_EN: 'JUNE',
    JUL_TH: 'กรกฎาคม',
    JUL_EN: 'JULY',
    AUG_TH: 'สิงหาคม',
    AUG_EN: 'AUGUST',
    SEP_TH: 'กันยายน',
    SEP_EN: 'SEPTEMBER',
    OCT_TH: 'ตุลาคม',
    OCT_EN: 'OCTOBER',
    NOV_TH: 'พฤศจิกายน',
    NOV_EN: 'NOVEMBER',
    DEC_TH: 'ธันวาคม',
    DEC_EN: 'DECEMBER'
  },
  calendayEvent: {
    EVENTTOPIC_TH: 'ปฏิทินกิจกรรม',
    EVENTTOPIC_EN: 'CALENDAT',
    EVENTSAMMARY_TH: 'สรุป',
    EVENTSAMMARY_EN: 'SAMMARY',
    EVENTLOCATION_TH: 'สถานที่',
    EVENTLOCATION_EN: 'LOCATION',
    EVENTDESCRIPTION_TH: 'เพิ่มเติม',
    EVENTDESCRIPTION_EN: 'DESCRIPTION',
    EVENTDATE_TH: 'เวลา',
    EVENTDATE_EN: 'DATE TIME',
    EVENTSTARTDATE_TH: 'เริ่มต้น',
    EVENTSTARTDATE_EN: 'START',
    EVENTENDDATE_TH: 'ถึง',
    EVENTENDDATE_EN: 'TO',

  },
  donation: {
    DONATION_EMAIL_TH: 'กรอกอีเมล',
    DONATION_EMAIL_EN: 'Please enter your email',
    DONATION_REEMAIL_TH: 'กรอกอีเมลอีกคร้ัง',
    DONATION_REEMAIL_EN: 'Please re-enter your email',
    DONATION_NAME_TH: 'กรอกชื่อ',
    DONATION_NAME_EN: 'First Name',
    DONATION_LASTNAME_TH: 'กรอกนาสกุล',
    DONATION_LASTNAME_EN: 'Last Name',
    DONATION_CITIZENID_TH: 'กรอกหมายเลขบัตรประชาชน',
    DONATION_CITIZENID_EN: 'ID Card / Passport number',
    DONATION_TEL_TH: 'กรอกเบอร์โทรศัพท์มือถือ',
    DONATION_TEL_EN: 'Mobile phone number',
  },
  assessment: {

    ASSESSMENT_NUMBERERROR_TH: 'กรุณากรอกหมายเลข',
    ASSESSMENT_NUMBERERROR_EN: 'ONLY NUMBER',
  },
  forbiddenPage:{
    FORBIDDEN_PAGE_TH: 'ไม่มีสิทธิ์เข้าถึงหน้านี้',
    FORBIDDEN_PAGE_EN: '403 the page is Forbidden',
    FORBIDDEN_DETAIL_TH: 'ไม่มีสิทธิ์เข้าถึงหน้านี้ ต้องการสิทธิ์ในการเข้าถึง !!!!!',
    FORBIDDEN_DETAIL_EN: 'This page is forbidden, Need Permission !!!!!',
    FORBIDDEN_BUTTON_TH: 'ไปหน้าแรก',
    FORBIDDEN_BUTTON_EN: 'HOME PAGE',
  },
  blogPage:{
    CREATEBLOG_PAGE_TH: 'ตั้งกระทู้',
    CREATEBLOG_PAGE_EN: 'CREATE BLOG',
    CANCELCREATEBLOG_PAGE_TH: 'ยกเลิก',
    CANCELCREATEBLOG_PAGE_EN: 'CANCEL',
    ORDERBYNEWERBLOG_PAGE_TH: 'ใหม่ล่าสุด',
    ORDERBYNEWERBLOG_PAGE_EN: 'NEWER',
    ORDERBYOLDERBLOG_PAGE_TH: 'เก่าที่สุด',
    ORDERBYOLDERBLOG_PAGE_EN: 'OLDER',
    MOSTPOPULATEDBLOG_PAGE_TH: 'ที่มีคนชมมากที่สุด',
    MOSTPOPULATEDBLOG_PAGE_EN: 'MOST POPULATED',
    OWNBLOG_PAGE_TH: 'ของคุณ',
    OWNBLOG_PAGE_EN: 'OWN',
    COMMEMTBLOG_PAGE_TH: 'ความคิดเห็นที่',
    COMMEMTBLOG_PAGE_EN: 'COMMEMT',
    DELETEDCOMMENTBLOG_PAGE_TH: 'ความเห็นถูกลบ !!!!!!',
    DELETEDCOMMENTBLOG_PAGE_EN: 'COMMENT DELETED !!!!!!',
    SUBCOMMENTBLOG_PAGE_TH: 'ความเห็นย่อย',
    SUBCOMMENTBLOG_PAGE_EN: 'SUB COMMENT',
    DELETEDBUTTONBLOG_PAGE_TH: 'ลบกระทู้',
    DELETEDBUTTONBLOG_PAGE_EN: 'DELETED BLOG',
    DELETEDBUTTONTAGBLOG_PAGE_TH: 'ลบกระทู้',
    DELETEDBUTTONTAGBLOG_PAGE_EN: 'DELETED BLOG',
    CANCELDELETEDTAGBLOG_PAGE_TH: 'ยกเลิกลบ Tag',
    CANCELDELETEDTAGBLOG_PAGE_EN: 'DELETED TAG CANCEL',
    EDITED_BG_PAGE_TH: 'แก้รูปภาพพื้นหลัง',
    EDITED_BG_PAGE_EN: 'EDITED BACKGROUND PICTURE',
    EDITEDBUTTONBLOG_TH: 'แก้ไข',
    EDITEDBUTTONBLOG_EN: 'EDITED',
    CANCELEDITEDBUTTONBLOG_TH: 'ยกเลิก',
    CANCELEDITEDBUTTONBLOG_EN: 'CANCEL',

  }
  ,
  editedUserPage:{
    SELECTPICTRUE_TH:'เลือกรูปประจำตัว',
    SELECTPICTRUE_EN:'SELECTED PICTRUE',
    ABOUTTOPPIC_TH:'เกี่ยวกับ',
    ABOUTTOPPIC_EN:'ABOUT',
    POSONALINFORMATION_TH:'ข้อมูลส่วนบุคคล',
    POSONALINFORMATION_EN:'PERSONAL INFORMATION',
    USERNAME_TH:'รหัสผู้ใช้',
    USERNAME_EN:'USERNAME',
    NAME_TH:'ชื่อ',
    NAME_EN:'NAME',
    LASTNAME_TH:'นาสกุล',
    LASTNAME_EN:'LASTNAME',
    TEL_TH:'เบอร์โทรศัพท์',
    TEL_EN:'TEL',
    USERTYPE_TH:'ประเภทผู้ใช้',
    USERTYPE_EN:'USER TYPE',
    PASSWORD_TH:'รหัสผ่าน',
    PASSWORD_EN:'PASSWORD'
  },
  pagenation:{
    FIRSTPAGE_TH:'หน้าแรก',
    FIRSTPAGE_EN:'HOME',
    PREVIOUS_TH:'ก่อนหน้านี้',
    PREVIOUS_EN:'PREVIOUS',
    NEXT_TH:'ถัดไป',
    NEXT_EN:'NEXT',
    LASTPAGE_TH:'หน้าสุดท้าย',
    LASTPAGE_EN:'LAST PAGE',
  },
  course:{
    SELECTED_ALL_TH:'ทั้งหมด',
    SELECTED_ALL_EN:'ALL',
    SELECTED_OPEN_TH:'คอร์สที่เปิดอยู่',
    SELECTED_OPEN_EN:'OPENED COURSE',
    SELECTED_NEWER_TH:'ใหม่ล่าสุด',
    SELECTED_NEWER_EN:'NEWER',
    SELECTED_OLDER_TH:'ที่เก่ากว่า',
    SELECTED_OLDER_EN:'OLDER',
    ADD_COURSE_TH:'เพิ่มหลักสูตร',
    ADD_COURSE_EN:'ADD COURSER',
    DELETE_COURSE_TH:'ลบหลักสูตร',
    DELETE_COURSE_EN:'DELELTED COURSE',
    CANCEL_DELETE_COURSE_TH:'ยกการลบหลักสูตร',
    CANCEL_DELETE_COURSE_EN:'CANCEL DELELTED COURSE',
    ADD_COURSERNO_TH:'รหัสหลักสูตร',
    ADD_COURSERNO_EN:'COURSE NO.',
    ADD_COVERIMAGE_TH:'เลือกรูปหน้าปก',
    ADD_COVERIMAGE_EN:'CHOOSE COVER PICTRUE',
    ADD_IMAGE_TH:'เลือกรูป',
    ADD_IMAGE_EN:'CHOOSE PICTRUE',
    ADD_CROP_IMAGE_TH:'ครอบตัด',
    ADD_CROP_IMAGE_EN:'CROP',
    ADD_TOPIC_TH:'หัวข้อหลักสูตร',
    ADD_TOPIC_EN:'TOPIC',
    ADD_DETAIL_TH:'รายละเอียดหลักสูตร',
    ADD_DETAIL_EN:'DETAIL',
    ADD_ABOUT_TH:'เกี่ยวกับหลักสูตร',
    ADD_ABOUT_EN:'ABOUT',
    ADD_OBJECTIVE_TH:'วัตถุประสงค์',
    ADD_OBJECTIVE_EN:'OBJECTIVE',
    ADD_CRITERION_TH:'เกณฑ์การให้คะแนน',
    ADD_CRITERION_EN:'CRITERION',
    ADD_URL_TH:'ลิงค์ภายนอกเว็ป',
    ADD_URL_EN:'URL',
    CANCEL_TH:'ยกเลิก',
    CANCEL_EN:'CANCEL',
    EDIT_TH:'แก้ไขหลักสูตร',
    EDIT_EN:'EDIT COURSE',
  },
  coursedetail:{
    ENROLLMENT_TH:'ลงทะเบียนเรียน',
    ENROLLMENT_EN:'ENROLLMENT',
  }
};


export function selectLang(page: string, element: string, langSelect: string) {

  return lang[page][element + '_' + langSelect]
}
