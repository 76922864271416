import {Deserializable} from "./deserializable.model";
import { BaseForm } from "./BaseForm"

export class MultipleChoiceGrid extends BaseForm{

  form_no:string;
  type:string = 'multiplechoicegrid';
  description:string;
  question:string;
  rows_list:any[];
  choice_list:any[];
  
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
