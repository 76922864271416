import { Component, Input, Output,OnInit,OnChanges, EventEmitter } from '@angular/core';
import { NewsService,BlogService,CourseService } from '@app/service/';
import { EditorService } from '../editor.service';
import { News,Blog,Course} from '@app/model';
import { LinkMenuBarEnum } from '@app/model/enum/LinkMenuBarEnum';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';

@Component({
    selector: 'menu-bar-editor',
    templateUrl: './menu-bar-editor.component.html',
    styleUrls: ['./menu-bar-editor.component.css'],
})
export class MenuBarEditor implements OnInit,OnChanges {

    @Input() type;
    @Input() link;
    @Input() styles;

    @Output() result_image_upload = new EventEmitter<string>();
    @Output() click_menu = new EventEmitter<{selection: string, value: any}>();


    api_url: string = environment.apiUrl

    is_image_crop: boolean = false;
    is_crop: boolean = false;
    is_bold = false;
    is_italic = false;
    is_underline = false;
    is_th = true;
    is_en = false;

    newsList:News[] = [];
    storyList:News[] = [];
    blogList:Blog[] = [];
    courseList:Course[] = [];

    selectNews:string = '';
    selectStory:string = '';
    selectBlog:string = '';
    selectCourse:string = '';

    public LinkMenuBarEnum = LinkMenuBarEnum;
    linkState = LinkMenuBarEnum.baseURL

    constructor(private newsService: NewsService,
        private blogService: BlogService,
        private courseService:CourseService,
        private editor: EditorService) {

        this.editor.selected$.subscribe(
            (selection) => {
                if (selection.topic == 'bold') {
                    this.is_bold = selection.value;
                }
                if (selection.topic == 'italic') {
                    this.is_italic = selection.value;
                }
                if (selection.topic == 'underline') {
                    this.is_underline = selection.value;
                }
            }
        );
    }

    ngOnInit(): void {

        this.get_newsList();
        this.get_storyList();
        this.get_blogList();
        this.getCourseList();
    }

    ngOnChanges(changes) {

        if(!this.link){
          this.link = ''
        }
        let isBaseUrl = this.link.includes(window.location.origin)
        let url = ''
        let value = ''

        if(isBaseUrl){

            let cutBaseURL = this.link.replace(window.location.origin, "");
            if(cutBaseURL.split('/')[1]&&cutBaseURL.split('/')[1] !='') {

                url = cutBaseURL.split('/')[1]
            }
            else{
                url = ''
            }
            if(cutBaseURL.split('/')[2]&&cutBaseURL.split('/')[2] !='') {

                value = cutBaseURL.split('/')[2]
            }
        }
        else if(!isBaseUrl){

            url = 'outsite'
        }


        if(url==''){

            this.linkState = this.LinkMenuBarEnum.baseURL;
            this.link = window.location.origin
        }

        else if(url=='news-info'){

            this.linkState = this.LinkMenuBarEnum.newsURL;
            this.selectNews = value;
            this.link = window.location.origin + '/news-info/';
        }

        else if(url=='story-info'){

            this.linkState = this.LinkMenuBarEnum.storyURL;
            this.selectStory = value;
            this.link = window.location.origin + '/story-info/';
        }

        else if(url=='blog'){

            this.linkState = this.LinkMenuBarEnum.blogURL;
            this.selectBlog = value;
            this.link = window.location.origin + '/blog/';
        }

        else if(url=='course'){

            this.linkState = this.LinkMenuBarEnum.courseURL;
            this.selectCourse = value;
            this.link = window.location.origin + '/course/';
        }

        else{

            this.linkState = this.LinkMenuBarEnum.outsiteURL;

        }


    }



    makeSelectionBold() {

        this.is_bold = !this.is_bold;
        this.click_menu.emit({selection: 'bold', value: this.is_bold})
    }

    makeSelectionItalic() {

        this.is_italic = !this.is_italic;
        this.click_menu.emit({selection: 'italic', value: this.is_italic})
    }

    makeSelectionUnderline() {

        this.is_underline = !this.is_underline;
        this.click_menu.emit({selection: 'underline', value: this.is_underline})
    }

    makeSelectionFont($event) {

        this.click_menu.emit({selection: $event.target.value, value: true })
    }
    makeSelectionCrop() {
        this.click_menu.emit({selection: 'crop', value: 2})
    }

    makeSelectionUndo() {
        this.click_menu.emit({selection: 'undo', value: null})
    }

    makeSelectionRedo() {
        this.click_menu.emit({selection: 'redo', value: null})
    }

    makeWMAnimate(wmAnimate){

      this.styles.wmAnimate = wmAnimate
      this.click_menu.emit({selection: 'wmAnimate', value: this.styles})
    }
    makeFilter(filter){

      this.styles.filter = filter
      this.click_menu.emit({selection: 'filter', value: this.styles})
    }
    makeHover(hover){

      this.styles.hover = hover
      this.click_menu.emit({selection: 'hover', value: this.styles})
    }

    makeLink($event,state){

        if(state==this.LinkMenuBarEnum.baseURL){

            this.linkState = this.LinkMenuBarEnum.baseURL;
            this.click_menu.emit({selection:'link',value:this.link})
        }
        else if(state==this.LinkMenuBarEnum.newsURL){

            this.linkState = this.LinkMenuBarEnum.newsURL;
            this.link = this.link + $event
            this.click_menu.emit({selection:'link',value:this.link})
        }
        else if(state==this.LinkMenuBarEnum.storyURL){

            this.linkState = this.LinkMenuBarEnum.storyURL;
            this.link = this.link + $event
            this.click_menu.emit({selection:'link',value:this.link})
        }
        else if(state==this.LinkMenuBarEnum.blogURL){

            this.linkState = this.LinkMenuBarEnum.blogURL;
            this.link = this.link + $event
            this.click_menu.emit({selection:'link',value:this.link})
        }
        else if(state==this.LinkMenuBarEnum.courseURL){

            this.linkState = this.LinkMenuBarEnum.courseURL;
            this.link = this.link + $event
            this.click_menu.emit({selection:'link',value:this.link})
        }
        else if(state==this.LinkMenuBarEnum.outsiteURL){

            this.linkState = this.LinkMenuBarEnum.outsiteURL;
            this.link =  $event;
            this.click_menu.emit({selection:'link',value:this.link})
        }
    }

    selectLink(state){

        if(state==this.LinkMenuBarEnum.baseURL){

            this.linkState = this.LinkMenuBarEnum.baseURL;
            this.link = window.location.origin;
            this.click_menu.emit({selection:'link',value:this.link})
        }
        else if(state==this.LinkMenuBarEnum.newsURL){

            this.linkState = this.LinkMenuBarEnum.newsURL;
            this.link = window.location.origin + '/news-info/';
        }
        else if(state==this.LinkMenuBarEnum.storyURL){

            this.linkState = this.LinkMenuBarEnum.storyURL;
            this.link = window.location.origin + '/story-info/';
        }
        else if(state==this.LinkMenuBarEnum.blogURL){

            this.linkState = this.LinkMenuBarEnum.blogURL;
            this.link = window.location.origin + '/blog/';
        }
        else if(state==this.LinkMenuBarEnum.courseURL){

            this.linkState = this.LinkMenuBarEnum.courseURL;
            this.link = window.location.origin + '/course/';
        }
        else{
            this.linkState = this.LinkMenuBarEnum.outsiteURL;
            this.link = '';
        }

    }



    // selectNewsLink(){
    //     this.link = this.api_url;
    // }
    // makeSelectionRedo() {
    //     this.click_menu.emit({selection: 'redo', value: null})
    // }

    makeSelectionLang(){

        this.is_th = !this.is_th;
        this.is_en = !this.is_en;
        if(this.is_th){

            this.click_menu.emit({selection: 'lang', value: 'th'})
        }
        else{

            this.click_menu.emit({selection: 'lang', value: 'en'})
        }

    }


    image_change_event(event: any): void {
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = (event: any) => {

                this.result_image_upload.emit(event.target.result);
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    get_newsList() {

        this.newsService.get_newsList().pipe(first())
            .subscribe({
                next: (response: any) => {

                    if (response.serviceStatus == 'success') {

                        var  today = new Date();
                        this.newsList = response.data_list.sort((a,b) => a.news_id - b.news_id);

                    }
                    else {

                    }

                },
                error: error => {
                }
            });
    }

    get_storyList() {

        this.newsService.get_storyList().pipe(first())
            .subscribe({
                next: (response: any) => {

                    if (response.serviceStatus == 'success') {

                        var  today = new Date();
                        this.storyList = response.data_list.sort((a,b) => a.news_id - b.news_id);

                    }
                    else {

                    }

                },
                error: error => {
                }
            });
    }

    get_blogList() {

            this.blogService.get_blogList().pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            this.blogList = response.data_list.sort((a,b) => a.blog_id - b.blog_id);

                        }
                        else {

                        }

                    },
                    error: error => {
                    }
                });
    }

    getCourseList() {
    this.courseService.get_CourseList()
                .pipe(first())
                .subscribe(
                response => {
                    if (response['serviceStatus'] == 'success') {

                        this.courseList = response['data_list'];

                    }
                    else {

                    }
            });
        }
}
