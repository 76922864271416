import { Injectable, OnInit } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient, HttpRequest, HttpParams, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ElementFiles,Instrument } from '@app/model';

@Injectable({ providedIn: 'root' })
export class InstrumentService {

  constructor(private http: HttpClient) {

  }

  get_instrument_list(type:number) {

    let params = new HttpParams();
    params = params.append('type', type.toString());
    return this.http.get(`${environment.apiUrl}/api/instrument/instrument_list`,
    { params: params })
      .pipe(map(Partner => {

        return Partner;

      }));;
  }

  save_instrument(instrument: Instrument, file: any) {

    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('instrument', JSON.stringify(instrument));
    formData.append('lang', localStorage.getItem('lang'));

    return this.http.post<any>(`${environment.apiUrl}/api/instrument/save_instrument`,
      formData)
      .pipe(map(course => {

        return course;

      }));
  }

  delete_instrument(instrument: Instrument) {

    return this.http.post<any>(`${environment.apiUrl}/api/instrument/delete_instrument`,
      { instrument: instrument })
      .pipe(map(instrument => {

        return instrument;

      }));
  }

  download_count(instrument: Instrument){
    return this.http.post<any>(`${environment.apiUrl}/api/instrument/download_count`,
      { instrument: instrument })
      .pipe(map(instrument => {

        return instrument;

      }));
  }

}
