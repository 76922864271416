import { Component, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { User, LoginAccess } from '@app/model';
import { AuthenticationService } from '../service/';
import { AlertService } from '@app/helper/alert';
import { first, window } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ModalService } from '../helper/modal';
import { TokenStorageService } from '@app/service';
import { selectLang } from '@environments/languageEnvironment';

@Component({
    selector: 'user',
    templateUrl: 'user.component.html',
    styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

    user: User = new User();
    profile_image_url: string = ''
    apiUrl: string = environment.apiUrl;
    image_url_status: number = 1;
    lang: string;
    selectLang = selectLang;

    constructor(
        private authenticationService: AuthenticationService,
        private tokenService: TokenStorageService,
        private alertService: AlertService,
        private modalService: ModalService) {

        this.lang = localStorage.getItem('lang');
    }

    ngOnInit() {

        this.user = this.tokenService.getUser();

        if (!this.user.tel) {

            this.user.tel = ''
        }

        this.user.password = ''
        this.user.comfirm_password = ''
        if (this.isValidHttpUrl(this.user.image_url)) {

            this.profile_image_url = this.user.image_url
        }
        else {

            this.profile_image_url = environment.apiUrl + '/media/' + this.user.image_url + '?' + (new Date()).getTime()
        }

        this.toDataUrl(this.profile_image_url,
            ((myBase64) => {

                this.profile_image_url = myBase64

            }));
    }

    isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    click_edit(variable) {

        if (this.user[variable]) {

            this.user[variable] = !this.user[variable];
        }
        else {

            this.user[variable] = true
        }

    }

    edited_user() {

        this.authenticationService.edited_user(this.user, this.dataURItoBlob(this.profile_image_url))
            .pipe(first())
            .subscribe(
                response => {
                    if (response.serviceStatus == 'success') {

                        this.user = response.data
                        this.user.password = ''
                        this.user.comfirm_password = ''
                        this.tokenService.saveUser(this.user);
                        this.alertService.success(response.massage, { autoClose: true });
                    }
                    else {
                        this.alertService.error(response.massage, { autoClose: true })
                    }
                });
    }

    edited_picture_user() {

        this.authenticationService.edited_picture_user(this.user, this.dataURItoBlob(this.profile_image_url))
            .pipe(first())
            .subscribe(
                response => {
                    if (response.serviceStatus == 'success') {

                        this.user = response.data;
                        this.tokenService.saveUser(this.user);
                        this.alertService.success(response.massage, { autoClose: true });
                        this.modalService.close('user-image')
                    }
                    else {
                        this.alertService.error(response.massage, { autoClose: true })
                    }
                });
    }

    open_picture_modal() {

        this.image_url_status = 1;
        this.modalService.open('user-image');

    }

    changed_image_profile(event: any): void {
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = (event: any) => {

                this.image_url_status = 2
                this.profile_image_url = event.target.result;

            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    set_image_cropper_status_cropper(image_result) {

        this.profile_image_url = image_result;
        this.image_url_status = 1;

    }

    private dataURItoBlob(dataURI) {

        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var arrayBuffer = new ArrayBuffer(byteString.length);
        var _ia = new Uint8Array(arrayBuffer);

        for (var i = 0; i < byteString.length; i++) {
            _ia[i] = byteString.charCodeAt(i);
        }

        var dataView = new DataView(arrayBuffer);
        var blob = new Blob([dataView], { type: mimeString });
        return blob;
    }

    private toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

}
