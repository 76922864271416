import { Component, OnInit, ViewChild, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { ModalService } from '../helper/modal';
import { ElementService } from '../service/';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { WindowRefService } from '@app/service/';

@Component({
  selector: 'app-internship',
  templateUrl: './internship.component.html',
  styleUrls: ['./internship.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InternshipComponent implements OnInit {

  app_name = environment.app_name
  apiUrl: string = environment.apiUrl

  constructor(@Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService) { }

  ngOnInit(): void {

  }

  downloadFile() {
    
    if (isPlatformBrowser(this.platformId)) {
      window.open(this.apiUrl + '/media/' + 'application.pdf');
    }
  }
}

