import { Component, Inject, OnInit, Output, EventEmitter, ViewChild, ViewEncapsulation, ElementRef, PLATFORM_ID } from '@angular/core';
import { AlertService } from '@app/helper/alert';
import { DonationService } from '@app/service/donation.service'
import { WindowRefService } from '@app/service'
import { Donation } from '@app/model/Donation'
import { selectLang } from '@environments/languageEnvironment';
import { environment } from '@environments/environment';
import { DonationStateEnum } from '@app/model/enum/DonationStateEnum'
import { first } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common'; //import
import { th } from 'date-fns/locale';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ModalService } from '@app/helper/modal';
declare var OmiseCard, Omise: any;

@Component({
    selector: 'donation',
    templateUrl: './donation.component.html',
    styleUrls: ['./donation.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DonationComponent implements OnInit {
    @Output() public sidenavToggle = new EventEmitter();

    donation: Donation = new Donation()
    changeOtherAmount: boolean = false
    isInvoice: boolean = false
    isSameReceipt = false;
    DonationStateEnum = DonationStateEnum
    state = DonationStateEnum.input
    source_id: string = ''
    amount: Number;
    currency: string;
    image_url: string;
    is_100_amount: boolean = false
    is_500_amount: boolean = false
    is_1000_amount: boolean = false
    is_order_amount: boolean = false
    order_amount: number;
    is_validation:boolean = false;
    lang: string = localStorage.getItem('lang')
    selectLang = selectLang;

    @ViewChild('donationForm') donationForm: ElementRef;
    @ViewChild('certificateAddressTextArea') certificateAddressTextArea: ElementRef;

    pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
    constructor(private alertService: AlertService,
        private donationService: DonationService,
        private viewPortScroller: ViewportScroller,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private windowRefService: WindowRefService,
        private modalService: ModalService,) {


    }

    ngOnInit(): void {

        this.reload()
    }

    reload() {

        if (isPlatformBrowser(this.platformId)) {
            this.donation.amount = null
            this.donation.email = ''
            this.donation.comfirm_email = ''
            this.donation.first_name = ''
            this.donation.last_name = ''
            this.donation.id_citizen = ''
            this.donation.tel = ''
            this.donation.receipt_address = ''
            this.donation.certificate_address = ''
            this.donation.source_id = ''
            this.donation.charge_id = ''
            this.donation.is_receipt = false
            this.donation.status = 1

            this.changeOtherAmount = false
        }
    }

    page: number = 1;
    totalPages: number;
    isLoaded: boolean = false;



    backToStepOne() {
        this.state  = DonationStateEnum.input;
    }
    finishStepOne() {

        this.state = DonationStateEnum.payment
    }

    finishStepTwo() {

        this.state = DonationStateEnum.input;
    }

    check_other(is_100_amount, is_500_amount, is_1000_amount, is_order_amount) {

        this.is_100_amount = is_100_amount
        this.is_500_amount = is_500_amount
        this.is_1000_amount = is_1000_amount
        this.is_order_amount = is_order_amount
        this.order_amount = null;
    }

    changeReceiptWithCertificate() {
        if (isPlatformBrowser(this.platformId)) {
            if (this.isSameReceipt) {

                this.certificateAddressTextArea.nativeElement.value = this.donation.receipt_address;
                if (isPlatformBrowser(this.platformId)) {
                    var elelement = this.windowRefService.nativeWindow.document.getElementById("donation-certificateAddress")
                    elelement['value'] = this.donation.receipt_address
                }
                this.donation.certificate_address = this.donation.receipt_address;
                this.certificateAddressTextArea.nativeElement

            }
        }

    }

    add_donation() {

        if (isPlatformBrowser(this.platformId)) {

            this.donationService.save_donation(this.donation)
                .pipe(first())
                .subscribe(
                    response => {
                        if (response.serviceStatus == 'success') {

                            this.state = this.state = DonationStateEnum.comfirm
                            this.donation = response.data
                            // this.donationFormSubmit()
                            // this.alertService.success(response.massage)

                        }
                        else {
                            this.alertService.error(response.massage)
                        }
                    });
        }
    }

    donationFormSubmit() {

        if (isPlatformBrowser(this.platformId)) {
            const url = "https://docs.google.com/forms/d/e/1FAIpQLScVg3cISLAV_YluvfEho7xP9Ly_tTjx7jbpTXxCFFzxTIQQ7A/formResponse";
            fetch(url, {
                method: "POST",
                body: new FormData(this.donationForm.nativeElement),

            }).then(


                response => {

                    this.donationService.update_source_sheet_donation(this.donation)
                        .pipe(first())
                        .subscribe(
                            response => {
                                if (response.serviceStatus == 'success') {

                                }
                                else {

                                }
                            });
                }
            ).then(
                html => console.log(html)
            )
                .catch((error) => {
                    this.donationService.update_source_sheet_donation(this.donation)
                        .pipe(first())
                        .subscribe(
                            response => {
                                if (response.serviceStatus == 'success') {

                                }
                                else {

                                }
                            });
                });
        }
    }

    create_source(service, amount, currency) {

        Omise.setPublicKey(environment.omise_public_key);
        Omise.createSource(service, {
            "amount": amount,
            "currency": currency
        }, (statusCode, response) => {
            this.amount = Number(response['amount'])
            this.currency = response['currency']
            this.source_id = response['id']
            this.donation.source_id = this.source_id
        });

    }

    donation_payment_charges() {

        this.add_donation()
        // this.state = DonationStateEnum.comfirm
    }

    finish_payment(){
      this.modalService.open('finish-donation');

    }

    backToMain(){
      window.location.href = window.location.origin + '/home'
    }

    check_input_data() {

        if (isPlatformBrowser(this.platformId)) {

            this.is_validation = true;

            if ((!this.is_100_amount&&!this.is_500_amount&&!this.is_1000_amount&&!this.is_order_amount)||(this.is_order_amount&&!this.order_amount)) {

                if (isPlatformBrowser(this.platformId)) {
                    let el = this.windowRefService.nativeWindow.document.getElementById('donation-amount');
                    this.viewPortScroller.scrollToPosition([0, el.offsetTop - 100])
                }
                // el.scrollTo({ top: 0, behavior: 'smooth' });

            }
            else if (this.donation.email == null || this.donation.email == '') {

                if (isPlatformBrowser(this.platformId)) {
                    let el = this.windowRefService.nativeWindow.document.getElementById('donation-email');
                    this.viewPortScroller.scrollToPosition([0, el.offsetTop - 130])
                }

            }
            else if (this.donation.comfirm_email == null || this.donation.comfirm_email == '') {

                if (isPlatformBrowser(this.platformId)) {
                    let el = this.windowRefService.nativeWindow.document.getElementById('donation-comfirmEmail');
                    this.viewPortScroller.scrollToPosition([0, el.offsetTop - 130])
                }

            }
            else if (this.donation.is_receipt && (this.donation.first_name == null || this.donation.first_name == '')) {

                if (isPlatformBrowser(this.platformId)) {
                    let el = this.windowRefService.nativeWindow.document.getElementById('donation-firstName');
                    this.viewPortScroller.scrollToPosition([0, el.offsetTop - 130])
                }
            }
            else if (this.donation.is_receipt && (this.donation.last_name == null || this.donation.last_name == '')) {

                if (isPlatformBrowser(this.platformId)) {
                    let el = this.windowRefService.nativeWindow.document.getElementById('donation-lastName');
                    this.viewPortScroller.scrollToPosition([0, el.offsetTop - 130])
                }

            }
            else if (this.donation.is_receipt && (this.donation.id_citizen == null || this.donation.id_citizen == '')) {

                if (isPlatformBrowser(this.platformId)) {
                    let el = this.windowRefService.nativeWindow.document.getElementById('donation-idCitizen');
                    this.viewPortScroller.scrollToPosition([0, el.offsetTop - 130])
                }
            }
            else if (this.donation.is_receipt && (this.donation.tel == null || this.donation.tel == '')) {

                if (isPlatformBrowser(this.platformId)) {
                    let el = this.windowRefService.nativeWindow.document.getElementById('donation-tel');
                    this.viewPortScroller.scrollToPosition([0, el.offsetTop - 130])
                }
            }
            else if (this.donation.is_receipt && (this.donation.receipt_address == null || this.donation.receipt_address == '')) {

                if (isPlatformBrowser(this.platformId)) {
                    let el = this.windowRefService.nativeWindow.document.getElementById('donation-receiptAddress');
                    this.viewPortScroller.scrollToPosition([0, el.offsetTop - 130])
                }

            }

            else if (this.donation.is_receipt && (this.donation.certificate_address == null || this.donation.certificate_address == '')) {

                if (isPlatformBrowser(this.platformId)) {
                    let el = this.windowRefService.nativeWindow.document.getElementById('donation-certificateAddress');
                    this.viewPortScroller.scrollToPosition([el.offsetTop - 130, 0])
                }

            }

            else {

              if(this.is_100_amount){
                this.donation.amount = 100
              }
              else if(this.is_500_amount){
                this.donation.amount = 500
              }
              else if(this.is_1000_amount){
                this.donation.amount = 1000
              }
              else if(this.is_order_amount){
                this.donation.amount = this.order_amount
              }

              this.state = DonationStateEnum.payment

            }
        }

    }

}
