import { Component, OnInit, Input, ViewChild, ViewEncapsulation, AfterViewInit, OnChanges, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';
import { Course, ElementText, ElementFiles, Lesson, LessonDetail, User } from '@app/model';
import { CourseService, WindowRefService } from '@app/service/';
import { AuthenticationService, FileService } from '@app/service';
import { AlertService } from '@app/helper/alert';
import { ModalService } from '@app/helper/modal';
import { BlogStateEnum } from '@app/model/enum/BlogStateEnum';
import { environment } from '@environments/environment';
import { CourseStateEnum } from '@app/model/enum/CourseStateEnum';
import { first } from 'rxjs/operators';
import { isThisQuarter } from 'date-fns';
import { FeelingBlog } from '@app/model/FeelingBlog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Router, Params, ActivatedRoute, NavigationExtras } from '@angular/router';
import { HostListener } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatMenu } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TokenStorageService } from '@app/service';
import { selectLang } from '@environments/languageEnvironment';

@Component({
  selector: 'course-detail',
  templateUrl: './courseDetail.component.html',
  styleUrls: ['./courseDetail.component.css'],

})
export class CourseDetailComponent implements OnInit {

  course: Course = new Course();
  editeCourse: Course = new Course();
  courseNo: string;
  is_erolled: boolean;
  // currentUser: any = this.authenticationService.currentUserValue;
  apiUrl: string = environment.apiUrl
  userList: User[]
  instructor_list: User[] = []

  public CourseStateEnum = CourseStateEnum;
  public state = CourseStateEnum.detail;
  // user: User = this.tokenService.getUser()

  menuTopLeftPosition = { x: '0', y: '0' }
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  selectLang = selectLang;
  lang: string;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'รายละเอียด',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'KANIT',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  editedName: any;
  placeholderNmae: string;

  selectLesson: Lesson
  addFile: ElementFiles;

  existingFile: File = null;
  progress = 0;
  msg = '';
  loaded: number = 0;
  total: number = 0;

  FileDetail: Observable<any>;

  addLesson: Lesson;

  addLessonDetail: LessonDetail;

  constructor(private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private courseService: CourseService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private fileService: FileService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService,
    private tokenService: TokenStorageService) {

    this.lang = localStorage.getItem('lang');
    if (isPlatformBrowser(this.platformId)) {
      this.courseNo = this.route.snapshot.paramMap.get("courseNo")
    }

  }

  ngOnInit(): void {

    this.reload()
  }

  reload() {

    if (isPlatformBrowser(this.platformId)) {
      this.addLesson = new Lesson()
      this.addLesson.course = this.course;
      this.addLesson.title = new ElementText();
      this.addLesson.title.th_html = '';

      this.addLessonDetail = new LessonDetail
      this.addLessonDetail.title = new ElementText();
      this.addLessonDetail.title.th_html = '';
      this.addLessonDetail.file = new ElementFiles()
      this.addLessonDetail.user_list = []
      this.addLessonDetail.lesson = new Lesson();

      this.existingFile = null;
      this.progress = 0;
      // this.editeCourse = this.course;

      this.getCourse();
      this.user_list();
    }
  }

  getCourse() {
    if (isPlatformBrowser(this.platformId)) {
      this.courseService.get_course(this.courseNo)
        .pipe(first())
        .subscribe(
          response => {
            if (response.serviceStatus == 'success') {

              this.course = response.data;

              // this.check_erolled()
            }
            else {
              this.alertService.error(response.massage)
            }
          });
    }
  }

  user_list() {

    if (isPlatformBrowser(this.platformId)) {
      this.authenticationService.user_list()
        .pipe(first())
        .subscribe(
          response => {
            if (response.serviceStatus == 'success') {

              this.userList = response.data_list
            }
            else {
              this.alertService.error(response.massage)
            }
          });
    }
  }

  // check_erolled() {

  //   if (isPlatformBrowser(this.platformId)) {
  //     let course_list = this.course.student_list.filter(s => s.user_key == this.user.user_key)
  //     if (course_list.length > 0) {

  //       this.is_erolled = true
  //     }
  //     else {

  //       this.is_erolled = false
  //     }
  //   }
  // }

  return_external_course() {

    window.open(this.course.url, "_blank");
    // window.location.href = this.course.url
  }
  enrolled_course() {

    if (isPlatformBrowser(this.platformId)) {
      this.courseService.enrolled_course(this.course)
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.course = response['data']
              this.reload()
              window.location.href = this.course.url;
            }
            else {
              this.alertService.error(response['massage'])
            }
          });
    }
  }

  addLessonRightClick(event: MouseEvent) {

    if (isPlatformBrowser(this.platformId)) {
      var target = event.target
      if (target && target['classList']) {

        let isCanRightClick = (target['id'] == 'lesson')
        if (isCanRightClick) {

          event.preventDefault();
          this.menuTopLeftPosition.x = event.clientX + 'px';
          this.menuTopLeftPosition.y = event.clientY + 'px';
          this.state = CourseStateEnum.addLesson;

          this.addLesson = new Lesson()
          this.addLesson.course = this.course;
          this.addLesson.title = new ElementText();
          this.addLesson.title.th_html = '';
          this.matMenuTrigger.openMenu()

        }
      }
    }
  }

  openAddLessonModal() {

    if (isPlatformBrowser(this.platformId)) {
      this.modalService.open('add-lesson')
    }
  }

  saveLesson() {
    if (isPlatformBrowser(this.platformId)) {
      this.courseService.save_lesson(this.addLesson)
        .pipe(first())
        .subscribe(
          response => {
            if (response.serviceStatus == 'success') {

              this.course = response.data
              this.modalService.close('add-lesson')
              this.reload()
              this.alertService.success(response.massage)
            }
            else {
              this.alertService.error(response.massage)
            }
          });
    }
  }

  closeSaveModal() {

    if (isPlatformBrowser(this.platformId)) {
      this.reload()
      this.modalService.close('add-lesson')
    }
  }


  addLessonDetailRightClick(event: MouseEvent, lesson: Lesson) {

    if (isPlatformBrowser(this.platformId)) {
      var target = event.target
      if (target && target['classList']) {

        let isCanRightClick = (target['id'] == 'lesson-detail')
        if (isCanRightClick) {

          event.preventDefault();
          this.menuTopLeftPosition.x = event.clientX + 'px';
          this.menuTopLeftPosition.y = event.clientY + 'px';
          this.state = CourseStateEnum.addLessonDetail;
          this.addLessonDetail = new LessonDetail
          this.addLessonDetail.title = new ElementText();
          this.addLessonDetail.title.th_html = '';
          this.addLessonDetail.file = new ElementFiles()
          this.addLessonDetail.user_list = []
          this.addLessonDetail.lesson = lesson;

          this.existingFile = null;
          this.matMenuTrigger.openMenu()
        }
      }
    }
  }

  openAddLessonDetailModal() {

    if (isPlatformBrowser(this.platformId)) {
      this.modalService.open('add-detail-lesson')
    }
  }

  uploadFile(event): void {

    if (isPlatformBrowser(this.platformId)) {
      this.existingFile = event.target.files.item(0);
    }
  }

  saveUploadFile(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.progress = 0;

      this.courseService.save_lessonDetail(this.addLessonDetail, this.existingFile).subscribe((event) => {

        if (event.type === HttpEventType.UploadProgress) {

          this.loaded = event.loaded;
          this.total = event.total;
          this.progress = Math.round(90 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {

          if (event.body.serviceStatus == 'success') {
            this.progress = Math.round(100 * this.loaded / this.total);
            this.alertService.success(event.body.massage);
            let test = this.course
            this.existingFile = null;
            this.modalService.close('add-detail-lesson')
            this.reload()
          }
          else {

            this.alertService.error(event.body.massage)
          }

        }
      }, (error) => {
        this.progress = 0;
        this.msg = 'Error occured while uploading file';
        this.existingFile = undefined;
      });
    }

  }

  closeSaveLessonDetailModal() {

    if (isPlatformBrowser(this.platformId)) {
      this.reload()
      this.modalService.close('add-detail-lesson')
    }
  }




  click_lesson_file(lessonDetail: LessonDetail) {

    if (isPlatformBrowser(this.platformId)) {
      if (lessonDetail.file.extension == 'mp4') {

        lessonDetail.is_vedio = true;

      } else {

        window.open(`${environment.apiUrl}/media/` + lessonDetail.file.file);
      }
    }

  }

  openEdited(event: MouseEvent, name, placeholderNmae) {

    if (isPlatformBrowser(this.platformId)) {
      event.preventDefault();
      this.menuTopLeftPosition.x = event.clientX + 'px';
      this.menuTopLeftPosition.y = event.clientY + 'px';
      this.editedName = name;
      this.placeholderNmae = placeholderNmae;
      if (this.editedName == 'instructor') {
        let user = new User()
        this.instructor_list.push(user)
        this.state = CourseStateEnum.addInstructoe;
      }
      else {

        this.state = CourseStateEnum.addDetail;
      }

      this.config.placeholder = placeholderNmae;
      this.matMenuTrigger.openMenu()
    }
  }

  editedCourse() {

    this.courseService.update_Course(this.course).pipe(first())
      .subscribe(
        response => {

          if (response.serviceStatus == 'success') {

            this.modalService.close('course-editer');
            this.alertService.success(response.massage, { autoClose: true })
            this.reload()
          }
          else {
            this.alertService.error(response.massage, { autoClose: true })
          }
        });
  }

  cancelEditedCourse() {

    this.modalService.close('edited-course');
    this.reload();
  }

  

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    window.location.reload()
  }

}
