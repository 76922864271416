import { Component, Input, Output, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { MultipleChoice } from '@app/assessment/question-form/model'
import { FormStateEnum } from '@app/assessment/question-form/model/enum/FormStateEnum'
import { selectLang } from '@environments/languageEnvironment';
import { Observable } from 'rxjs';
import { BaseFormComponent } from '@app/assessment/question-form/base-form/baseform.component';

@Component({
  selector: 'multiplechoice-form',
  templateUrl: './multiplechoice-form.component.html',
  styleUrls: ['./multiplechoice-form.component.css']
})

export class MultipleChoiceComponent extends BaseFormComponent implements OnInit {

  baseForm: MultipleChoice;
  other_text: string = "";
  other_choice_index: number;
  is_other: boolean = false;
  answer: string = null;

  changed_choice(choice) {

    this.answer_list = []
    this.answer_list.push(choice)
    this.is_other = false;
    this.other_text = '';

    this.changed_answer()

  }



  changed_text_other() {

    this.answer_list = [];
    this.answer_list.push(this.other_text);
    this.changed_answer();

  }

  added_option() {

    let index = this.baseForm.choice_list.length
    this.baseForm.choice_list.push({ option: 'option' + (index + 1) })
    this.out_form_event();

  }

  changed_option() {

    this.out_form_event();
  }

  changed_other_choice() {

    this.baseForm.is_choice_other = !this.baseForm.is_choice_other;
    this.out_form_event();
  }

  deleted_option(index) {

    this.baseForm.choice_list.splice(index, 1);
    this.out_form_event();
  }

  changed_is_other() {

    this.is_other = !this.is_other;
    
  }
}
