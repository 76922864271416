export enum CourseStateEnum {
    list, 
    detail, 
    add,
    update,
    delete,
    addDetail,
    addLesson,
    addLessonDetail,
    addFile,
    addInstructoe
  }