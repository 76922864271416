import { Component, OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
    selector: 'test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.css'],
    encapsulation: ViewEncapsulation.None
  })
  export class TestComponent implements OnInit {

    app_name = environment.app_name
    apiUrl:string =environment.apiUrl

    constructor() { }

    ngOnInit(): void {

      }

}
