import { Injectable,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import {News} from '@app/model';

@Injectable({ providedIn: 'root' })
export class NewsService {
    headers:any
    constructor(
        private router: Router,
        private http: HttpClient
    ) {}

    get_newsList() {
        
        return this.http.get(`${environment.apiUrl}/api/news/news_list`)
        .pipe(map(news => {

            return  new News().deserialize(news);

        }));;
    }

    get_storyList() {

        return this.http.get(`${environment.apiUrl}/api/news/story_list`)
        .pipe(map(news => {

            return  new News().deserialize(news);

        }));;
    }
    get_news(news_id) {

        let params = new HttpParams();
        params = params.append('news_id', news_id);

        return this.http.get(`${environment.apiUrl}/api/news/get_news`,
        {params: params}
        )
        .pipe(map(news => {

            return  new News().deserialize(news);

        }));;
    }

    save_news(news:News,imageFile:any) {

        const formData: FormData = new FormData();
        formData.append('file', imageFile);
        formData.append('news', JSON.stringify(news));
        formData.append('lang', localStorage.getItem('lang'));

        return this.http.post(`${environment.apiUrl}/api/news/save_news`,   
            formData
        )
        .pipe(map(elementImage => {

            return  new News().deserialize(elementImage);

        }));;
    }

    edited_news(news:News) {


        return this.http.post(`${environment.apiUrl}/api/news/edited_news`,   
        {news:news}
        )
        .pipe(map(elementImage => {

            return  new News().deserialize(elementImage);

        }));;
    }

    deleted_news(news:News) {


        return this.http.post(`${environment.apiUrl}/api/news/deleted_news`,   
        {news:news}
        )
        .pipe(map(elementImage => {

            return  new News().deserialize(elementImage);

        }));;
    }

    
}