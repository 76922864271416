import { Component, OnInit, ViewChild, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { ModalService } from '@app/helper/modal';
import { ElementService, InstrumentService, TokenStorageService } from '@app/service/';
import { ElementText, ElementFiles, Instrument, User } from '@app/model';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { WindowRefService } from '@app/service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AlertService } from '@app/helper/alert';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ManualComponent implements OnInit {

  app_name = environment.app_name
  apiUrl: string = environment.apiUrl
  addInstrument: Instrument = new Instrument();

  is_delete_instrument: boolean = false;
  instrumentList: Instrument[] = [];
  file: any;
  user: User = this.tokenService.getUser()

  menuTopLeftPosition = { x: '0', y: '0' }
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  config = {
    aspectRatio: 1 / 1,
    dragMode: 'move',
    background: true,
    movable: true,
    rotatable: true,
    scalable: false,
    zoomable: true,
    viewMode: 1,
    checkImageOrigin: true,
    checkCrossOrigin: true
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'รายละเอียด',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'KANIT',
    sanitize: false,
    toolbarHiddenButtons: [
      ['bold']
    ],
    fonts: [
      { class: 'arial', name: 'ARIAL' },
      { class: 'times-new-roman', name: 'TIMES NEW ROMAN' },
      { class: 'calibri', name: 'CALIBRI' },
      { class: 'KANIT', name: 'KANIT' },
      { class: 'IANNNNNDOG-REQULAR', name: 'IANNNNNDOG REQULAR' },
      { class: 'THSARABUN', name: 'THSARABUN' },
      { class: 'LYFNARAK', name: 'LYFNARAK' },
      { class: 'SOVKLORNG', name: 'SOVKLORNG' },
      { class: 'MMNIRATPARIS', name: 'MMNIRATPARIS' },
      { class: 'SOVBAAN', name: 'SOVBAAN' },
      { class: 'TALAMDUAN', name: 'TALAMDUAN' },
      { class: 'TAMAGAZINE', name: 'TAMAGAZINE' },
      { class: 'SOV3DSLIM', name: 'SOV3DSLIM' },
      { class: 'FCOUTLINECOLOR', name: 'FCOUTLINECOLOR' },
      { class: 'SOVJDKRANOK', name: 'SOVJDKRANOK' },
      { class: 'NOODLEDEMO', name: 'NOODLEDEMO' },
      { class: 'SOVSAMCHUK', name: 'SOVSAMCHUK' },
      { class: 'WHALE', name: 'WHALE' },
      { class: 'AKKHARAKT', name: 'AKKHARAKT' },
      { class: 'SPLAISAN', name: 'SPLAISAN' },
      { class: 'SOVRATAPHAN', name: 'SOVRATAPHAN' },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };


  constructor(private modalService: ModalService, private elementService: ElementService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService,
    private alertService: AlertService,
    private instrumentService: InstrumentService,
    private tokenService: TokenStorageService,) { }

  ngOnInit(): void {

    this.reload()
  }

  downloadPDF() {

    if (isPlatformBrowser(this.platformId)) {
      window.open("https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf");
    }
  }

  reload() {

    if (isPlatformBrowser(this.platformId)) {

      this.addInstrument = new Instrument()
      this.addInstrument.topic = new ElementText()
      this.addInstrument.detail = new ElementText()
      this.addInstrument.file = new ElementFiles()
      this.addInstrument.type = 2;
      this.file = null;



      this.is_delete_instrument = false;
      this.instrument_list()

    }
  }

  instrument_list() {

    if (isPlatformBrowser(this.platformId)) {
      this.instrumentService.get_instrument_list(2)
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.instrumentList = response['data_list'];
              console.log(this.instrumentList)
            }
            else {
              this.alertService.error(response['massage'])
            }
          });
    }
  }

  onRightClick(event: MouseEvent) {

    if (this.user && this.user.user_role.role_name == 'admin') {

      var target = event.target

      if (target && target['classList']) {
        console.log(target['id'])
        let isCanRightClick = (target['id'] == 'instrument')
        if (isCanRightClick) {

          event.preventDefault();
          this.menuTopLeftPosition.x = event.clientX + 'px';
          this.menuTopLeftPosition.y = event.clientY + 'px';
          this.matMenuTrigger.openMenu()
        }
      }
    }
  }

  uploadFile(event): void {

    if (isPlatformBrowser(this.platformId)) {

      this.file = event.target.files && event.target.files[0];
    }
  }

  openAddModal() {

    if (isPlatformBrowser(this.platformId)) {

      this.modalService.open('add-instrument');
    }
  }

  closeAddModal() {

    if (isPlatformBrowser(this.platformId)) {

      this.reload()
      this.modalService.close('add-instrument')
    }
  }

  covert_file_to_name(file) {

    return file.split("/")[1]
  }

  save_instructionnal_media() {

    if (isPlatformBrowser(this.platformId)) {
      this.instrumentService.save_instrument(this.addInstrument, this.file)
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.closeAddModal()
              this.alertService.success(response['massage'], { autoClose: true })
            }
            else {
              this.alertService.error(response['massage'], { autoClose: true })
            }
          });
    }
  }

  openDeletedInstrument() {

    this.is_delete_instrument = true;
  }

  cancelDeleteInstrument() {
    this.is_delete_instrument = false;
  }

  delete_instrument(instrument) {

    if (isPlatformBrowser(this.platformId)) {

      this.instrumentService.delete_instrument(instrument)
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.reload()

              this.alertService.success(response['massage'])
            }
            else {
              this.alertService.error(response['massage'])
            }
          });
    }

  }

  download_count(instrument) {

    this.instrumentService.download_count(instrument)
      .pipe(first())
      .subscribe(
        response => {
          if (response['serviceStatus'] == 'success') {

            this.reload()

          }
          else {
            this.alertService.error(response['massage'])
          }
        });
  }

  downloadFile(fileName) {

    if (isPlatformBrowser(this.platformId)) {
      window.open(this.apiUrl + '/media/' + fileName);
    }
  }

}
