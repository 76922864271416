import { Component, Input, OnInit, OnChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { selectLang } from '@environments/languageEnvironment';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html'
})

export class PaginationComponent implements OnInit, OnChanges {

  @Input() items = [];
  @Input() recordsPerPage = 0;
  @Input() activedPage: number;

  @Output() onPageChange: EventEmitter<{}> = new EventEmitter();

  public pages: any[] = [];
  pageCount: number = 0;
  totalRecords: number = 0;
  lang: string;
  selectLang = selectLang;


  constructor(private cdr: ChangeDetectorRef) { 
    
    this.lang = localStorage.getItem('lang');
  }

  ngOnInit() {

    // this.activePage = 1; 
    // this.onPageChange.emit(1);
    // this.cdr.detectChanges(); 

  }

  ngOnChanges(): any {

    if (this.items) {

      this.reload();
    }

  }

  reload() {

    this.totalRecords = this.items.length;
    const pageCount = this.getPageCount();
    this.pageCount = pageCount;
    this.pages = this.getArrayOfPage(pageCount);
    let itemOfpages = this.itemOfPage();
    this.onPageChange.emit(itemOfpages);
  }

  private getPageCount(): number {

    let totalPage = 0;

    if (this.totalRecords > 0 && this.recordsPerPage > 0) {
      const pageCount = this.totalRecords / this.recordsPerPage;
      const roundedPageCount = Math.floor(pageCount);

      totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }

    return totalPage;
  }

  private getArrayOfPage(pageCount: number): any[] {
    const pageArray = [];

    if (pageCount > 0) {

      if (this.activedPage == 1) {

        pageArray.push(1);

        if (pageCount == 2) {

          pageArray.push(2);
        }
        else if (pageCount > 2) {

          pageArray.push(2);
          pageArray.push(pageCount);
        }
      }
      else if (this.activedPage == pageCount) {

        if (pageCount == 2) {

          pageArray.push(pageCount - 1)
        }
        else if (pageCount > 2) {
          pageArray.push(pageCount - 2)
          pageArray.push(pageCount - 1)
        }
        pageArray.push(pageCount);

      } else {

        pageArray.push(this.activedPage - 1)
        pageArray.push(this.activedPage);

        if (pageCount > 2) {

          pageArray.push(this.activedPage + 1);
        }
      }
    }

    return pageArray;
  }

  itemOfPage() {

    let pageNumber = this.activedPage - 1;
    let itemOfPages = []
    if (pageNumber > -1) {
      for (let i = pageNumber; i <= (pageNumber + this.recordsPerPage - 1); i++) {

        if (this.items[i]) {

          itemOfPages.push(this.items[i]);
        }
      }
    }

    return { 'itemOfPage': itemOfPages, 'activePage': this.activedPage };
  }

  onClickPage(pageNumber: number): void {

    if (pageNumber >= 1) {
      this.activedPage = pageNumber;
      this.reload();
    }
  }
} 