
import {Deserializable} from "./deserializable.model";
import { User } from "./User";
import { ChatChannel } from "./ChatChannel";
export class Event {

  id:string;
  start_date:Date;
  end_date:Date;
  description:string;
  location:string;
  summary:string;
  creator:string;
  updated:Date;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
