import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
  })
export class EditorService {

    private selected = new Subject<{topic:string,value:boolean}>();
    public selected$ = this.selected.asObservable();

  constructor() {
      
  }

  selectedData(selected){
    this.selected.next(selected);
  }
  
}