import { Component, OnInit, ViewChild, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { ModalService } from '../helper/modal';
import { ElementService, VideoTrainingService, TokenStorageService } from '../service/';
import { ElementText, ElementVideo, VideoTraining, User } from '@app/model';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AlertService } from '@app/helper/alert';

@Component({
  selector: 'app-video-training',
  templateUrl: './video-training.component.html',
  styleUrls: ['./video-training.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VideoTrainingComponent implements OnInit {

  app_name = environment.app_name;
  apiUrl: string = environment.apiUrl;

  addVideoTraining: VideoTraining;
  is_delete_videoTraining: boolean = false;
  videoTrainingList: VideoTraining[] = [];
  is_invalid_url: boolean = false;

  existingFile: File = null;


  menuTopLeftPosition = { x: '0', y: '0' }
  user: User = this.tokenService.getUser()
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  config = {
    aspectRatio: 1 / 1,
    dragMode: 'move',
    background: true,
    movable: true,
    rotatable: true,
    scalable: false,
    zoomable: true,
    viewMode: 1,
    checkImageOrigin: true,
    checkCrossOrigin: true
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'รายละเอียด',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'KANIT',
    sanitize: false,
    toolbarHiddenButtons: [
      ['bold']
    ],
    fonts: [
      { class: 'arial', name: 'ARIAL' },
      { class: 'times-new-roman', name: 'TIMES NEW ROMAN' },
      { class: 'calibri', name: 'CALIBRI' },
      { class: 'KANIT', name: 'KANIT' },
      { class: 'IANNNNNDOG-REQULAR', name: 'IANNNNNDOG REQULAR' },
      { class: 'THSARABUN', name: 'THSARABUN' },
      { class: 'LYFNARAK', name: 'LYFNARAK' },
      { class: 'SOVKLORNG', name: 'SOVKLORNG' },
      { class: 'MMNIRATPARIS', name: 'MMNIRATPARIS' },
      { class: 'SOVBAAN', name: 'SOVBAAN' },
      { class: 'TALAMDUAN', name: 'TALAMDUAN' },
      { class: 'TAMAGAZINE', name: 'TAMAGAZINE' },
      { class: 'SOV3DSLIM', name: 'SOV3DSLIM' },
      { class: 'FCOUTLINECOLOR', name: 'FCOUTLINECOLOR' },
      { class: 'SOVJDKRANOK', name: 'SOVJDKRANOK' },
      { class: 'NOODLEDEMO', name: 'NOODLEDEMO' },
      { class: 'SOVSAMCHUK', name: 'SOVSAMCHUK' },
      { class: 'WHALE', name: 'WHALE' },
      { class: 'AKKHARAKT', name: 'AKKHARAKT' },
      { class: 'SPLAISAN', name: 'SPLAISAN' },
      { class: 'SOVRATAPHAN', name: 'SOVRATAPHAN' },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private modalService: ModalService,
    private elementService: ElementService,
    private videoTrainingService: VideoTrainingService,
    private alertService: AlertService,
    private tokenService: TokenStorageService) { }

  ngOnInit(): void {

    this.reload()
  }

  reload() {

    if (isPlatformBrowser(this.platformId)) {

      this.addVideoTraining = new VideoTraining();
      this.addVideoTraining.topic = new ElementText();
      this.addVideoTraining.detail = new ElementText();
      this.addVideoTraining.video = new ElementVideo();
      this.addVideoTraining.video.link = '';
      this.addVideoTraining.video.is_external = true;

      this.is_delete_videoTraining = false;
      this.videoTraining_list();
    }
  }

  videoTraining_list() {

    if (isPlatformBrowser(this.platformId)) {

      this.videoTrainingService.get_videoTraining_list()
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.videoTrainingList = response['data_list'];
              console.log('videoTrainingList')
              console.log(this.videoTrainingList)
            }
            else {
              this.alertService.error(response['massage'])
            }
          });
    }
  }

  onRightClick(event: MouseEvent) {

    if (this.user && this.user.user_role.role_name == 'admin') {
      var target = event.target
      if (target && target['classList']) {
        console.log(target['id'])
        let isCanRightClick = (target['id'] == 'videoTraining')
        if (isCanRightClick) {

          event.preventDefault();
          this.menuTopLeftPosition.x = event.clientX + 'px';
          this.menuTopLeftPosition.y = event.clientY + 'px';
          this.matMenuTrigger.openMenu()
        }
      }
    }
  }

  openAddModal() {

    if (isPlatformBrowser(this.platformId)) {

      this.modalService.open('add-videoTraining');
    }
  }

  closeAddModal() {

    if (isPlatformBrowser(this.platformId)) {
      this.modalService.close('add-videoTraining')
    }
  }

  openDeletedVideoTraining() {

    this.is_delete_videoTraining = true;
  }

  cancelDeleteVideoTraining() {

    this.is_delete_videoTraining = false;
  }

  save_videoTraining_media() {

    if (isPlatformBrowser(this.platformId)) {
      this.videoTrainingService.save_videoTraining(this.addVideoTraining)
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.closeAddModal();
              this.reload();
              this.alertService.success(response['massage'], { autoClose: true })
            }
            else {
              this.alertService.error(response['massage'], { autoClose: true })
            }
          });
    }
  }

  deletedVideoTrainingMedia(videoTraining: VideoTraining) {

    this.videoTrainingService.delete_videoTraining(videoTraining)
      .pipe(first())
      .subscribe(
        response => {
          if (response['serviceStatus'] == 'success') {

            this.reload();
            this.alertService.success(response['massage'])
          }
          else {
            this.alertService.error(response['massage'])
          }
        });
  }

  is_valid_videoURL() {

    let url = this.addVideoTraining.video.link;
    let youtube_url = this.match_Youtube_Url(url);

    if (youtube_url) {

      this.addVideoTraining.video.link = 'https://www.youtube.com/embed/' + youtube_url;
      this.is_invalid_url = false;
    }
    else if (this.chech_video_url(url)) {

      this.is_invalid_url = false;
    }
    else {

      this.is_invalid_url = true;
    }

  }

  photoURL() {

  }

  private chech_video_url(url) {

    let is_video_url = false;
    var video = document.createElement("video");
    video.setAttribute("src", url);
    video.addEventListener("canplay", function () {

      is_video_url = true;
      return is_video_url;
    });
    video.addEventListener("error", function () {

      is_video_url = false;
      return is_video_url;
    });

    return is_video_url;
  }

  private match_Youtube_Url(url) {

    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      return url.match(p)[1];
    }
    return false;
  }


}


