
import { Component, OnInit,OnDestroy, Inject, AfterViewChecked, ChangeDetectorRef, Input, Output, EventEmitter, ViewChild, ViewEncapsulation, HostListener, PLATFORM_ID } from '@angular/core';
import { ModalService } from '../helper/modal';
import { NewsService, WindowRefService } from '@app/service/';
import { User } from '@app/model';
import { AlertService } from '@app/helper/alert';
import { environment } from '@environments/environment';
import { ElementImage, ElementText, News } from '@app/model';
import { ImageUploadEnum } from '@app/model/enum/ImageUpload';
import { Observable, Subject } from 'rxjs';;
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import * as Editor from '@app/helper/ckeditor5//build/ckeditor';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatMenu } from '@angular/material/menu';
import { CropperComponent, ImageCropperResult } from 'angular-cropperjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ConfirmationDialogService } from '@app/helper/confirmation-dialog/confirmation-dialog.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { TokenStorageService } from '@app/service';
import { Subscription } from "rxjs";

@Component({
    selector: 'story',
    templateUrl: './story.component.html',
    styleUrls: ['./story.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class StoryComponent implements OnInit,OnDestroy, AfterViewChecked {

    story: News;

    menuTopLeftPosition = { x: '0', y: '0' }
    @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

    newsList: News[];
    pageOfNews: News[] = [];

    lang: string = localStorage.getItem('lang');
    isAddNews: boolean = false;

    imageCover: any;
    apiUrl: string = environment.apiUrl;

    imageResult: any;

    isDeleteNews: boolean = false;
    activedPage: number = 1;
    user: User = this.tokenService.getUser()

    config = {
        aspectRatio: 1 / 1,
        dragMode: 'move',
        background: true,
        movable: true,
        rotatable: true,
        scalable: false,
        zoomable: true,
        viewMode: 1,
        checkImageOrigin: true,
        checkCrossOrigin: true
    };

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'รายละเอียด',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'KANIT',
        sanitize: false,
        toolbarHiddenButtons: [
            ['bold']
        ],
        customClasses: [
            {
                name: "quote",
                class: "quote",
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: "titleText",
                class: "titleText",
                tag: "h1",
            },
        ]
    };

    @ViewChild('angularCropper') public angularCropper: CropperComponent;

    public ImageUploadEnum = ImageUploadEnum;
    public imageState = ImageUploadEnum.view;

    private subscriptions: Subscription[] = [];

    constructor(private newsService: NewsService,
        private modalService: ModalService,
        // private ng2ImgMax: Ng2ImgMaxService,
        private sanitizer: DomSanitizer,
        private confirmationDialogService: ConfirmationDialogService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private windowRefService: WindowRefService,
        private tokenService: TokenStorageService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef) {



    }

    ngOnInit() {
        // an example array of 150 items to be paged
        this.getUser();
        this.reload()
        this.defaultPage();
    }

    ngAfterViewChecked() {

        this.customTextPagination()
    }

    getUser() {

        this.subscriptions.push(this.tokenService.getUserBehavior().subscribe(user => {

            this.user = user;
        }))
    }

    reload() {

        this.story = new News()
        this.story.cover_image = new ElementImage()
        this.story.topic = new ElementText()
        this.story.topic.th_html = ''
        this.story.detail = new ElementText()
        this.story.detail.th_html = ''
        this.story.news_type = 2
        if (isPlatformBrowser(this.platformId)) {
            this.imageCover = this.apiUrl + '/media/default_profile/no-image.png';
        }
        this.get_newsList()

    }

    defaultPage() {
        let activedPage = Number(localStorage.getItem('story-page'));
        if (activedPage) {

            this.activedPage = activedPage;
        }
    } Î

    get_newsList() {
        if (isPlatformBrowser(this.platformId)) {
            this.newsService.get_storyList().pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            this.newsList = response.data_list;

                        }
                        else {

                        }

                    },
                    error: error => {
                    }
                });
        }
    }

    onRightClick(event: MouseEvent) {

        if (isPlatformBrowser(this.platformId)) {
            if (this.user && this.user.user_role.role_name == 'admin') {
                var target = event.target
                if (target && target['classList']) {

                    let isCanRightClick = (target['id'] == 'news')
                    if (isCanRightClick) {

                        event.preventDefault();
                        this.menuTopLeftPosition.x = event.clientX + 'px';
                        this.menuTopLeftPosition.y = event.clientY + 'px';

                        this.story = new News()
                        this.story.cover_image = new ElementImage()
                        this.story.topic = new ElementText()
                        this.story.topic.th_html = ''
                        // this.news.title = new ElementText()
                        // this.news.title.th_html = ''
                        this.story.detail = new ElementText()
                        this.story.detail.th_html = ''
                        this.story.news_type = 2

                        this.isAddNews = true;

                        this.matMenuTrigger.openMenu()
                    }
                }
            }
        }
    }

    openSaveNewsModal() {

        if (isPlatformBrowser(this.platformId)) {
            // this.isAddNews = true;
            this.imageCover = this.apiUrl + '/media/default_profile/no-image.png';
            this.modalService.open('add-story');
        }

    }

    setImageCropperStatusCropper(image_result) {

        if (isPlatformBrowser(this.platformId)) {
            this.imageCover = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
            this.imageState = ImageUploadEnum.view;
        }

    }

    checkstatus(event: any) {

        if (isPlatformBrowser(this.platformId)) {
            if (event.blob === undefined) {
                return;
            }

            let urlCreator = window.URL;
            this.imageResult = this.sanitizer.bypassSecurityTrustUrl(
                urlCreator.createObjectURL(new Blob(event.blob)));
        }
    }

    changedImageCover(event: any): void {
        if (isPlatformBrowser(this.platformId)) {
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.onload = (event: any) => {

                    this.imageCover = event.target.result;
                    this.imageState = ImageUploadEnum.Cropper;;
                    // this.imageUrl = event.target.result
                    // this.ng2ImgMax.resizeImage(event.target.result, 400, 400).subscribe(
                    //     result => {
                    //         this.imageState = ImageUploadEnum.Cropper;;
                    //         this.imageCover = result;
                    //     },
                    //     error => {

                    //         this.imageCover = event.target.result;
                    //     }
                    // );

                }
                reader.readAsDataURL(event.target.files[0]);
            }
        }
    }

    CropCoverImage() {

        if (isPlatformBrowser(this.platformId)) {
            this.angularCropper.exportCanvas();
        }
    }

    // changedImageCover(event: any): void {
    //     if (event.target.files && event.target.files[0]) {
    //         var reader = new FileReader();
    //         reader.onload = (event: any) => {

    //             this.imageState = ImageUploadEnum.Cropper;
    //             // this.imageUrl = event.target.result
    //             this.ng2ImgMax.resizeImage(event.target.result, 450, 450).subscribe(
    //                 result => {
    //                     this.imageCover = result;
    //                 },
    //                 error => {

    //                     this.imageCover = event.target.result;
    //                 }
    //             );  
    //         }
    //         reader.readAsDataURL(event.target.files[0]);
    //     }
    // }

    saveNews() {
        if (isPlatformBrowser(this.platformId)) {

            let image = this.checkImage(this.imageCover);
            this.newsService.save_news(this.story, image).pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            this.modalService.close('add-story');
                            this.alertService.success(response['massage'], { autoClose: true })
                            this.reload()

                        }
                        else {

                            this.alertService.error(response['massage'], { autoClose: true })

                        }
                    },
                    error: error => {
                    }
                });
        }
    }

    checkImage(image) {

        if (this.isBase64(image)) {

            return this.dataURItoBlob(image);
        }
        else {

            return null;
        }

    }

    private isBase64(str) {

        return str.startsWith("data:");
    }

    cancleNews() {

        if (isPlatformBrowser(this.platformId)) {
            this.reload()
            this.modalService.close('add-story');
        }

    }


    /////// detect change page  ////////
    onChangePage(pageData: any) {
        // update current page of items
        if (isPlatformBrowser(this.platformId)) {

            let itemOfPage = pageData['itemOfPage'];
            let activePage = pageData['activePage'];
            this.pageOfNews = itemOfPage;
            this.activedPage = activePage;
            localStorage.setItem('story-page', String(activePage));
            this.cdr.detectChanges();
        }
    }

    linkNewsDetail(link) {

        window.open(link, "_blank");
       
    }

    openDeleteNews() {

        this.isDeleteNews = true;
    }

    closeDeleteNews() {

        this.isDeleteNews = false;
    }

    deleteNews(news: News) {
        this.confirmationDialogService.confirm('ลบข่าว', 'คุณต้องการลบเรื่องราวหรือไม่')
            .then((confirmed) => {
                this.newsService.deleted_news(news).pipe(first())
                    .subscribe({
                        next: (response: any) => {

                            if (response.serviceStatus == 'success') {

                                this.reload()


                            }
                            else {

                            }

                        },
                        error: error => {
                        }
                    });
            }
            )
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

    }

    private dataURItoBlob(dataURI) {

        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var arrayBuffer = new ArrayBuffer(byteString.length);
        var _ia = new Uint8Array(arrayBuffer);

        for (var i = 0; i < byteString.length; i++) {
            _ia[i] = byteString.charCodeAt(i);
        }

        var dataView = new DataView(arrayBuffer);
        var blob = new Blob([dataView], { type: mimeString });
        return blob;
    }

    private toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    // @HostListener('window:scroll', ['$event'])
    // doSomething(event) {

    // this.customTextPagination()
    // console.log(news.childNodes[0])
    // console.log(news[0])


    // let news = document.getElementById('news')


    // let bottomOfScrollbar = window.innerHeight +window.pageYOffset;
    // if(bottomOfScrollbar >news.offsetTop+news.){

    //     // news.classList.remove("opacity-animation");
    //     // void news.offsetWidth;
    //     news.classList.add("opacity-animation")
    // }

    // console.log( 'Height' +t1.offsetHeight +"top" +(t1.offsetTop + t1.offsetHeight)  );
    // console.log('this object:' + news.offsetTop + ' Height' + (window.innerHeight + window.pageYOffset))
    // }

    customTextPagination() {
        if (isPlatformBrowser(this.platformId)) {

            let firstPage = this.windowRefService.nativeWindow.document.getElementsByClassName('first-item')
            if (firstPage.length > 1) {
                let firstPageItem = firstPage[1].childNodes[0]
                if (this.lang == 'TH') {

                    firstPageItem.textContent = 'หน้าแรก';
                }
                else {
                    firstPageItem.textContent = 'First Page';
                }
            }

            let previousPage = this.windowRefService.nativeWindow.document.getElementsByClassName('previous-item')
            if (previousPage.length > 1) {
                let previousPageItem = previousPage[1].childNodes[0]
                if (this.lang == 'TH') {

                    previousPageItem.textContent = 'หน้าก่อนหน้า';
                }
                else {
                    previousPageItem.textContent = 'Previous Page';
                }
            }


            let nextPage = this.windowRefService.nativeWindow.document.getElementsByClassName('next-item')
            if (nextPage.length > 1) {
                let nextPageItem = nextPage[1].childNodes[0]
                if (this.lang == 'TH') {

                    nextPageItem.textContent = 'หน้าถัดไป';
                }
                else {
                    nextPageItem.textContent = 'Next Page';
                }
            }


            let lastPage = this.windowRefService.nativeWindow.document.getElementsByClassName('last-item')
            if (nextPage.length > 1) {
                let lastPageItem = lastPage[1].childNodes[0]
                if (this.lang == 'TH') {

                    lastPageItem.textContent = 'หน้าสุดท้าย';
                }
                else {
                    lastPageItem.textContent = 'Last Page';
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s?.unsubscribe());
    }
}



