import { Component, OnInit, AfterViewInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { SectionForm } from '@app/assessment/question-form/model';
import { FormStateEnum } from '@app/assessment/question-form/model/enum/FormStateEnum';
import { selectLang } from '@environments/languageEnvironment';
import { Observable } from 'rxjs';
import { BaseFormComponent } from '@app/assessment/question-form/base-form/baseform.component';
import { WindowRefService } from '@app/service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'section-form',
  templateUrl: './section-form.compoent.html',
  styleUrls: ['./section-form.component.css']
})

export class SectionFormComponent extends BaseFormComponent implements OnInit, AfterViewInit {


  baseForm: SectionForm;

  constructor(windowRefService:WindowRefService,public viewPortScroller: ViewportScroller){

    super(windowRefService);

  }


  ngOnInit() {
    /* extends method from BaseFormComponent */
    super.ngOnInit();
  }

  ngAfterViewInit(){
    
    if(this.is_views){

      let el = this.windowRefService.nativeWindow.document.getElementById(this.baseForm.form_no);
      window.scrollTo(0, 0);

    }
    
  }
  

}
