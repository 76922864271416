import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/service';
import { TokenStorageService } from '@app/service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private authenticationService: AuthenticationService, private tokenService: TokenStorageService,private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {

    let authReq = req;
    const token = this.tokenService.getToken();
    authReq = this.addTokenHeader(req, token);

    return next.handle(authReq).pipe(catchError(error => {
      console.log(error)
      if (!authReq.url.includes('accounts/login') && error.status === 401) {

        return this.handle401Error(authReq, next);
      }
      else{
        this.addTokenHeader(req, token)

      }
    }));

    return next.handle(authReq);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    const refreshToken = this.tokenService.getRefreshToken();

    if (refreshToken) {
      return this.authenticationService.refreshToken(refreshToken).pipe(
        switchMap((account: any) => {

          return next.handle(this.addTokenHeader(request, account.access_token));
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
    }
    else{

      this.authenticationService.logout()
      this.router.navigate(['/login'])
    }



  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {

    if (!(request.body instanceof FormData)) {
      return request.clone({ setHeaders: { Authorization: `Token ${token}` }, body: { ...request.body, lang: localStorage.getItem('lang') } });
    }
    else {

      return request.clone({ setHeaders: { Authorization: `Token ${token}` } });
    }
  }

}

    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        // const currentUser = this.authenticationService.currentUserValue();
        // const isLoggedIn = currentUser && currentUser.access_token;
        // const isApiUrl = request.url.startsWith(environment.apiUrl);

        // if (!(request.body instanceof FormData)) {
        //     request = request.clone({
        //         body: { ...request.body, lang: localStorage.getItem('lang') }
        //     })
        // }

        // if (isLoggedIn && isApiUrl) {
        //     request = request.clone({
        //         setHeaders: {
        //             Authorization: `Token ${currentUser.access_token}`
        //         }
        //     });
        // }

        // return next.handle(request);
    // }
