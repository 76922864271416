import { Component, Input, Output, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { TitleForm } from '@app/assessment/question-form/model'
import { Observable } from 'rxjs';
import { BaseFormComponent } from '@app/assessment/question-form/base-form/baseform.component';

@Component({
  selector: 'title-form',
  templateUrl: './title-form.component.html',
  styleUrls: ['./title-form.component.css']
})

export class TitleFormComponent extends BaseFormComponent implements OnInit {

  baseForm: TitleForm;

  ngOnInit() {
    /* extends method from BaseFormComponent */
    super.ngOnInit();

  }
}
