import { NgModule } from '@angular/core';
import { ImageComponentDirective,TextEditorDirective,WrapperComponentDirective } from '@app/editor/directive';
import {TestComponent} from '@app/editor/test/test.component'
import {ContextMenuComponent} from '@app/editor/context-menu/context-menu.compoent'

@NgModule({
  imports: [],
    declarations: [TestComponent,ContextMenuComponent,WrapperComponentDirective,ImageComponentDirective,TextEditorDirective],
    // entryComponents: [ TitleFormComponent ],
    exports: [TestComponent,ContextMenuComponent,WrapperComponentDirective,ImageComponentDirective,TextEditorDirective]
})

export class EditorModule { }
