import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { ChatChannel, Chat } from '@app/model';
import { ChatService } from '../service/';
import { ChatMassageService } from './chat.service';
import { AuthenticationService } from '@app/service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import { Observable, Subject } from 'rxjs';;
import { first } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { WindowRefService } from '@app/service';


@Component({
    selector: 'chrt',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ChatComponent implements OnInit, AfterViewInit {

    apiUrl: string = environment.apiUrl

    chatChannel_list: ChatChannel[] = []
    selected_chat_channel: ChatChannel
    chat_list: Chat[]
    currentUser: any;
    chatChannelSubscription: Subscription;

    constructor(private authenticationService: AuthenticationService,
        private chatService: ChatService,
        private chatMassageService: ChatMassageService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private windowRefService: WindowRefService
    ) {

        if (isPlatformBrowser(this.platformId)) {
            this.chatChannelSubscription = this.chatMassageService.getMessageChat().subscribe(messageChat => {

                if (messageChat) {


                    this.get_chatChannel()

                }

            });
        }
    }

    ngOnInit(): void {

        if (isPlatformBrowser(this.platformId)) {
            this.get_chatChannel()
            this.currentUser = this.authenticationService.currentUserValue;
            this.chatService.$chat_reload.subscribe(
                (redirect_url) => {

                    this.get_chatChannel()
                }
            )
        }
    }

    ngAfterViewInit() {

    }

    get_chatChannel() {

        if (isPlatformBrowser(this.platformId)) {
            this.chatService.get_chatChannel().pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            this.chatChannel_list = response.data_list.sort((a, b) => new Date(b.updated_date).getTime() - new Date(a.updated_date).getTime());

                            if (this.chatChannel_list.length > 0 && this.selected_chat_channel == null) {

                                this.chatMassageService.changeSelectedChatChannel(this.chatChannel_list[0]);
                                this.selected_chat_channel = this.chatChannel_list[0]
                            }
                            else if (this.chatChannel_list.length > 0 && this.selected_chat_channel != null) {

                                this.chatMassageService.changeSelectedChatChannel(this.chatChannel_list.filter(chatChannel => chatChannel.chatChannel_id === this.selected_chat_channel.chatChannel_id)[0]);
                                this.selected_chat_channel = this.chatChannel_list.filter(chatChannel => chatChannel.chatChannel_id === this.selected_chat_channel.chatChannel_id)[0]
                            }
                        }
                        else {

                        }

                    },
                    error: error => {
                    }
                });
        }

    }

    selected_chatChannel(chatChannel: ChatChannel) {

        if (isPlatformBrowser(this.platformId)) {
            this.selected_chat_channel = chatChannel
            this.chatMassageService.changeSelectedChatChannel(chatChannel)
        }

    }

    calculate_notification(chatChannel: ChatChannel) {

        if (isPlatformBrowser(this.platformId)) {
            return chatChannel.chat_list.filter(c => c.is_readed == false && c.sent_user.user_key != this.authenticationService.currentUserValue.user.user_key).length
        }
    }


}

