
import {Deserializable} from "./deserializable.model";
import { FeelingBlog } from "./FeelingBlog";
import { TagBlog } from "./TagBlog"
import { User } from "./User";
export class Blog implements Deserializable{

    blog_id:number;
    topic:string;
    detail:string;
    parent_blog:Blog;
    feelingBlog_list:FeelingBlog[];
    tagBlog_list:TagBlog[];
    blog_list:Blog[];
    created_user:User;
    is_root:boolean;
    is_actived:boolean;
    is_add:boolean;
    updated_date:Date;
  
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



