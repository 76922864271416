import { Component, OnInit,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'training-tsl',
  templateUrl: './training-tsl.component.html',
  styleUrls: ['./training-tsl.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TrainingTslComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
