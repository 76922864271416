import { Component, OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { ModalService } from '../helper/modal';
import { ElementService } from '../service/';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.css'],
    encapsulation: ViewEncapsulation.None
  })
  export class MapComponent implements OnInit {

    app_name = environment.app_name
    apiUrl:string =environment.apiUrl
 
    constructor() { }

    ngOnInit(): void {
  
      }

}


