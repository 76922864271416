import { Component, ViewEncapsulation,ViewContainerRef,ViewChild , ElementRef, Input, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WindowRefService } from '@app/service'
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ModalService } from './modal.service';
import { FileContainerDirective } from '@app/element/directive/file-container/file-container.directive'

@Component({
    selector: 'jw-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['modal.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class JWModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @ViewChild(FileContainerDirective,{ static: true })  fileContainer: FileContainerDirective;
    private element: any;

    constructor(private modalService: ModalService,
        private el: ElementRef,
        public vcRef: ViewContainerRef,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private windowRefService: WindowRefService,
    ) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }
        if (isPlatformBrowser(this.platformId)) {

            console.log(this.windowRefService.nativeWindow.document.body)
            // move element to bottom of page (just before </body>) so it can be displayed above everything else
            this.windowRefService.nativeWindow.document.body.appendChild(this.element);

            // close modal on background click
            this.element.addEventListener('click', (el:any) => {
                if (el.target.className === 'jw-modal') {
                    this.close();
                }
            });
        }

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.element.style.display = 'block';
        if (isPlatformBrowser(this.platformId)) {

            this.windowRefService.nativeWindow.document.body.classList.add('jw-modal-open');
        }
    }

    // close modal
    close(): void {

        this.element.style.display = 'none';
        console.log(this.id)
        if (isPlatformBrowser(this.platformId)) {

            this.windowRefService.nativeWindow.document.body.classList.remove('jw-modal-open');
        }
    }
}
