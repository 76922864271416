import { Component, OnInit,Inject,PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from '@app/helper/alert';
import { User } from '@app/model';
import { selectLang } from '@environments/languageEnvironment';
import { ChangePassword } from '@app/model/enum/ChangePassword';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { WindowRefService } from '@app/service';

@Component({
    templateUrl: 'forget-password.component.html',
    styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

    token: string = ""
    user: User = new User();
    lang: string = localStorage.getItem('lang')
    ChangePassword = ChangePassword
    state = ChangePassword.unfinished
    selectLang = selectLang
    constructor(
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
        public router: Router,
        private alertService: AlertService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private windowRefService: WindowRefService

    ) {

    }

    ngOnInit() {

        if (isPlatformBrowser(this.platformId)) {
            this.token = this.activatedRoute.snapshot.paramMap.get("token");

            this.authenticationService.check_forgetPassword(this.token).pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            this.user = response.data
                            this.user.password = ''

                        }
                        else {

                            this.alertService.error(response.massage)
                        }

                    },
                    error: error => {
                    }
                });

        }

    }

    change_password() {

        if (isPlatformBrowser(this.platformId)) {
            this.authenticationService.change_password(this.user).pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            this.alertService.success(response.massage)
                            this.state = ChangePassword.finished

                        }
                        else {

                            this.alertService.error(response.massage)
                        }

                    },
                    error: error => {
                    }
                });
        }

    }

    cancel_forgetPassword() {

        this.router.navigate(['/login']);
    }


}
