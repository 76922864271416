
import {Deserializable} from "./deserializable.model";

export class ElementVideo implements Deserializable{

    item_name:string;
    th_html:string;
    en_html:string;
    text:string;
    link:string;
    file:File;
    styles:string;
    is_external:boolean;
    updated_by:string;
    updated_date:Date;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
