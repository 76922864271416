import { Deserializable } from "./deserializable.model";
import { Validation } from "./Validation"

export class BaseForm {

  form_no: string;
  type = null;
  question: string ='';
  is_other: boolean = false;
  is_choice_other: boolean=false;
  choice_list: any[] = [];
  rows_list:any[] = [];
  title:string = '';
  description:string = '';
  link:string = '';
  is_required: boolean = false;
  is_deleted: boolean = false;
  order_number: number = 0;
  validation:Validation = new Validation();


  // form_no:string;
  // order_number:number;
  // type.
  // is_required:boolean = false
  // is_deleted:boolean = false;
  // is_focused:boolean = false;
  // is_newer:boolean = false;
  // current_number:number

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
