import { Injectable,PLATFORM_ID, Inject} from '@angular/core';
import { Subject } from 'rxjs';
import { WindowRefService } from '@app/service'
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LoginService {
    private modals: any;
    private redirect_url = new Subject<string>();
    private returnUrl:string = ''
    public  $redirect_url = this.redirect_url.asObservable();
    constructor(@Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService,){

    }
    set_redirect_url(url:any){
        this.redirect_url.next(url);
        this.returnUrl = url;
    }

    add(modal: any) {
        this.modals = modal
        
    }

    remove(id: string) {
        // remove modal from array of active modals
        // this.modals = this.modals.filter(x => x.id !== id);
    }

    open() {
        // open modal specified by id

        this.modals.content.nativeElement.style.display = 'block';
        this.modals.content.nativeElement.classList.add('dropdown-backdrop');
        this.modals.content.nativeElement.style.overflow = "scroll";

        this.modals.returnUrl = this.returnUrl;

    }

    close() {

        this.modals.content.nativeElement.style.display = 'none';
        this.modals.content.nativeElement.position = 'none'
        if (isPlatformBrowser(this.platformId)) {

            var elements = this.windowRefService.nativeWindow.document.getElementsByClassName("modal-backdrop");
            while (elements.length > 0) elements[0].remove();
        }
    }

    signOut(){

        this.modals.socialAuthService.signOut();
    }
}