import { Directive, ElementRef, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[ellipsis]'
})

export class EllipsisDirective implements AfterViewChecked {


    constructor(private elementRef: ElementRef) {

    }

    ngAfterViewChecked(){
        
        this.ellipsisText();

    }

    ellipsisText(){

        let textContent = this.elementRef.nativeElement.textContent;
        let ellipsisText = '';
        if(textContent.length > 40){

            ellipsisText = textContent.substring(1,40)
            ellipsisText = ellipsisText + ' ...';
        }

        this.elementRef.nativeElement.textContent = ellipsisText;
    }


}