
import {Deserializable} from "./deserializable.model";
import { User } from "./User";
import { ElementText } from "./ElementText"
import { ElementImage } from "./ElementImage"
export class Partner {

  partner_id:number;
  topic:ElementText;
  image:ElementImage;
  created_date:Date;
  created_user:User;
  is_actived:boolean;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
