import {Deserializable} from "./deserializable.model";
import { BaseForm } from "./BaseForm"

export class SectionForm extends BaseForm{

  form_no:string;
  type:string = 'section';
  title:string = '';
  description:string = '';

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
