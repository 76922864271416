import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { User, LoginAccess, EndPoint, UserRole, Permission } from '@app/model';
import { SocialAuthService } from 'angularx-social-login';
import { LoginService } from '@app/login/login.service';
import { TokenStorageService } from '@app/service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  public currentUserSubject: BehaviorSubject<LoginAccess>;
  // public currentUser: Observable<LoginAccess>;

  constructor(private http: HttpClient,
    private socialAuthService: SocialAuthService,
    private loginService: LoginService,
    private tokenService: TokenStorageService) {

    this.currentUserSubject = new BehaviorSubject<LoginAccess>(JSON.parse(localStorage.getItem('currentUser')));
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string, is_remember: boolean) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/login`, { username: username, password: password, is_remember: is_remember })
      .pipe(map(account => {
        if (account.serviceStatus == 'success') {

          this.tokenService.saveToken(account.data.access_token);
          this.tokenService.saveRefreshToken(account.data.access_refresh);
          this.tokenService.saveUser(account.data.user);
          this.currentUserSubject.next(account.data);
          // this.startRefreshTokenTimer();
          return account

        }
        else {
          return account
        }

      }));
  }

  login_socialMedia(user: User, is_remember: boolean) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/login_socialMedia`,
      { user: user, is_remember: is_remember })
      .pipe(map(account => {
        if (account.serviceStatus == 'success') {
          this.tokenService.saveToken(account.data.access_token);
          this.tokenService.saveRefreshToken(account.data.access_refresh);
          this.tokenService.saveUser(account.data.user);
          this.currentUserSubject.next(account.data);
          // localStorage.setItem('currentUser', JSON.stringify(account.data));
          // this.currentUserSubject.next(account.data);
          this.startRefreshTokenTimer();
          return account;

        }
        else {

          return account;
        }

      }));
  }

  email_register(user: User) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/email_register`,
      { user: user })
      .pipe(map(user => {

        return user;

      }));
  }

  comfirm_register(email: string) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/comfirm_register`,
      { email: email })
      .pipe(map(user => {

        return user;

      }));
  }

  forget_password(email: string) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/forget_password`,
      { email: email })
      .pipe(map(user => {

        return user;

      }));
  }
  check_forgetPassword(token: string) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/check_forgetPassword`,
      { token: token })
      .pipe(map(user => {

        return user;

      }));

  }

  change_password(user: User) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/change_password`,
      { user: user })
      .pipe(map(user => {

        return user;

      }));

  }


  edited_user(user: User, image_file: any) {
    const formData = new FormData();
    formData.append('image_file', image_file);
    formData.append('user', JSON.stringify(user));
    formData.append('lang', localStorage.getItem('lang'));

    return this.http.post<any>(`${environment.apiUrl}/accounts/edited_user`,
      formData)
      .pipe(map(user => {

        return user;

      }));
  }

  

  edited_picture_user(user: User, image_file: any) {
    
    const formData = new FormData();
    formData.append('image_file', image_file);
    formData.append('user', JSON.stringify(user));
    formData.append('lang', localStorage.getItem('lang'));

    return this.http.post<any>(`${environment.apiUrl}/accounts/edited_picture_user`,
      formData)
      .pipe(map(user => {

        return user;

      }));
  }

  save_endPoint(endPoint: EndPoint) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/save_endPoint`,
      { endPoint: endPoint })
      .pipe(map(endPoint => {

        return endPoint;

      }));
  }

  edit_endPoint(endPoint: EndPoint) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/edited_endPoint`,
      { endPoint: endPoint })
      .pipe(map(endPoint => {

        return endPoint;

      }));
  }

  save_endPoint_userRole_permission(endPoint: EndPoint) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/save_endPoint_userRole_permission`,
      { endPoint: endPoint })
      .pipe(map(endPoint => {

        return endPoint;

      }));
  }

  delete_endPoint_userRole_permission(endPoint_list: EndPoint[]) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/delete_endPoint_userRole_permission`,
      { endPoint_list: endPoint_list })
      .pipe(map(endPoint => {

        return endPoint;

      }));
  }

  delete_endPoint_list(endPoint_list: EndPoint[]) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/deleted_endPoint`,
      { 'endPoint_list': endPoint_list })
      .pipe(map(endPoint => {

        return endPoint;

      }));
  }

  save_endPoint_user_permission(endPoint: EndPoint) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/save_endPoint_user_permission`,
      { endPoint: endPoint })
      .pipe(map(endPoint => {

        return endPoint;

      }));
  }

  delete_endPoint_user_permission(endPoint_list: EndPoint[]) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/delete_endPoint_user_permission`,
      { 'endPoint_list': endPoint_list })
      .pipe(map(endPoint => {

        return endPoint;

      }));
  }

  endPoint_list() {

    return this.http.get<any>(`${environment.apiUrl}/accounts/endPoint_list`)
      .pipe(map(endPoint => {
        return endPoint;
      }));

  }

  save_userRole(userRole: UserRole) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/save_userRole`,
      { userRole: userRole })
      .pipe(map(userRole => {

        return userRole;
      }));
  }

  edit_userRole(userRole: UserRole) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/edited_userRole`,
      { userRole: userRole })
      .pipe(map(userRole => {

        return userRole;

      }));
  }

  delete_userRole_list(userRole_list: UserRole[]) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/deleted_userRole`,
      { 'userRole_list': userRole_list })
      .pipe(map(userRole => {

        return userRole;

      }));
  }

  userRole_list() {

    return this.http.get<any>(`${environment.apiUrl}/accounts/userRole_list`)
      .pipe(map(userRole => {
        return userRole;
      }));

  }

  userType_list() {

    return this.http.get<any>(`${environment.apiUrl}/accounts/userType_list`)
      .pipe(map(userRole => {
        return userRole;
      }));

  }

  save_user(user: User) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/save_user`,
      { user: user })
      .pipe(map(user => {

        return user;
      }));
  }

  edited_user_admin(user: User) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/edited_user_admin`,
      { user: user })
      .pipe(map(user => {

        return user;

      }));
  }

  delete_user_list(user_list: User[]) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/deleted_user`,
      { 'user_list': user_list })
      .pipe(map(user => {

        return user;

      }));
  }

  user_list() {

    return this.http.get<any>(`${environment.apiUrl}/accounts/user_list`)
      .pipe(map(user => {
        return user;
      }));

  }

  socialMediaUser_list() {

    return this.http.get<any>(`${environment.apiUrl}/accounts/socialMedia_user_list`)
      .pipe(map(socialMediaUser => {
        return socialMediaUser;
      }));

  }

  permissionRole_list() {

    return this.http.get<any>(`${environment.apiUrl}/accounts/permissionRole_list`)
      .pipe(map(permissionRole => {
        return permissionRole;
      }));
  }


  permissionUser_list() {

    return this.http.get<any>(`${environment.apiUrl}/accounts/permissionUser_list`)
      .pipe(map(permissionUser => {
        return permissionUser;
      }));
  }

  save_permission(permission: Permission) {

    return this.http.post<any>(`${environment.apiUrl}/accounts/save_permission`,
      { 'permission': permission })
      .pipe(map(permission => {

        return permission;

      }));
  }

  get_permission(permission:string) {
    
    let params = new HttpParams();
    params = params.append('permission', permission);

    return this.http.get(`${environment.apiUrl}/accounts/get_permission`,
      { params: params }
    )
      .pipe(map(permission => {

        return new Permission().deserialize(permission);

      }));;
  }
  get_permission_promise(permission:string) {

    let params = new HttpParams();
    params = params.append('permission', permission);
    return this.http.get(`${environment.apiUrl}/accounts/get_permission`,
      { params: params }
    );
  }

  get_permission_list(){

    return this.http.get<any>(`${environment.apiUrl}/accounts/get_permission_list`)
      .pipe(map(permission => {
        return permission;
      }));
  }




  refreshToken(refresh) {

    // let refresh = this.currentUserSubject.value.access_refresh;
    // this.stopRefreshTokenTimer()
    return this.http.post<any>(`${environment.apiUrl}/accounts/refresh_token`, { refresh: refresh },)
      .pipe(map((account) => {

        // this.currentUserSubject.next(account.data);
        // localStorage.setItem('currentUser', JSON.stringify(account.data));
        // this.startRefreshTokenTimer();
        return account.data;
      }));


  }
  private refreshTokenTimeout: any;

  private startRefreshTokenTimer() {

    /* refresh token  */
    // this.refreshTokenTimeout = setTimeout(() => { this.refreshToken().subscribe(); }, 30000);
  }

  private stopRefreshTokenTimer() {

    clearTimeout(this.refreshTokenTimeout);
  }

  logout() {
    
    let user = this.tokenService.getUser();

    if(user.provider=='GOOGLE'||user.provider=='FACEBOOK'){

      this.socialAuthService.signOut();
    }
    this.tokenService.deleteUser()
    this.tokenService.deleteToken()
    this.tokenService.deleteRefreshToken()
    this.tokenService.saveUserBehavior(null)
    
    
    // if (localStorage.getItem('currentUser')) {

    //   localStorage.removeItem('currentUser');
    // }
    // if (this.currentUserSubject) {

    //   this.currentUserSubject.next(null);
    // }
    // this.stopRefreshTokenTimer()
  }

  exportImage(endPoint) {
    return this.http.get(endPoint, 
        {responseType: 'blob'});
}
}
