import { Component,Input,Output,EventEmitter } from '@angular/core';
import { EditorService } from '../editor.service';


@Component({
  selector: 'image-cropper-content',
  templateUrl: './image-cropper-content.component.html',
  styleUrls: ['./image-cropper-content.component.css'],
})
export class ImageCropperContentComponent {

    @Input() image : any;
    @Input() image_cropper_status:number;
    @Input() height_ratior_image:number;
    @Input() width_ratior_image:number;
    @Output() set_image_cropper_Status = new EventEmitter< number>();
    @Output() result_crop_image = new EventEmitter< any>();

    constructor(private editor: EditorService) {
    }
    set_image_cropper_status_cropper(image_result){  
        
        this.result_crop_image.emit(image_result)
    }

}