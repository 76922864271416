
import {Deserializable} from "./deserializable.model";
import {User} from "./User"
import {EndPoint} from "./EndPoint"
import {UserType} from "./UserType"

export class UserRole implements Deserializable{

    role_id:number;
    role_name:string;
    limited_access:number;
    is_active:boolean;
    is_deleted:boolean = false;
    user_list:User[];
    endpoint_list:EndPoint[]
    userType_list:UserType[]
    created_date:Date
    created_by:string;
    updated_by:string;
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
