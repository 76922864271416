import { Component, OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { ModalService } from '../helper/modal';
import { ElementService } from '../service';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { selectLang } from '@environments/languageEnvironment';


@Component({
  selector: 'forbiden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ForbidenPageCompoent{

  selectLang = selectLang
  lang: string;

  constructor(private router: Router,){
    this.lang = localStorage.getItem('lang')
  }

  back_before_page(){

    let beforePage = localStorage.getItem('beforePage');

    if(beforePage){

      localStorage.setItem('beforePage', '/home');
      this.router.navigate([beforePage])
    }
    else{

      this.router.navigate(['/home'])
    }
  }

}
