
import {Deserializable} from "./deserializable.model";
import {ElementText} from "./ElementText";
import {ElementImage} from "./ElementImage";
import {User} from "./User"
export class SystemInfo implements Deserializable{

    systemInfo_id:number;
    views_page:number;
    is_actived:boolean;
    updated_date:Date;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



