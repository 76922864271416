import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from '@app/helper/alert';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { WindowRefService } from '@app/service';

@Component({
    templateUrl: 'comfirm-register.component.html',
    styleUrls: ['./comfirm-register.component.css']
})
export class ComfirmRegisterComponent implements OnInit {

    email: string = ""
    constructor(
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
        public router: Router,
        private alertService: AlertService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private windowRefService: WindowRefService

    ) {

    }

    ngOnInit() {

        if (isPlatformBrowser(this.platformId)) {
            this.email = this.activatedRoute.snapshot.paramMap.get("email");
            this.comfirm_register()
        }
    }

    comfirm_register() {

        if (isPlatformBrowser(this.platformId)) {
            this.authenticationService.comfirm_register(this.email).pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            this.router.navigate(['/login']);
                            this.alertService.success(response.massage)

                        }
                        else {

                            this.alertService.error(response.massage)

                        }

                    },
                    error: error => {
                    }
                });
        }
    }
}
