
import { Deserializable } from "./deserializable.model";
import { User } from "./User";
import { ElementText } from "./ElementText"
import { ElementFiles } from "./ElementFiles"
import { ElementVideo } from "./ElementVideo"
export class VideoTraining {

    videoTraining_id: number;
    topic: ElementText;
    detail: ElementText;
    video: ElementVideo;
    created_date: Date;
    created_user: User;
    is_actived: boolean;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
