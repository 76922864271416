import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JWModalComponent } from './modal.component';
import { FileContainerDirective} from '@app/element/directive/file-container/file-container.directive';

@NgModule({
    imports: [CommonModule,],
    declarations: [JWModalComponent,FileContainerDirective],
    exports: [JWModalComponent,FileContainerDirective]
})
export class JWModalModule { }
