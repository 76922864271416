import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { WebsocketService } from './websocket.service';
import { Blog,FeelingBlog,TagBlog } from '@app/model';
import { AbstractService } from './abstract.service';
import {Message} from './chat.service'



@Injectable({ providedIn: 'root' })
export class BlogService extends AbstractService {
    headers: any
    authToken: string;
    username: string;
    public messages;
    websocketUrl: string = environment.websocketUrl;


    constructor(
        private router: Router,
        // private wsService: WebsocketService,
        http: HttpClient
    ) {

        super(http)
        // this.messages = <Subject<Message>>wsService.connect(this.websocketUrl+'/ws/blog/blog' + '/').map(
        //     (response: MessageEvent): Message => {
        //         let data = JSON.parse(response.data);
        //         return data
        //     }
        // );
    }

    get_blogList() {

        return this.http.get(`${environment.apiUrl}/api/blog/blog_list`)
            .pipe(map(Blog => {

                return Blog;

            }));;
    }

    add_blog(blog:Blog) {

        return this.http.post<any>(`${environment.apiUrl}/api/blog/add_blog`,
                { blog: blog })
                .pipe(map(blog => {

                    return  blog;

                }));

    }

    delete_blog(blog:Blog) {
      return this.http.post<any>(`${environment.apiUrl}/api/blog/delete_blog`,
              { blog: blog })
              .pipe(map(blog => {

                  return  blog;

              }));

  }
  updated_blog(blog:Blog) {
    return this.http.post<any>(`${environment.apiUrl}/api/blog/updated_blog`,
            { blog: blog })
            .pipe(map(blog => {

                return  blog;

            }));

}

    add_comment(blog:Blog,detail_blog:Blog) {

        return this.http.post<any>(`${environment.apiUrl}/api/blog/add_comment`,
                {
                    blog: blog,
                    detail_blog:detail_blog
                })
                .pipe(map(blog => {

                    return  blog;

                }));

    }

    add_feelingBlog(feelingBlog:FeelingBlog,detail_blog:Blog) {

        return this.http.post<any>(`${environment.apiUrl}/api/blog/add_feelingBlog`,
                { feelingBlog: feelingBlog,
                  detail_blog:detail_blog })
                .pipe(map(feelingBlog => {

                    return  feelingBlog;

                }));

    }

    add_tagBlog(tagBlog:TagBlog) {

        return this.http.post<any>(`${environment.apiUrl}/api/blog/add_tagBlog`,
                { tagBlog: tagBlog })
                .pipe(map(tagBlog => {

                    return  tagBlog;

                }));
    }

    get_TagBlog_list() {

        return this.http.get(`${environment.apiUrl}/api/blog/tagBlog_list`)
            .pipe(map(tagBlog => {

                return tagBlog;

            }));;
    }

    deleted_tagBlog(tagBlog:TagBlog) {
      return this.http.post<any>(`${environment.apiUrl}/api/blog/deleted_tagBlog`,
              { tagBlog: tagBlog })
              .pipe(map(tagBlog => {

                  return  tagBlog;

              }));

  }

}
