import { Injectable, OnInit } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient, HttpRequest, HttpParams, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ElementFiles,Partner } from '@app/model';

@Injectable({ providedIn: 'root' })
export class PartnerService {

  constructor(private http: HttpClient) {

  }

  get_partner_list() {

    return this.http.get(`${environment.apiUrl}/api/partner/partner_list`)
      .pipe(map(Partner => {

        return Partner;

      }));;
  }

  save_partner(partner: Partner, image_partner: any) {

    const formData: FormData = new FormData();
    formData.append('file', image_partner);
    formData.append('partner', JSON.stringify(partner));
    formData.append('lang', localStorage.getItem('lang'));

    return this.http.post<any>(`${environment.apiUrl}/api/partner/save_partner`,
      formData)
      .pipe(map(course => {

        return course;

      }));
  }
  delete_partner(partner: Partner) {

    return this.http.post<any>(`${environment.apiUrl}/api/partner/delete_partner`,
      { partner: partner })
      .pipe(map(partner => {

        return partner;

      }));
  }

}
