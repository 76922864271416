import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Event } from '@app/model';

@Injectable({ providedIn: 'root' })
export class CalendarService {

  constructor(
    private http: HttpClient

  ) { }

  get_EventList() {

    return this.http.get(`${environment.apiUrl}/api/calendar/get_all_event`)
      .pipe(map(Event => {

        return Event;
      }));
  }
  add_event(add_event: Event) {

    return this.http.post<any>(`${environment.apiUrl}/api/calendar/add_event`,
      { event: add_event })
      .pipe(map(event => {

        return event;

      }));
  }

  update_event(event: Event) {

    return this.http.post<any>(`${environment.apiUrl}/api/calendar/update_event`,
      { event: event })
      .pipe(map(event => {

        return event;

      }));
  }

  delete_event(event: Event) {

    return this.http.post<any>(`${environment.apiUrl}/api/calendar/delete_event`,
      { event: event })
      .pipe(map(event => {

        return event;

      }));
  }

}
