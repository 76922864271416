import { Component, OnInit,ViewChild } from '@angular/core';


@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {

  constructor() {
   
   }

  ngOnInit(): void {
  }

}
