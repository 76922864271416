import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const TOKEN_KEY = 'accessToken';
const REFRESHTOKEN_KEY = 'refreshToken';
const USER_KEY = 'user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  private user = new BehaviorSubject<any>(JSON.parse(localStorage.getItem(USER_KEY)));
  constructor() { }

  signOut(): void {
    localStorage.clear();
  }

  public saveToken(token: string): void {

    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);

    const user = this.getUser();
    if (user) {
      this.saveUser({ ...user, accessToken: token });
    }
  }
  public deleteToken(): void {

    localStorage.removeItem(TOKEN_KEY);
  }

  public getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveRefreshToken(token: string): void {
    localStorage.removeItem(REFRESHTOKEN_KEY);
    localStorage.setItem(REFRESHTOKEN_KEY, token);
  }
  public deleteRefreshToken() {

    localStorage.removeItem(REFRESHTOKEN_KEY);
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem(REFRESHTOKEN_KEY);
  }

  public saveUser(user: any): void {

    this.saveUserBehavior(user)
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));

  }
  public deleteUser(): void {

    localStorage.removeItem(USER_KEY);
  }
  public getUser(): any {
    const user = localStorage.getItem(USER_KEY);

    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  public saveUserBehavior(user:any){

    this.user.next(user);
  }

  public getUserBehavior(): BehaviorSubject<any>{

    return this.user;
  }
}
