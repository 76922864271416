
import {Deserializable} from "./deserializable.model";
import {UserRole} from "./UserRole";
import {UserType} from "./UserType"
import {User} from "./User"
export class Permission implements Deserializable{

    permission_id:number;
    permission:string;
    action:string;
    app:string;
    is_active:boolean;
    userRole_list:UserRole[]=[];
    userType_list:UserType []=[];
    user_list:User [] =[];
    created_date:Date;
    created_by:string;
    updated_by:string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
