import { Component, OnInit, Input, Output, ChangeDetectorRef, AfterViewChecked, Inject, OnChanges, SimpleChanges, AfterViewInit, OnDestroy, EventEmitter, ViewEncapsulation, PLATFORM_ID, ViewChild, ElementRef } from '@angular/core';
import { environment } from '@environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ElementService, UtilityService, BlogService, CourseService } from '@app/service/';
import { ModalService } from '@app/helper/modal';
import { EditorService } from '@app/helper/editor/editor.service';
import { News, Blog, Course } from '@app/model';
import { first } from 'rxjs/operators';
import { NewsService, WindowRefService } from '@app/service/';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { TestBed } from '@angular/core/testing';
import { Container } from '@angular/compiler/src/i18n/i18n_ast';
import { threadId } from 'worker_threads';
import { style } from '@angular/animations';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AlertService } from '@app/helper/alert';

@Component({
  selector: 'editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class EditorComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {

  @Input() element_edited: any;
  @Input() type: string;
  @Input() feature_list: string[];

  @Output() close_modal = new EventEmitter();
  @Output() result_element = new EventEmitter<any>();
  api_url: string = environment.apiUrl

  @ViewChild("textEditor") textEditor: ElementRef;

  image: any;
  image_cropper_status: number = 1;
  image_crop_list: any[] = [];
  image_crop_index = 0;
  image_url: string;

  lang_selected: string = localStorage.getItem('lang');

  text: any;
  text_list: any[] = [];
  text_index = 0;
  text_native_element: any;

  video: any;
  file: any;
  selection: any;

  link_button_list: any[] = []
  selected_link_button_id: string = ''
  animation_effect: any

  newsList: News[] = [];
  storyList: News[] = [];
  blogList: Blog[] = [];
  courseList: Course[] = [];

  link_base: string = ''
  extra_base: string = ''

  range: any = new Range();
  startNodeRange: any = null;
  rootNode: any = null;
  textColorCode: string;
  imageCrop:any;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '500px',
    minHeight: '200px',
    placeholder: 'รายละเอียด',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'KANIT',
    sanitize: false,
    fonts: [
      { class: 'arial', name: 'ARIAL' },
      { class: 'times-new-roman', name: 'TIMES NEW ROMAN' },
      { class: 'calibri', name: 'CALIBRI' },
      { class: 'KANIT', name: 'KANIT' },
      { class: 'IANNNNNDOG-REQULAR', name: 'IANNNNNDOG REQULAR' },
      { class: 'THSARABUN', name: 'THSARABUN' },
      { class: 'LYFNARAK', name: 'LYFNARAK' },
      { class: 'SOVKLORNG', name: 'SOVKLORNG' },
      { class: 'MMNIRATPARIS', name: 'MMNIRATPARIS' },
      { class: 'SOVBAAN', name: 'SOVBAAN' },
      { class: 'TALAMDUAN', name: 'TALAMDUAN' },
      { class: 'TAMAGAZINE', name: 'TAMAGAZINE' },
      { class: 'SOV3DSLIM', name: 'SOV3DSLIM' },
      { class: 'FCOUTLINECOLOR', name: 'FCOUTLINECOLOR' },
      { class: 'SOVJDKRANOK', name: 'SOVJDKRANOK' },
      { class: 'NOODLEDEMO', name: 'NOODLEDEMO' },
      { class: 'SOVSAMCHUK', name: 'SOVSAMCHUK' },
      { class: 'WHALE', name: 'WHALE' },
      { class: 'AKKHARAKT', name: 'AKKHARAKT' },
      { class: 'SPLAISAN', name: 'SPLAISAN' },
      { class: 'SOVRATAPHAN', name: 'SOVRATAPHAN' },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],

  };

  effect = { hover: '', wmAnimate: '' }
  isOpen: boolean = true;


  constructor(private elementService: ElementService,
    private editorService: EditorService,
    private modalService: ModalService,
    private utilityService: UtilityService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService,
    private newsService: NewsService,
    private blogService: BlogService,
    private courseService: CourseService,
    private changeDetector: ChangeDetectorRef,
    private alertService: AlertService,
    private sanitizer:DomSanitizer) {
  }



  ngOnChanges(changes: SimpleChanges) {

    this.image_crop_list = []
    if (this.type == 'image') {

      this.image_url = this.api_url + '/media/' + this.element_edited.image.file;

      this.toDataUrl(this.image_url,
        ((myBase64) => {

          this.image_crop_list.push({ image: myBase64, is_current: true })
          this.image_crop_index = this.image_crop_list.length;
          this.imageCrop = this.image_url;
          this.image = this.sanitizer.bypassSecurityTrustResourceUrl(myBase64);
        }));

      if (!this.element_edited.image.styles || this.element_edited.image.styles == '') {

        this.element_edited.image.styles = { hover: '', wmAnimate: '', filter: '' }
      }

    }
    if (this.type == 'text') {

      this.createdPickupcolor();

      if (!this.element_edited.text.styles || this.element_edited.text.styles == '') {

        this.element_edited.text.styles = { hover: '', wmAnimate: '' }
      }
      if (!this.element_edited.text.link) {
        this.element_edited.text.link = ''
      }

    }
    if (this.type == 'video') {

      if (!this.element_edited.video.styles || this.element_edited.video.styles == '') {

        this.element_edited.video.styles = { hover: '', wmAnimate: '' }
      }
      if (!this.element_edited.video.link) {

        this.element_edited.video.link = ''

      }
    }
  }
  ngOnInit(): void {
  }

  async ngAfterViewInit() {

    this.get_newsList();
    this.createdPickupcolor();

  }


  ngAfterViewChecked() {

    this.isOpen = true;
    this.changeDetector.detectChanges();

  }



  add_hove_effect() {

    if (this.type == 'text') {
      var editor_element = this.textEditor.nativeElement.getElementsByTagName("angular-editor-toolbar")[0];

      if (editor_element) {

        var container = this.windowRefService.nativeWindow.document.createElement("div")
        container.innerHTML = '<div class="container"><div class="d-flex flex-row justify-content-center"></div></div>'

        var animation_effect = this.createAnimationEffect()
        var hover_effect = this.createHoverEffect()
        var link_element = this.createLinkElement()
        container.childNodes[0].childNodes[0].appendChild(animation_effect)
        container.childNodes[0].childNodes[0].appendChild(hover_effect)
        container.childNodes[0].childNodes[0].appendChild(link_element)
        editor_element.childNodes[0].appendChild(container);

      }
    }
  }

  createdPickupcolor() {

    let pickerColor = document.getElementById('backgroundColorPicker-');

    if (pickerColor) {

      var newPickupColor = this.windowRefService.nativeWindow.document.createElement("div");

      newPickupColor.classList.add('d-inline-flex');
      newPickupColor.classList.add('pickup-color');
      newPickupColor.setAttribute("ng-star-inserted", "")
      // pickupColor.style.backgroundColor = 'green'
      let pickupPanel = this.windowRefService.nativeWindow.document.getElementById('pickup-panel');
      pickupPanel.classList.remove('none-display');
      newPickupColor.innerHTML = pickupPanel.outerHTML;
      pickupPanel.classList.add('none-display');

      pickerColor.parentNode.insertBefore(newPickupColor, pickerColor);

      this.clickPickerColorListening(newPickupColor);
      pickerColor.remove();
    }

  }

  clickPickerColorListening(element) {

    element.addEventListener('click', (e) => {

      if (e.target.id) {


        if (e.target.id == 'custom-color') {

          e.target.addEventListener('change', (e) => {

            let colorCode = e.target.value;
            this.changeTextColor(colorCode);

          });

        } else {


          let colorCode = e.target.getAttribute('value');
          this.changeTextColor(colorCode);


        }
      }
    });
  }


  changeTextColor(colorCode) {

    if (colorCode) {

      if (this.textColorCode) {

        let eliminateElement = document.getElementById(this.textColorCode);

        if (eliminateElement) {

          eliminateElement.classList.remove('border-5');
        }
      }

      this.textColorCode = colorCode;
      let element = document.getElementById(this.textColorCode);

      if (element) {

        element.classList.add('border-5');
      }

      let referenceNode = document.getElementsByClassName('angular-editor-textarea')[0];
      this.rootNode = referenceNode;
      var selection = window.getSelection();
      var range = selection.getRangeAt(0);
      this.range = range;
      this.transformativeElement({ name: 'color', value: this.textColorCode }, null, 'down', false, referenceNode);

      let interHTML = document.getElementsByClassName('angular-editor-textarea')[0].innerHTML;
      if (this.lang_selected == 'TH') {

        this.element_edited.text.th_html = interHTML;

      } else {

        this.element_edited.text.en_html = interHTML;
      }

      selection.addRange(this.range);

    }
  }

  addElementToEditorBar(addElement) {

    var editor_element = this.textEditor.nativeElement.getElementsByTagName("angular-editor-toolbar")[0];
    editor_element.childNodes[0].appendChild(addElement);
  }

  createAnimationEffect() {
    var animation_effect = this.windowRefService.nativeWindow.document.createElement("div");
    animation_effect.classList.add('d-flex')
    animation_effect.classList.add('mx-1')
    animation_effect.setAttribute("ng-star-inserted", "")
    animation_effect.innerHTML =
      '<select class="form-select form-select-sm" name="effect-menu" id="effect-menu" >' +
      '<option value="">NONE</option>' +
      '<option value="fadeIn">FADE IN</option>' +
      '<option value="fadeInRight">FADE IN RIGHT</option>' +
      '<option value="fadeInLeft">FADE IN LEFT</option>' +
      '<option value="zoomIn">ZOOM IN</option>' +
      '</select>'

    for (var i, j = 0; i = animation_effect.getElementsByTagName("select")[0].options[j]; j++) {
      if (i.value == this.element_edited.text.styles.wmAnimate) {

        animation_effect.getElementsByTagName("select")[0].selectedIndex = j;
        break;
      }
    }
    animation_effect.addEventListener('change', (e) => {

      this.element_edited.text.styles.wmAnimate = e.target['value']

    });

    return animation_effect
  }



  createHoverEffect() {
    var animation_effect = this.windowRefService.nativeWindow.document.createElement("div");
    animation_effect.setAttribute("ng-star-inserted", "")
    animation_effect.classList.add('d-flex')
    animation_effect.classList.add('mx-1')
    animation_effect.innerHTML =
      '<select class="form-select form-select-sm" name="effect-menu" id="effect-menu" >' +
      '<option value="">NONE</option>' +
      '<option value="effect-underline">UNDERLINE</option>' +
      '<option value="effect-shine">SHINE</option>' +
      '<option value="effect-changeColor">CHANGE COLOR</option>' +
      '<option value="effect-changeBGColor">CHANGE BACKGROUND COLOR</option>' +
      '<option value="effect-hvr-pulse-shrink">PULSE SHRINK</option>' +
      '<option value="effect-hvr-curl-top-left">CURLS TOP LEFT</option>' +
      '</select>'

    for (var i, j = 0; i = animation_effect.getElementsByTagName("select")[0].options[j]; j++) {
      if (i.value == this.element_edited.text.styles.hover) {
        animation_effect.getElementsByTagName("select")[0].selectedIndex = j;
        break;
      }
    }
    animation_effect.addEventListener('change', (e) => {

      this.element_edited.text.styles.hover = e.target['value'];//your typescript function
    });

    return animation_effect
  }


  createLinkElement() {

    this.animation_effect = this.windowRefService.nativeWindow.document.createElement("div");
    this.animation_effect.setAttribute("ng-star-inserted", "")
    this.animation_effect.classList.add('d-flex')
    this.animation_effect.classList.add('mx-1')
    this.animation_effect.innerHTML =
      '<div class="dropdown">' +
      '<div class="button">' +
      '<i class="fas fa-link"></i>' +
      '</div>' +
      '<div class="dropdown-content">' +
      '<div class="container">' +
      '<div class="row">' +
      '</div>' +
      '</div>' +
      '<div class="d-flex">' +
      '</div>' +
      '</div>' +
      '</div>'
    var button_effect = this.animation_effect.childNodes[0].childNodes[1].childNodes[0].childNodes[0]

    this.link_button_list.push(this.create_button_link('baseURL', 'ลิงค์เว็บไซต์'))
    this.link_button_list.push(this.create_button_link('newsURL', 'ลิงค์ข่าว'))
    this.link_button_list.push(this.create_button_link('storyURL', 'ลิงค์เรื่องราว'))
    this.link_button_list.push(this.create_button_link('blogURL', 'ลิงค์กระทู้'))
    this.link_button_list.push(this.create_button_link('courseURL', 'ลิงค์หลักสูตร'))
    this.link_button_list.push(this.create_button_link('outsiteURL', 'ลิงค์สู่ภายนอก'))

    for (let i = 0; i < this.link_button_list.length; i++) {

      button_effect.appendChild(this.link_button_list[i])
    }

    var link_input_parent = this.animation_effect.childNodes[0].childNodes[1].childNodes[1]

    var link_input = this.windowRefService.nativeWindow.document.createElement("input");
    link_input.classList.add('form-control')
    link_input.setAttribute('type', 'text')


    link_input.value = this.element_edited.text



    link_input_parent.appendChild(link_input)

    let url = this.return_extra_url()[0]
    let value = this.return_extra_url()[1]
    this.default_button_link(url, value)


    link_input.addEventListener('input', (e) => {

      this.element_edited.text.link = e.target['value']

    });


    return this.animation_effect

  }

  default_button_link(url, value) {

    if (url == '') {

      this.link_button_click('baseURL', value)
    }
    else if (url == 'news-info') {

      this.link_button_click('newsURL', value)
    }
    else if (url == 'story-info') {

      this.link_button_click('storyURL', value)
    }
    else if (url == 'blog') {

      this.link_button_click('blogURL', value)
    }
    else if (url == 'blog') {

      this.link_button_click('blogURL', value)
    }
    else if (url == 'course') {

      this.link_button_click('courseURL', value)
    }
    else {

      this.link_button_click('outsiteURL', value)
    }

  }

  return_extra_url() {

    let isBaseUrl = this.element_edited.text.link.includes(window.location.origin)
    let url = ''
    let value = ''

    if (isBaseUrl) {

      let cutBaseURL = this.element_edited.text.link.replace(window.location.origin, "");
      if (cutBaseURL.split('/')[1] && cutBaseURL.split('/')[1] != '') {

        url = cutBaseURL.split('/')[1]
      }
      else {
        url = ''
      }
      if (cutBaseURL.split('/')[2] && cutBaseURL.split('/')[2] != '') {

        value = cutBaseURL.split('/')[2]
      }
    }
    else if (!isBaseUrl) {

      url = 'outsite'
    }

    return [url, value]

  }


  create_button_link(id, text) {

    var container = this.windowRefService.nativeWindow.document.createElement("div");
    container.classList.add('col-md-4')
    container.classList.add('text-center')
    container.classList.add('my-2')
    var button = this.windowRefService.nativeWindow.document.createElement("button");
    button.id = id
    button.classList.add('btn')
    button.classList.add('btn-light')
    button.innerText = text
    container.appendChild(button)

    container.addEventListener('click', (e) => {

      this.link_button_click(e.target['id'], '')
      // this.changeAnimationEffect(e.target['value']);//your typescript function
    });

    return container
  }
  link_button_click(id, value) {

    // this.animation_effect.childNodes[0].childNodes[1].childNodes[1] =
    for (let i = 0; i < this.link_button_list.length; i++) {

      if (this.link_button_list[i].childNodes[0].id == id) {

        this.selected_link_button_id = id
        this.select_link(id, value)
        this.link_button_list[i].childNodes[0].classList.remove(...this.link_button_list[i].childNodes[0].classList)
        this.link_button_list[i].childNodes[0].classList.add('btn')
        this.link_button_list[i].childNodes[0].classList.add('btn-primary')
      }
      else {

        this.link_button_list[i].childNodes[0].classList.remove(...this.link_button_list[i].childNodes[0].classList)
        this.link_button_list[i].childNodes[0].classList.add('btn')
        this.link_button_list[i].childNodes[0].classList.add('btn-light')
      }
    }

  }

  select_link(id, value) {

    var link_input_parent = this.animation_effect.childNodes[0].childNodes[1].childNodes[1]
    if (link_input_parent.childNodes[1]) {
      link_input_parent.childNodes[1].remove()
    }

    if (id == 'baseURL') {

      var input = this.animation_effect.childNodes[0].childNodes[1].childNodes[1].childNodes[0]
      input.value = window.location.origin
      this.element_edited.text.link = window.location.origin

    }
    else if (id == 'newsURL') {

      var input = this.animation_effect.childNodes[0].childNodes[1].childNodes[1].childNodes[0]
      input.value = window.location.origin + '/news-info/'
      this.element_edited.text.link = window.location.origin + '/news-info/'
      // this.extra_base = ''

      var select_container = this.windowRefService.nativeWindow.document.createElement("select");
      select_container.classList.add('form-select')
      select_container.classList.add('my-md-1')
      select_container.classList.add('mx-md-4')

      var option_container = this.windowRefService.nativeWindow.document.createElement("option");
      option_container.value = ''
      option_container.innerText = 'เลือกข่าว'

      select_container.appendChild(option_container)
      select_container.appendChild(option_container)

      for (let i = 0; i < this.newsList.length; i++) {

        var option_container = this.windowRefService.nativeWindow.document.createElement("option");
        option_container.value = this.newsList[i].news_id.toString()
        option_container.innerText = 'ID ' + this.newsList[i].news_id.toString()

        select_container.appendChild(option_container)

      }

      for (var i, j = 0; i = select_container.options[j]; j++) {
        if (i.value == value) {
          select_container.selectedIndex = j;
          break;
        }
      }

      select_container.addEventListener('change', (e) => {


        this.element_edited.text.link = this.element_edited.text.link + e.target['value']

      });

      link_input_parent.appendChild(select_container)

    }

    else if (id == 'storyURL') {

      var input = this.animation_effect.childNodes[0].childNodes[1].childNodes[1].childNodes[0]
      input.value = window.location.origin + '/story-info/'
      this.element_edited.text.link = window.location.origin + '/story-info/'

      var select_container = this.windowRefService.nativeWindow.document.createElement("select");
      select_container.classList.add('form-select')
      select_container.classList.add('my-md-1')
      select_container.classList.add('mx-md-4')

      var option_container = this.windowRefService.nativeWindow.document.createElement("option");
      option_container.value = ''
      option_container.innerText = 'เลือกเรื่องราว'

      select_container.appendChild(option_container)
      select_container.appendChild(option_container)

      for (let i = 0; i < this.storyList.length; i++) {

        var option_container = this.windowRefService.nativeWindow.document.createElement("option");
        option_container.value = this.storyList[i].news_id.toString()
        option_container.innerText = 'ID ' + this.storyList[i].news_id.toString()

        select_container.appendChild(option_container)
      }

      for (var i, j = 0; i = select_container.options[j]; j++) {
        if (i.value == value) {
          select_container.selectedIndex = j;
          break;
        }
      }

      select_container.addEventListener('change', (e) => {

        this.element_edited.text.link = this.element_edited.text.link + e.target['value']

      });

      link_input_parent.appendChild(select_container)

    }
    else if (id == 'blogURL') {

      var input = this.animation_effect.childNodes[0].childNodes[1].childNodes[1].childNodes[0]
      input.value = window.location.origin + '/blog/'
      this.element_edited.text.link = window.location.origin + '/blog/'

      var select_container = this.windowRefService.nativeWindow.document.createElement("select");
      select_container.classList.add('form-select')
      select_container.classList.add('my-md-1')
      select_container.classList.add('mx-md-4')

      var option_container = this.windowRefService.nativeWindow.document.createElement("option");
      option_container.value = ''
      option_container.innerText = 'เลือกกระทู้'

      select_container.appendChild(option_container)
      select_container.appendChild(option_container)

      for (let i = 0; i < this.blogList.length; i++) {

        var option_container = this.windowRefService.nativeWindow.document.createElement("option");
        option_container.value = this.blogList[i].blog_id.toString()
        option_container.innerText = 'ID ' + this.blogList[i].blog_id.toString()

        select_container.appendChild(option_container)
      }

      for (var i, j = 0; i = select_container.options[j]; j++) {
        if (i.value == value) {
          select_container.selectedIndex = j;
          break;
        }
      }

      select_container.addEventListener('change', (e) => {

        this.element_edited.text.link = this.element_edited.text.link + e.target['value']

      });

      link_input_parent.appendChild(select_container)

    }
    else if (id == 'courseURL') {

      var input = this.animation_effect.childNodes[0].childNodes[1].childNodes[1].childNodes[0]
      input.value = window.location.origin + '/course/'
      this.element_edited.text.link = window.location.origin + '/course/'

      var select_container = this.windowRefService.nativeWindow.document.createElement("select");
      select_container.classList.add('form-select')
      select_container.classList.add('my-md-1')
      select_container.classList.add('mx-md-4')

      var option_container = this.windowRefService.nativeWindow.document.createElement("option");
      option_container.value = ''
      option_container.innerText = 'เลือกหลักสูตร'

      select_container.appendChild(option_container)
      select_container.appendChild(option_container)

      for (let i = 0; i < this.courseList.length; i++) {

        var option_container = this.windowRefService.nativeWindow.document.createElement("option");
        option_container.value = this.courseList[i].course_no.toString()
        option_container.innerText = 'ID ' + this.courseList[i].course_no.toString()

        select_container.appendChild(option_container)
      }

      for (var i, j = 0; i = select_container.options[j]; j++) {
        if (i.value == value) {
          select_container.selectedIndex = j;
          break;
        }
      }

      select_container.addEventListener('change', (e) => {

        this.element_edited.text.link = this.element_edited.text.link + e.target['value']

      });

      link_input_parent.appendChild(select_container)

    }
    else if (id == 'outsiteURL') {

      var input = this.animation_effect.childNodes[0].childNodes[1].childNodes[1].childNodes[0]
      input.value = ''
      this.element_edited.text.link = ''

    }

  }

  get_newsList() {

    this.newsService.get_newsList().pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus == 'success') {

            var today = new Date();
            this.newsList = response.data_list.sort((a, b) => a.news_id - b.news_id);
            this.get_storyList()

          }
          else {

          }

        },
        error: error => {
        }
      });
  }

  get_storyList() {

    this.newsService.get_storyList().pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus == 'success') {

            var today = new Date();
            this.storyList = response.data_list.sort((a, b) => a.news_id - b.news_id);
            this.get_blogList()

          }
          else {

          }

        },
        error: error => {
        }
      });
  }

  get_blogList() {

    this.blogService.get_blogList().pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus == 'success') {

            this.blogList = response.data_list.sort((a, b) => a.blog_id - b.blog_id);
            this.getCourseList()
          }
          else {
            return []
          }

        },
        error: error => {
        }
      });
  }

  getCourseList() {
    this.courseService.get_CourseList()
      .pipe(first())
      .subscribe(
        response => {
          if (response['serviceStatus'] == 'success') {

            this.courseList = response['data_list'];
            this.add_hove_effect()


          }
          else {

          }
        });
  }




  click_menu(selectionObj) {
    if (selectionObj.selection == 'crop') {

      this.set_image_cropper_Status(selectionObj.value);

    }
    if (selectionObj.selection == 'undo' && this.type == 'image') {

      this.image_undo();
    }
    if (selectionObj.selection == 'redo' && this.type == 'image') {

      this.image_redo();
    }
    if (selectionObj.selection == 'wmAnimate' && this.type == 'image') {

      this.element_edited.image.styles = selectionObj.value;
    }
    if (selectionObj.selection == 'hover' && this.type == 'image') {

      this.element_edited.image.styles = selectionObj.value;
    }
    if (selectionObj.selection == 'link' && this.type == 'image') {

      this.element_edited.image.link = selectionObj.value;
    }
    if (selectionObj.selection == 'undo' && this.type == 'text') {

      this.text_undo();
    }
    if (selectionObj.selection == 'redo' && this.type == 'text') {

      this.text_redo();
    }
    if (selectionObj.selection == 'bold' && this.type == 'text') {

      this.change_selected_text(selectionObj);
    }
    if (selectionObj.selection == 'italic' && this.type == 'text') {

      this.change_selected_text(selectionObj);
    }
    if (selectionObj.selection == 'underline' && this.type == 'text') {

      this.change_selected_text(selectionObj);
    }
    if (selectionObj.selection == 'angsanews-font' && this.type == 'text') {

      this.change_selected_text(selectionObj);
    }
    if (selectionObj.selection == 'arial-font' && this.type == 'text') {

      this.change_selected_text(selectionObj);
    }
    if (selectionObj.selection == 'lang' && this.type == 'text') {

      if (selectionObj.value == 'th') {

        this.lang_selected = 'th'

      }
      if (selectionObj.value == 'en') {

        this.lang_selected = 'en'
      }

    }
  }

  change_selected_text(selection) {

    if (this.lang_selected == 'th') {

      this.element_edited.text.th_html = this.text_native_element.innerHTML;

    }
    if (this.lang_selected == 'en') {

      this.element_edited.text.en_html = this.text_native_element.innerHTML;
    }
    this.add_text_list(this.text_native_element)
  }

  set_selection(selection: any) {

    this.selection = selection;

  }

  result_image_upload(image_file) {

    this.elementService.uploadImage(this.dataURItoBlob(image_file), this.element_edited.image.quality).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus == 'success') {

            this.toDataUrl(this.api_url + "/" + response.data.image_name,
              ((myBase64) => {

                this.image_crop_list[this.image_crop_index - 1].is_current = false
                this.image_crop_list.push({ image: myBase64, is_current: true });
                this.image_crop_index = this.image_crop_list.length;
                this.imageCrop = this.api_url + "/" + response.data.image_name;
                this.image = this.sanitizer.bypassSecurityTrustResourceUrl(myBase64);
                this.image_cropper_status = 1;

              }));
          }

        }
      });
  }

  result_crop_image(image) {
    this.elementService.uploadResizeImage(this.element_edited.image.width, this.element_edited.image.height, this.dataURItoBlob(image)).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus == 'success') {

            var imageUrl = this.api_url + "/" + response.data.image_name
            this.toDataUrl(imageUrl,
              ((image) => {
                this.image_crop_list[this.image_crop_index - 1].is_current = false
                this.image_crop_list.push({ image: image, is_current: true });
                this.image_crop_index = this.image_crop_list.length;
                this.imageCrop = imageUrl;
                this.image = this.sanitizer.bypassSecurityTrustResourceUrl(image);
                this.image_cropper_status = 1;

              }));
          }
        },
        error: error => {
        }
      });


  }

  image_undo() {

    if (this.image_crop_list[this.image_crop_index - 1].is_current && this.image_crop_index > 1) {

      this.image_crop_list[this.image_crop_index - 1].is_current = false;
      this.image_crop_list[this.image_crop_index - 2].is_current = true;
      this.image = this.image_crop_list[this.image_crop_index - 2].image;
      this.image_crop_index = this.image_crop_index - 1;

    }
  }

  image_redo() {

    if (this.image_crop_list[this.image_crop_index - 1].is_current && this.image_crop_index < this.image_crop_list.length) {

      this.image_crop_list[this.image_crop_index - 1].is_current = false;
      this.image_crop_list[this.image_crop_index].is_current = true;
      this.image = this.image_crop_list[this.image_crop_index].image;
      this.image_crop_index = this.image_crop_index + 1;

    }

  }



  set_image_cropper_Status(image_cropper_Status) {

    this.image_cropper_status = image_cropper_Status
  }

  change_video_element(event) {

    if (event.action == 'upload') {

      this.video = event.value;
    }
    else if (event.action == 'link') {

      this.element_edited.link = event.value;
    }

  }
  change_file_element(event) {

    this.file = event.value;

  }

  async update_element_edited() {

    if (this.type == 'image') {

      if (typeof this.element_edited.image.styles != 'string') {

        this.element_edited.image.styles = JSON.stringify(this.element_edited.image.styles)
      }

      // this.image = await this.checkImage();
      this.elementService.edited_image(this.utilityService.dataURItoBlob(this.image.changingThisBreaksApplicationSecurity), this.element_edited).pipe(first())
        .subscribe({
          next: (response: any) => {

            if (response.serviceStatus == 'success') {

              this.alertService.success(response['massage'], { autoClose: true });
              this.modalService.close(this.element_edited.name);
              window.location.reload()
            } else {

              this.alertService.error(response['massage'], { autoClose: true });
            }
          },
          error: error => {
          }
        });

    }

    if (this.type == 'text') {

      this.element_edited.text.styles = JSON.stringify(this.element_edited.text.styles)
      this.elementService.edited_text(this.element_edited.text).pipe(first())
        .subscribe({
          next: (response: any) => {

            if (response.serviceStatus == 'success') {

              this.alertService.success(response['massage'], { autoClose: true });
              this.modalService.close(this.element_edited.name);
              window.location.reload()
            } else {

              this.alertService.error(response['massage'], { autoClose: true });
            }
          }
        });
    }
    if (this.type == 'video') {

      this.element_edited.video.styles = JSON.stringify(this.element_edited.video.styles);

      this.elementService.edited_video(this.element_edited.video, this.video).pipe(first())
        .subscribe({
          next: (response: any) => {

            if (response.serviceStatus == 'success') {

              this.alertService.success(response['massage'], { autoClose: true });
              this.modalService.close(this.element_edited.name);
              window.location.reload()
            } else {

              this.alertService.error(response['massage'], { autoClose: true });
            }
          }
        });
    }
    if (this.type == 'file') {
      console.log(this.element_edited.file)
      this.elementService.edited_file(this.element_edited.file, this.file).pipe(first())
        .subscribe({
          next: (response: any) => {
        
            if (response.serviceStatus == 'success') {

              this.alertService.success(response['massage'], { autoClose: true });
              this.modalService.close(this.element_edited.name);
              window.location.reload()
            } else {

              this.alertService.error(response['massage'], { autoClose: true });
            }
          }
        });
    }
  }

  // async checkImage(){

  //   let isURL = this.isValidURL(this.image);
  //   if(isURL){

  


  //   }else{

  //     this.image = this.image.changingThisBreaksApplicationSecurity;

  //     return this.image
  //   }



  // }

  async covertDataUrl(){
    var image = await this.toDataUrl(this.image,
      ((image) => {

        this.image = this.sanitizer.bypassSecurityTrustResourceUrl(image)['changingThisBreaksApplicationSecurity'];

        return this.image;
       
        
      }))

    return image
  }

  isValidURL(string) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(string);
  };
  text_changed(element) {

    // let text_element = new TextElement(element.nativeElement,this.element_edited.elementName);
    // this.text_native_element  = text_element.covertTextToElement();
    // if(this.lang_selected == 'th'){

    //     this.element_edited.text.th_html = this.text_native_element.innerHTML;

    // }
    // else{

    //     this.element_edited.text.en_html = this.text_native_element.innerHTML;
    // }
    // if(element.is_add){

    //     this.add_text_list(this.text_native_element)
    // }
  }
  add_text_list(text_element_native) {
    // this.text_list.filter(text => text.lang === this.lang_selected)
    this.set_all_false_current_text()
    this.text_list.push({ innerHTML: text_element_native.innerHTML, lang: this.lang_selected, is_current: true });
    // if(this.text_index > 0){

    //     this.text_list[this.text_index-1].is_current = false
    // }

    // this.text_index = this.text_list.length;
  }


  text_undo() {
    let lang_text_index = this.get_lang_text_undo()


    if (this.lang_selected == 'th' && lang_text_index >= 0) {

      this.set_all_false_current_text()
      let element_edited = this.element_edited;
      element_edited.text.th_html = this.text_list[lang_text_index].innerHTML;
      this.text_list[lang_text_index].is_current = true;
      this.element_edited = Object.create(element_edited);
    }
    if (this.lang_selected == 'en' && lang_text_index >= 0) {

      this.set_all_false_current_text()
      let element_edited = this.element_edited;
      element_edited.text.en_html = this.text_list[lang_text_index].innerHTML;
      this.text_list[lang_text_index].is_current = true;
      this.element_edited = Object.create(element_edited);

    }

  }

  text_redo() {

    let lang_text_index = this.get_lang_text_redo()

    if (this.lang_selected == 'th' && lang_text_index >= 0) {

      this.set_all_false_current_text()
      let element_edited = this.element_edited;
      element_edited.text.th_html = this.text_list[lang_text_index].innerHTML;
      this.text_list[lang_text_index].is_current = true;
      this.element_edited = Object.create(element_edited);
    }
    if (this.lang_selected == 'en' && lang_text_index >= 0) {

      this.set_all_false_current_text()
      let element_edited = this.element_edited;
      element_edited.text.en_html = this.text_list[lang_text_index].innerHTML;
      this.text_list[lang_text_index].is_current = true;
      this.element_edited = Object.create(element_edited);

    }

  }


  set_all_false_current_text() {

    this.text_list.map((text, i) => {

      if (this.lang_selected == text.lang) {

        this.text_list[i].is_current = false
      }
    })
  }
  get_lang_text_undo() {

    let lang_text_index = -1

    for (var i = 0; i < this.text_list.length; i++) {

      if (this.text_list[i].lang == this.lang_selected && this.text_list[i].is_current == false) {

        lang_text_index = i
      }
      if (this.text_list[i].lang == this.lang_selected && this.text_list[i].is_current == true) {

        if (lang_text_index == -1) {
          return i
        }
        return lang_text_index
      }
    }
    return -1
  }

  get_lang_text_redo() {

    let lang_text_index = -1

    for (var i = this.text_list.length - 1; i >= 0; i--) {

      if (this.text_list[i].lang == this.lang_selected && this.text_list[i].is_current == false) {

        lang_text_index = i
      }
      if (this.text_list[i].lang == this.lang_selected && this.text_list[i].is_current == true) {

        if (lang_text_index == -1) {
          return i
        }
        return lang_text_index
      }
    }
    return -1
  }

  close_mpdal() {

    this.modalService.close(this.element_edited.name);
    this.isOpen = false;
  }


  is_valid_link(link) {

    if (link) {

      if (this.match_Youtube_Url(link)) {

        return false;

      }
      else if (this.chech_video_url(link)) {

        return false;

      }
      else {

        return true;
      }

    } else {

      return true;
    }
  }

  private chech_video_url(url) {

    let is_video_url = false;
    var video = document.createElement("video");
    video.setAttribute("src", url);
    video.addEventListener("canplay", function () {

      is_video_url = true;
      return is_video_url;
    });
    video.addEventListener("error", function () {

      is_video_url = false;
      return is_video_url;
    });

    return is_video_url;
  }

  transformativeElement(stylesObj, classList, direction, isInterval, node) {

    let startRangeNode = this.range.startContainer;
    let endRangeNode = this.range.endContainer;
    let startOffset = this.range.startOffset;
    let endOffset = this.range.endOffset;

    if (node && !node.hasChildNodes()) {

      if (node.isSameNode(startRangeNode) && node.isSameNode(endRangeNode) && node.nodeType == 3) {

        let parentNode = node.parentNode;

        let returnNodeObj = this.transformativeOnlyNode(stylesObj, classList, startOffset, endOffset, node);

        this.insertNodeFromParent(returnNodeObj.startNode, node, parentNode);
        this.insertNodeFromParent(returnNodeObj.transformativeNode, node, parentNode);
        this.insertNodeFromParent(returnNodeObj.endNode, node, parentNode);

        node.remove();


        this.range.selectNodeContents(returnNodeObj.transformativeNode);

      } else if (node.isSameNode(startRangeNode)) {

        let parentNode = node.parentNode;

        let returnNodeObj = this.transformtiveStartNode(stylesObj, classList, startOffset, endOffset, node);
        this.insertNodeFromParent(returnNodeObj.startNode, node, parentNode);
        this.insertNodeFromParent(returnNodeObj.transformativeNode, node, parentNode);
        isInterval = true;
        this.startNodeRange = returnNodeObj.transformativeNode;
        this.recursive_transformative_Node(stylesObj, classList, direction, isInterval, node, true);

      } else if (node.isSameNode(endRangeNode) || (node.parentNode.isSameNode(endRangeNode) && node.nodeType == 3)) {

        let parentNode = node.parentNode;
        let returnNodeObj = this.transformtiveEndNode(stylesObj, classList, 0, endOffset, node);

        this.insertNodeFromParent(returnNodeObj.transformativeNode, node, parentNode);
        this.insertNodeFromParent(returnNodeObj.endNode, node, parentNode);

        isInterval = false;

        node.remove();
        // this.recursive_transformative_Node(stylesObj, classList, direction, isInterval, node, true);

        this.range.setStartBefore(this.startNodeRange);
        this.range.setEndBefore(returnNodeObj.transformativeNode);
      }
      else if (isInterval && node.nodeType == 3) {

        let parentNode = node.parentNode;

        let returnNodeObj = this.transformtiveIntervalNode(stylesObj, classList, startOffset, endOffset, node);
        this.insertNodeFromParent(returnNodeObj.transformativeNode, node, parentNode);

        this.recursive_transformative_Node(stylesObj, classList, direction, isInterval, node, true);
      }
      else {

        this.recursive_transformative_Node(stylesObj, classList, direction, isInterval, node, false);

      }
    } else {

      if (direction == 'down') {

        this.transformativeElement(stylesObj, classList, 'down', isInterval, node.childNodes[0]);

      } else {

        this.recursive_transformative_Node(stylesObj, classList, direction, isInterval, node, false);

      }
    }

  }

  transformativeOnlyNode(stylesObj, classList, startOffset, endOffset, node) {

    let textContent = node.textContent;

    let satrtText = textContent.substring(0, startOffset);
    let startNode = this.createdNode('FONT', satrtText, node.parentNode.classList, null, 1);

    let transformativeText = textContent.substring(startOffset, endOffset);
    let transformativeNode = this.createdNode('FONT', transformativeText, node.parentNode.classList, stylesObj, 1);

    let endText = textContent.substring(endOffset, textContent.length);
    let endNode = this.createdNode('FONT', endText, node.parentNode.classList, null, 1);

    return { 'startNode': startNode, 'transformativeNode': transformativeNode, 'endNode': endNode }
  }

  transformtiveStartNode(stylesObj, classList, startOffset, endOffset, node) {

    let textContent = node.textContent;

    let satrtText = textContent.substring(0, startOffset);
    let startNode = this.createdNode(node.tagName, satrtText, null, null, 1);

    let transformativeText = textContent.substring(startOffset, textContent.length);
    let transformativeNode = this.createdNode('FONT', transformativeText, null, stylesObj, 1);

    return { 'startNode': startNode, 'transformativeNode': transformativeNode }
  }

  transformtiveIntervalNode(stylesObj, classList, startOffset, endOffset, node) {

    let textContent = node.textContent;
    let transformativeNode = this.createdNode('FONT', textContent, null, stylesObj, 1);

    return { 'transformativeNode': transformativeNode }

  }

  transformtiveEndNode(stylesObj, classList, startOffset, endOffset, node) {

    let textContent = node.textContent;

    let transformativeText = textContent.substring(0, endOffset);
    let transformativeNode = this.createdNode('FONT', transformativeText, null, stylesObj, 1);

    let endText = textContent.substring(endOffset, textContent.length);
    let endNode = this.createdNode('FONT', endText, null, null, 1);

    return { 'transformativeNode': transformativeNode, 'endNode': endNode }
  }

  insertNodeFromParent(childNode, referenceNode, parentNode) {

    parentNode.insertBefore(childNode, referenceNode);
  }

  createdNode(tagName, textContend, classList, stylesObj, nodeType) {

    let node = null;

    if (nodeType == 3) {

      node = document.createTextNode(textContend);

      return node;
    }
    else if (nodeType == 1) {

      node = document.createElement(tagName);
      node.textContent = textContend;

      if (classList) {
        node.classList.add(...classList);
      }

      if (stylesObj) {

        node.style[stylesObj.name] = stylesObj.value;
      }

      return node;
    }

    return node;
  }

  selectedRangeElement(startNode, endNode, startOffset, endOffset) {

    const selection = window.getSelection();
    selection.removeAllRanges();

    if (startNode.isSameNode(endNode) && startOffset == endOffset) {

      startNode.innerHTML = "&#8203";
      this.range.selectNodeContents(startNode);
      selection.addRange(this.range);
      selection.collapseToEnd();
    } else {

      this.range.setStartBefore(startNode);
      this.range.setEndBefore(endNode);
      selection.addRange(this.range);

    }
  }

  recursive_transformative_Node(stylesObj, classList, direction, isInterval, node, isRemoved) {


    let nextSibling = node.nextSibling;
    let parentNode = node.parentNode;

    if (nextSibling && !node.isSameNode(this.rootNode)) {

      if (isRemoved) {

        node.remove();
      }

      return this.transformativeElement(stylesObj, classList, 'down', isInterval, nextSibling);
    }
    else if (parentNode && !node.isSameNode(this.rootNode)) {

      if (isRemoved) {

        node.remove();
      }

      return this.transformativeElement(stylesObj, classList, 'up', isInterval, parentNode);
    }

  }

  private match_Youtube_Url(url) {

    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      return url.match(p)[1];
    }
    return false;
  }

  private toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  private dataURItoBlob(dataURI) {

    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);

    for (var i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], { type: mimeString });
    return blob;
  }

  ngOnDestroy() {

    if (this.type == 'text') {
      let editorElement = this.textEditor.nativeElement.getElementsByTagName("angular-editor-toolbar")[0];

      if (editorElement) {

        editorElement.remove();

      }
    }
  }
}
