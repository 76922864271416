import { Injectable,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import {SystemInfo} from '@app/model';

@Injectable({ providedIn: 'root' })
export class SystemInfoService {
    headers:any
    constructor(
        private router: Router,
        private http: HttpClient
    ) {}

    get_systemInfo() {

        return this.http.get(`${environment.apiUrl}/api/system/get_systemInfo`
        )
        .pipe(map(systemInfo => {

            return  new SystemInfo().deserialize(systemInfo);

        }));;
    }

    edited_systemInfo(systemInfo:SystemInfo) {


        return this.http.post(`${environment.apiUrl}/api/system/edited_systemInfo`,   
        {systemInfo:systemInfo}
        )
        .pipe(map(systemInfo => {

            return  new SystemInfo().deserialize(systemInfo);

        }));;
    }
}
