import { Component, OnInit, ViewChild } from '@angular/core';
import { Assessments } from '@app/model';
import { first } from 'rxjs/operators';
import { AlertService } from '@app/helper/alert';
import { AssessmentService } from '@app/assessment/services/assessment.service';
import { environment } from '@environments/environment';
import { Router, Params, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { EditStateEnum } from '@app/assessment/question-form/model/enum/EditStateEnum';

@Component({
  selector: 'assessment-list',
  templateUrl: './assessment-list.component.html',
  styleUrls: ['./assessment-list.component.css']
})

export class AssessmentListComponent implements OnInit {

  assessment_list: Assessments[] = []
  apiUrl: string = environment.apiUrl;
  image: any;
  is_deleted: boolean = false;
  public EditStateEnum = EditStateEnum;
  public state = EditStateEnum.deleted;
  copyedAssessment = new Assessments()

  menuTopLeftPosition = { x: '0', y: '0' }

  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @ViewChild('deletedMenu', { read: MatMenuTrigger, static: false }) deletedMenuTrigger: MatMenuTrigger;

  // @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  // @ViewChild('copy', { read: MatMenuTrigger,static: true }) protected copyMenuTrigger: MatMenuTrigger;

  // menuTopLeftPosition1 = { x: '0', y: '0' }
  // @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  constructor(
    private assessmentService: AssessmentService,
    private alertService: AlertService,
    private router: Router
  ) {

  }

  ngOnInit(): void {

    this.get_assessment_list()
    this.toDataUrl(this.apiUrl + '/media/default_profile/no-image.png',
      ((myBase64) => {

        this.image = myBase64

      }));

  }

  reload() {

    this.get_assessment_list();
    this.is_deleted = false;
  }

  get_assessment_list() {

    this.assessmentService.get_assessments_list()
      .pipe(first())
      .subscribe(
        response => {
          if (response['serviceStatus'] == 'success') {

            this.assessment_list = response['data_list'];
          }
          else {

            console.log(response['massage'])
          }
        });

  }

  created_assessment() {

    let assessments = new Assessments()
    assessments.name = 'UNTITLED FORM ' + this.assessment_list.length
    assessments.topic = null;
    assessments.description = null;
    assessments.forms = null;
    assessments.permissions = null;

    this.assessmentService.created_assessment(assessments, this.dataURItoBlob(this.image)).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus == 'success') {

            this.alertService.success(response['massage'], { autoClose: true })
            this.get_assessment_list()

          }
          else {

            this.alertService.error(response['massage'], { autoClose: true })

          }

        },
        error: error => {
        }
      });
  }


  updated_assessments(assessments) {
    if (assessments) {

      this.assessmentService.updated_assessment(assessments).pipe(first())
        .subscribe({
          next: (response: any) => {

            if (response.serviceStatus == 'success') {

              // this.alertService.success(response['massage'], { autoClose: true })

            }
            else {

              this.alertService.error(response['massage'], { autoClose: true })

            }

          },
          error: error => {
          }
        });
    }
  }

  onRightClick(event: MouseEvent) {

    var target = event.target;

    if (target && target['id'] && target['id'] == "deleted") {
      event.preventDefault();
      this.menuTopLeftPosition.x = event.clientX + 'px';
      this.menuTopLeftPosition.y = event.clientY + 'px';
      this.state =  EditStateEnum.deleted;
      this.matMenuTrigger.openMenu()
    }
  }

  copyAssessment(event: MouseEvent, copyAssessment) {

    var target = event.target;
    if (target && target['id'] && target['id'] == "copy") {
      event.preventDefault();
      this.menuTopLeftPosition.x = event.clientX + 'px';
      this.menuTopLeftPosition.y = event.clientY + 'px';
      this.state =  EditStateEnum.edited;
      this.copyedAssessment = copyAssessment;
      this.matMenuTrigger.openMenu()
    }

  }

  open_deleted_assessment() {

    this.is_deleted = !this.is_deleted;
  }

  private dataURItoBlob(dataURI) {

    var byteString = atob(dataURI.split(',')[1]);
    // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);

    for (var i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], { type: 'image/png' });
    return blob;
  }

  deleted_assessment(assessment) {

    this.assessmentService.deleted_assessments(assessment).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus == 'success') {

            this.alertService.success(response['massage'], { autoClose: true })
            this.reload()

          }
          else {

            this.alertService.error(response['massage'], { autoClose: true })

          }

        },
        error: error => {
        }
      });
  }

  private toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  link_created_assessment(assessments_no) {

    const queryParams: any = {};
    queryParams.assessments_no = assessments_no;
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['/created-assessment'], navigationExtras).then(() => {

    });
  }

  copyed_assessment(){

    let assessments = new Assessments()
    assessments.name = this.copyedAssessment.name + "copy"
    assessments.topic = this.copyedAssessment.topic;
    assessments.description = this.copyedAssessment.description;
    assessments.forms = this.copyedAssessment.forms;
    assessments.permissions = null

    this.assessmentService.created_assessment(assessments, this.dataURItoBlob(this.image)).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus == 'success') {

            this.alertService.success(response['massage'], { autoClose: true })
            this.get_assessment_list()

          }
          else {

            this.alertService.error(response['massage'], { autoClose: true })

          }

        },
        error: error => {
        }
      });
    


  }





}
