import { Component, Input, Output, OnInit, HostListener, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { ShortTextForm } from '@app/assessment/question-form/model';
import { FormStateEnum } from '@app/assessment/question-form/model/enum/FormStateEnum';
import { AssessmentsAnswers } from '@app/model';
import { Validation } from '@app/assessment/question-form/model/Validation';
import { BaseFormComponent } from '@app/assessment/question-form/base-form/baseform.component';
import { selectLang } from '@environments/languageEnvironment';
import { Observable } from 'rxjs';

@Component({
  selector: 'shorttext-form',
  templateUrl: './shorttext-form.component.html',
  styleUrls: ['./shorttext-form.component.css']
})

export class ShortTextComponent extends BaseFormComponent implements OnInit {

  @Output() outAnswer: EventEmitter<{}> = new EventEmitter<{}>();

  baseForm: ShortTextForm;
  other_text: string = "";
  other_choice_index: number;
  is_other: boolean = false;
  answer: string = null;
  lang: string = localStorage.getItem('lang')
  selectLang = selectLang;

  ngOnInit() {
    /* extends method from BaseFormComponent */
    super.ngOnInit();
    this.answer_list.push(null);

  }
  changed_answer() {

    if (this.validation()) {
      
      this.answer_list[0] = this.answer;
      this.out_answer(false);

    }
    else {
      
      this.answer_list[0] = this.answer;
      this.out_answer(true);
    }

  }
  opened_validation() {

    if (this.state == FormStateEnum.validation) {

      this.state = FormStateEnum.detail;
    }
    else {

      this.state = FormStateEnum.validation;
    }
  }

  get_number(number) {

    if (isNaN(+number)) {

      return 0;
    }
    else {

      return Number(number)
    }

  }

  validation() {

    this.erorr_message = ''
    if (this.baseForm.is_required && !this.answer) {
      return false;
    }

    if (this.baseForm.validation) {

      if (this.baseForm.validation.type == 'number') {

        return this.validation_number()
      }
      else if (this.baseForm.validation.type == 'text') {

        return this.validation_text()
      }
      else if (this.baseForm.validation.type == 'lenght') {

        return this.validation_lenght()
      }
      else if (this.baseForm.validation.type == 'regular-expression') {

        return this.validation_expression()
      }
    }


    return true;

  }

  validation_number(): boolean {

    if (isNaN(+this.answer)) {

      this.erorr_message = selectLang('assessment', 'ASSESSMENT_NUMBERERROR', this.lang)
      // console.log('sdsdsd')
      return false

    }
    else if (this.baseForm.validation.operation == 'greater-than' && this.get_number(this.answer) > this.get_number(this.baseForm.validation.valueOne)) {

      this.erorr_message = this.baseForm.validation.error_massage;
      return false

    }
    else if (this.baseForm.validation.operation == 'greater-than-or-equal-to' && this.get_number(this.answer) >= this.get_number(this.baseForm.validation.valueOne)) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return false
    }
    else if (this.baseForm.validation.operation == 'less-than' && this.get_number(this.answer) < this.get_number(this.baseForm.validation.valueOne)) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return false
    }
    else if (this.baseForm.validation.operation == 'less-than-or-equal-to' && this.get_number(this.answer) <= this.get_number(this.baseForm.validation.valueOne)) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return false
    }
    else if (this.baseForm.validation.operation == 'equal-to' && this.get_number(this.answer) == this.get_number(this.baseForm.validation.valueOne)) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return false
    }
    else if (this.baseForm.validation.operation == 'not-equal-to' && this.get_number(this.answer) != this.get_number(this.baseForm.validation.valueOne)) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return false
    }
    else if (this.baseForm.validation.operation == 'between' && (this.get_number(this.answer) > this.get_number(this.baseForm.validation.valueOne) && this.get_number(this.answer) < this.get_number(this.baseForm.validation.valueTwo))) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return false
    }
    else if (this.baseForm.validation.operation == 'not-between' && !(this.get_number(this.answer) > this.get_number(this.baseForm.validation.valueOne) && this.get_number(this.answer) < this.get_number(this.baseForm.validation.valueTwo))) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return false
    }
    else if (this.baseForm.validation.operation == 'is-number' && isNaN(+this.answer)) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return false
    }
    return true;
  }

  validation_text() {

    if (this.baseForm.validation.operation == 'contains' && this.answer.includes(this.baseForm.validation.valueOne)) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return true
    }
    else if (this.baseForm.validation.operation == 'not-contain' && !(this.answer.includes(this.baseForm.validation.valueOne))) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return true
    }
    else if (this.baseForm.validation.operation == 'email' && !(this.isvalidateEmail(this.answer))) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return true
    }
    else if (this.baseForm.validation.operation == 'url' && !(this.isValidURL(this.answer))) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return true
    }
    return false;
  }

  validation_lenght() {

    if (this.baseForm.validation.operation == 'maximum-character-count' && this.answer.length > this.get_number(this.baseForm.validation.valueOne)) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return true
    }

    return false

  }
  validation_expression() {

    let regexpNumber = new RegExp(this.baseForm.validation.valueOne);
    if (this.baseForm.validation.operation == 'matches' && regexpNumber.test(this.answer)) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return true
    }
    else if (this.baseForm.validation.operation == 'not-match' && !regexpNumber.test(this.answer)) {

      this.erorr_message = this.baseForm.validation.error_massage;

      return true
    }

    return false

  }

  isvalidateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  isValidURL(url) {

    var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

}
