import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Assessments, AssessmentsAnswers } from '@app/model';

@Injectable({ providedIn: 'root' })
export class AssessmentService {
  headers: any
  constructor(
    private router: Router,
    private http: HttpClient
  ) { }
  created_assessment(assessment: Assessments, image: any) {

    const formData: FormData = new FormData();
    formData.append('file', image);
    formData.append('assessment', JSON.stringify(assessment));
    formData.append('lang', localStorage.getItem('lang'));
    return this.http.post(`${environment.apiUrl}/api/assessment/created_assessment`,
      formData
    )
      .pipe(map(assessment => {

        return new Assessments().deserialize(assessment);

      }));;
  }

  updated_assessment(assessments: Assessments) {
    return this.http.post<any>(`${environment.apiUrl}/api/assessment/update_assessments`,
      { assessments: assessments })
      .pipe(map(assessments => {

        return assessments;

      }));
  }

  get_assessments_list() {
    return this.http.get(`${environment.apiUrl}/api/assessment/get_assessments_list`)
      .pipe(map(Assessments => {

        return Assessments;

      }));;
  }

  get_assessments(assessment_no: string) {

    return this.http.post<any>(`${environment.apiUrl}/api/assessment/get_assessments`,
      { assessment_no: assessment_no })
      .pipe(map(assessments => {

        return assessments;

      }));
  }

  created_assessments_answers(assessmentsAnswers: AssessmentsAnswers) {

    return this.http.post<any>(`${environment.apiUrl}/api/assessment/created_assessments_answers`,
      { assessmentsAnswers: assessmentsAnswers })
      .pipe(map(assessments => {

        return assessments;


      }));
  }

  get_assessments_answers_list(assessments_no: string) {

    let params = new HttpParams();
    params = params.append('assessments_no', assessments_no);

    return this.http.get(`${environment.apiUrl}/api/assessment/get_assessments_answers_list`,
      { params: params }
    )
      .pipe(map(news => {

        return new Assessments().deserialize(news);

      }));;
  }

  deleted_assessments(assessmentsAnswers: AssessmentsAnswers) {

    return this.http.post<any>(`${environment.apiUrl}/api/assessment/deleted_assessments`,
      { assessmentsAnswers: assessmentsAnswers })
      .pipe(map(assessments => {

        return assessments;


      }));
  }
}
