import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, window } from 'rxjs/operators';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { TokenStorageService } from '@app/service';
import { AuthenticationService } from '@app/service';
import { User, LoginAccess, UserType } from '@app/model';
import { LoginStateEnum } from '@app/model/enum/LoginStateEnum';
import { AlertService } from '@app/helper/alert';
import { LoginService } from '@app/login/login.service';
import { environment } from '@environments/environment';
import { selectLang } from '@environments/languageEnvironment';
import { Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { NewsService, WindowRefService } from '@app/service';
import { saveAs } from 'file-saver';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  username: string = '';
  password: string = '';
  is_remember: boolean = false;
  returnUrl!: string;
  is_register: boolean = false;
  user: User = new User()
  userLogin: User = new User()
  imageUrl: any;
  app_name: string = environment.app_name;
  apiUrl: string = environment.apiUrl
  bsModalRef!: BsModalRef;
  image_cropper_status: number = 1
  socialUser!: SocialUser;
  lang: string;
  forget_email: string = ''
  userType_list: UserType[] = [];
  userList: User[] = [];
  selectLang = selectLang
  public LoginStateEnum = LoginStateEnum;
  public state = LoginStateEnum.login;
  changeOther: boolean = false;
  api_url: string = environment.apiUrl;
  @ViewChild('content') content!: ElementRef;

  @ViewChild('regiterForm') regiterForm!: ElementRef;
  @ViewChild('forgetForm') forgetForm!: ElementRef;



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    public socialAuthService: SocialAuthService,
    private loginService: LoginService,
    private bsModalService: BsModalService,
    private location: Location,
    // private ng2ImgMax: Ng2ImgMaxService,
    private tokenService: TokenStorageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.lang = localStorage.getItem('lang')
    }
  }

  ngOnInit() {

    this.get_userType_list();
    if (isPlatformBrowser(this.platformId)) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

      this.socialAuthService.authState.subscribe((socialUser) => {

        let user = this.userList.filter(user => user.user_key == socialUser.id)[0];
        if (user) {

          this.socialUser = socialUser;
          this.user = user;
          this.login_socialMedia(user).subscribe((data) => {

            this.alertService.success(data.massage,{autoClose:true});
            let beforeLoginPage = localStorage.getItem('beforePage');

            if (beforeLoginPage) {

              localStorage.setItem('beforePage', '/home');
              this.router.navigate([beforeLoginPage])
            }
            else {

              this.router.navigate(['/home'])
            }
          });

        } else {

          if (socialUser) {
            this.user.user_key = socialUser.id;
            this.user.email = socialUser.email;
            this.user.first_name = socialUser.firstName;
            this.user.last_name = socialUser.lastName;
            this.user.image_url = socialUser.photoUrl;
            this.user.provider = socialUser.provider;
            this.user.tel = null;
            this.user.name_title = null;
            this.user.id_citizen = null;

            this.login_socialMedia(this.user).subscribe((data) => {

              this.user = data.user;
              this.imageUrl = this.api_url + '/media/' + this.user.image_url;

              if (this.user.is_first) {

                this.state = this.LoginStateEnum.isFirst;
              }
            });
          }
        }
      });
    }
  }


  ngAfterViewInit() {

    if (isPlatformBrowser(this.platformId)) {
      this.get_user_list();
      this.loginService.add(this)
      this.loginService.open();
    }
  }

  login_socialMedia(user): Observable<any> {

    var subject = new Subject<any>();
    this.authenticationService.login_socialMedia(user, this.is_remember)
      .pipe(first())
      .subscribe(
        response => {

          if (response.serviceStatus == 'success') {
            this.userLogin = response.data.user;

            subject.next(response.data);

          } else {

            subject.next(null);
            this.alertService.error(response.massage,{autoClose:true});
          }

        });
    return subject.asObservable();
  }

  get_user_list() {

    this.authenticationService.user_list().pipe(first())
      .subscribe(
        response => {

          if (response.serviceStatus == 'success') {

            this.userList = response.data_list;

          } else {

            this.alertService.error(response.massage,{autoClose:true});
          }

        }
      );
  }

  get_userType_list() {

    this.authenticationService.userType_list()
      .pipe(first())
      .subscribe(
        response => {
          if (response.serviceStatus == 'success') {

            this.userType_list = response['data_list']

          }
          else {

            this.alertService.error(response.massage,{autoClose:true})
          }

        }
      );
  }

  changeUserType(userType: UserType) {

    this.user.user_type = userType;

  }

  openLoginModal() {

  }

  login() {

    if (isPlatformBrowser(this.platformId)) {
      this.authenticationService.login(this.username, this.password, this.is_remember)
        .pipe(first())
        .subscribe(
          response => {
            if (response.serviceStatus == 'success') {

              this.userLogin = response.data.user;

              this.state = this.LoginStateEnum.isFirst

              if (!response.data.user.is_first) {

                this.alertService.success(response.massage,{autoClose:true});
                let beforeLoginPage = localStorage.getItem('beforePage');

                if (beforeLoginPage) {

                  localStorage.setItem('beforePage', '/home');
                  this.router.navigate([beforeLoginPage])
                }
                else {

                  this.router.navigate(['/home'])
                }
                this.loginService.close()

              }
              else {

                this.toDataUrl(this.apiUrl + '/media/' + this.userLogin.image_url,
                  ((myBase64: any) => {

                    this.imageUrl = myBase64

                  }));


              }

            }
            else {
              this.alertService.error(response.massage,{autoClose:true})
            }
          });
    }
  }

  login_facebook() {

    if (isPlatformBrowser(this.platformId)) {
      this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID)
    }
  }

  login_google() {

    if (isPlatformBrowser(this.platformId)) {
      this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }
  }

  email_register() {

    if (isPlatformBrowser(this.platformId)) {
      this.user.user_key = this.user.email;
      this.user.image_url = null;
      this.user.provider = 'Email';

      this.authenticationService.email_register(this.user)
        .pipe(first())
        .subscribe(
          response => {
            if (response.serviceStatus == 'success') {

              this.state = this.LoginStateEnum.login
              this.alertService.success(response.massage,{autoClose:true})
              this.registerFormSubmit()
            }
            else {
              this.alertService.error(response.massage,{autoClose:true})
            }
          });
    }
  }

  registerFormSubmit() {

    if (isPlatformBrowser(this.platformId)) {
      const url = "https://docs.google.com/forms/d/e/1FAIpQLSfxYOX8DQrwBTQ6DrbHwcSUDb2syXLSdWJh303ZJzL5cP9ruw/formResponse";
      fetch(url, {
        method: "POST",
        body: new FormData(this.regiterForm.nativeElement),

      }).then(
        response => response.text() 
      ).then(
        html => console.log(html)
      );
    }

  }

  click_back() {
    this.location.back();
  }

  register_open() {

    this.is_register = !this.is_register
    this.user.email = ''
    this.user.password = ''
    this.user.comfirm_password = ''
    this.user.name_title = ''
    this.user.id_citizen = ''
    this.user.first_name = ''
    this.user.last_name = ''
    this.user.tel = ''
    this.user.role_id = 3

    this.state = this.LoginStateEnum.register

  }

  register_cancel() {

    this.state = this.LoginStateEnum.login

  }

  change_other(changeOther: any) {

    this.changeOther = changeOther

    if (this.changeOther) {

      this.user.name_title = ''

    }
  }

  changed_image_profile(event: any): void {
    if (isPlatformBrowser(this.platformId)) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.image_cropper_status = 2
          // this.ng2ImgMax.resizeImage(event.target.result, 400, 400).subscribe(
          //   result => {
          //     this.imageUrl = result;
          //   },
          //   error => {

          //     this.imageUrl = event.target.result;
          //   }
          // );

        }
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  async upload_image_profile() {

    if (isPlatformBrowser(this.platformId)) {
      this.userLogin.is_first = false;
      var res = await fetch(this.imageUrl);
      var imageBlob = await res.blob();
      this.authenticationService.edited_user(this.userLogin, imageBlob)
        .pipe(first())
        .subscribe(
          response => {
            if (response.serviceStatus == 'success') {
              this.imageUrl = null;
              this.user = response.data;
              this.loginService.close()
              let loginAceess = this.authenticationService.currentUserValue
              loginAceess['user'] = response.data;
              this.authenticationService.currentUserSubject.next(loginAceess)
              this.router.navigate([this.returnUrl])
              this.alertService.success(response.massage,{autoClose:true})
            }
            else {
              this.alertService.error(response.massage,{autoClose:true})
            }
          });
    }

  }

  async ededited_user() {

    this.user.is_first = false;
    let imageBlob = null
    if (this.imageUrl) {

      var res = await fetch(this.imageUrl);
      imageBlob = await res.blob();
    }

    this.authenticationService.edited_user(this.user, imageBlob)
      .pipe(first())
      .subscribe(
        response => {
          if (response.serviceStatus == 'success') {
            this.imageUrl = null;
            this.user = response.data;
            this.tokenService.saveUser(this.user);
            this.loginService.close();
            // this.tokenService.saveUserBehavior(this.user);
            // let loginAceess = this.authenticationService.currentUserValue;
            // loginAceess['user'] = response.data;
            // this.authenticationService.currentUserSubject.next(loginAceess);
            this.router.navigate([this.returnUrl])
            this.alertService.success(response.massage,{autoClose:true})
          }
          else {
            this.alertService.error(response.massage,{autoClose:true})
          }
        });
  }

  skip_upload_image() {

    if (isPlatformBrowser(this.platformId)) {

      this.upload_image_profile()

    }
  }

  set_image_cropper_status_cropper(image_result: any) {

    this.imageUrl = image_result;
    this.image_cropper_status = 1;

  }

  open_forger_password() {

    this.forget_email = ''
    this.state = this.LoginStateEnum.forget
  }

  forget_password() {
    if (isPlatformBrowser(this.platformId)) {
      this.authenticationService.forget_password(this.forget_email)
        .pipe(first())
        .subscribe(
          response => {
            if (response.serviceStatus == 'success') {

              this.state = this.LoginStateEnum.login
              this.alertService.success(response.massage,{autoClose:true})
            }
            else {
              this.alertService.error(response.massage,{autoClose:true})
            }
          });
    }
  }

  cancel_forgetPassword() {

    this.state = this.LoginStateEnum.login
  }



  private dataURItoBlob(dataURI: any) {

    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);

    for (var i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], { type: mimeString });
    return blob;
  }

  private toDataUrl(url: any, callback: any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

}
