import { Component, OnInit, ViewChild, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { ModalService } from '../helper/modal';
import { ElementService } from '../service/';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { WindowRefService } from '@app/service';


@Component({
  selector: 'app-massive-director',
  templateUrl: './massive-director.component.html',
  styleUrls: ['./massive-director.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MassiveDirectorComponent implements OnInit {

  menuTopLeftPosition = { x: '0', y: '0' }
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  app_name = environment.app_name
  apiUrl: string = environment.apiUrl

  innerHTMLEdited: any = { elementName: '', innerHTML: '' };
  headerEdited: string = '';
  typeEdited: string = '';

  element_text_director_1: any = null;
  element_text_director_2: any = null;

  constructor(
    private modalService: ModalService,
    private elementService: ElementService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService) { }

  ngOnInit(): void {

    this.reload()
  }

  downloadPDF() {

    window.open("https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf");
  }

  reload() {
    // this.getElementText('text-director-1').subscribe((data)=>this.element_text_director_1 = data )
    // this.getElementText('text-director-2').subscribe((data)=>this.element_text_director_2 = data )

  }

  //   getElementText(element_name): Observable<string> {

  //     var subject = new Subject<string>();
  //     this.elementService.get_elementText(element_name).pipe(first())
  //     .subscribe({
  //         next: (response:any) => {

  //           if (response.serviceStatus = 'success')
  //           {
  //             subject.next(response.data);
  //           }
  //           else
  //           {
  //             subject.next(null);
  //           }

  //         },
  //         error: error => {
  //         }
  //     });

  //     return subject.asObservable();
  //   }

  // onRightClick(event: MouseEvent,elementName,innerHTML,typeEdited) {

  //     event.preventDefault();
  //     this.menuTopLeftPosition.x = event.clientX + 'px';
  //     this.menuTopLeftPosition.y = event.clientY + 'px';
  //     if(typeEdited=='image'){

  //         this.innerHTMLEdited = {elementName:elementName,innerHTML:this.apiUrl+"/media/"+ innerHTML};
  //     }

  //     if(typeEdited=='text'){

  //         this.innerHTMLEdited = {elementName:elementName,innerHTML:innerHTML};
  //     }
  //     this.typeEdited = typeEdited;
  //     this.headerEdited = elementName;
  //     this.matMenuTrigger.openMenu()

  // }

  // openModal() {

  //     this.modalService.open('massive-director-modal');
  // }   

}


