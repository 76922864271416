import { Component, OnInit, ViewChild, ViewEncapsulation, PLATFORM_ID, Inject } from '@angular/core';
import { ModalService, } from '@app/helper/modal';
import { ElementService, WindowRefService } from '@app/service/';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { ElementImage, ElementText, News } from '@app/model';
import { NewsService } from '@app/service/';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';


@Component({
    selector: 'app-news-info',
    templateUrl: './news-info.component.html',
    styleUrls: ['./news-info.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NewsInfoComponent implements OnInit {


    newId: string;
    news: News = new News()
    newsList: News[];

    constructor(private activatedRoute: ActivatedRoute,
        private newsService: NewsService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private windowRefService: WindowRefService) {

        this.newId = this.activatedRoute.snapshot.paramMap.get("newsId")
        // this.reload()
    }

    ngOnInit(): void {

        this.reload()

    }

    reload() {

        this.get_news()
        this.get_newsList()

    }

    get_news() {

        if (isPlatformBrowser(this.platformId)) {
            this.newsService.get_news(this.newId).pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            this.news = response.data;
                            this.edited_news()

                        }
                        else {

                        }

                    },
                    error: error => {
                    }
                });
        }
    }

    get_newsList() {

        if (isPlatformBrowser(this.platformId)) {
            this.newsService.get_newsList().pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            var today = new Date(new Date().toISOString());
                            var data_list = response.data_list.sort((a, b) => b.views - a.views);

                            if (data_list.length >= 5) {
                                this.newsList = data_list.slice(0, 5)
                            }
                            else {

                                this.newsList = data_list
                            }

                        }
                        else {

                        }

                    },
                    error: error => {
                    }
                });
        }
    }

    edited_news() {

        if (isPlatformBrowser(this.platformId)) {
            this.newsService.edited_news(this.news).pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            // this.reload()

                        }
                        else {

                        }

                    },
                    error: error => {
                    }
                });
        }
    }

    covertHTMLToText(topic) {

        if (isPlatformBrowser(this.platformId)) {
            var doc = new DOMParser().parseFromString(topic, "text/html");

            if (doc.childNodes && doc.childNodes.length) {

                return doc.childNodes[0].textContent

            }
        }
    }

    linkNewsDetail(link) {

        if (isPlatformBrowser(this.platformId)) {
            window.location.href = link;
        }
    }

}