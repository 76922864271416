
import {Deserializable} from "./deserializable.model";
import { User } from "./User";

export class ElementFiles implements Deserializable{

    file_no:string;
    part:string;
    extension:string;
    is_actived:boolean;
    file:any;
    created_user:User;
    created_date:Date;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
