import { Component, OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { ModalService } from '../helper/modal';
import { ElementService } from '../service/';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {

    menuTopLeftPosition =  {x: '0', y: '0'}
    @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

    app_name = environment.app_name
    apiUrl:string =environment.apiUrl

    innerHTMLEdited : any = {elementName:'',innerHTML:''};
    headerEdited : string = '';
    typeEdited : string = '';

    element_text_contact_1 : any = null;
    element_text_contact_2: any =null;

    constructor(private modalService: ModalService,private elementService:ElementService) { }

    ngOnInit(): void {

        this.reload()  
      }
    
      reload()
      {
        // this.getElementText('text_contact_1').subscribe((data)=>this.element_text_contact_1 = data )
        // this.getElementText('text_contact_2').subscribe((data)=>this.element_text_contact_2 = data )
    
     

      }
    
      getElementText(element_name): Observable<string> {
    
        var subject = new Subject<string>();
        this.elementService.get_elementText(element_name).pipe(first())
        .subscribe({
            next: (response:any) => {
              
              if (response.serviceStatus = 'success')
              {
                subject.next(response.data);
              }
              else
              {
                subject.next(null);
              }
              
            },
            error: error => {
            }
        });
    
        return subject.asObservable();
      }
    
    onRightClick(event: MouseEvent,elementName,innerHTML,typeEdited) {
    
        event.preventDefault();
        this.menuTopLeftPosition.x = event.clientX + 'px';
        this.menuTopLeftPosition.y = event.clientY + 'px';
        if(typeEdited=='image'){
    
            this.innerHTMLEdited = {elementName:elementName,innerHTML:this.apiUrl+"/media/"+ innerHTML};
        }
    
        if(typeEdited=='text'){

            this.innerHTMLEdited = {elementName:elementName,innerHTML:innerHTML};
        }
        this.typeEdited = typeEdited;
        this.headerEdited = elementName;
        this.matMenuTrigger.openMenu()
        
    }
        
    openModal() {
    
        this.modalService.open('modal');
    }    

}
