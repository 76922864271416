import { Injectable, OnInit } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient, HttpRequest, HttpParams, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Donation } from '@app/model';

@Injectable({ providedIn: 'root' })
export class DonationService {

    constructor(private http: HttpClient) {
    }

    save_donation(donation: Donation) {

        return this.http.post<any>(`${environment.apiUrl}/api/donation/add_donation`,
            { donation: donation })
            .pipe(map(donation => {

                return donation;

            }));
    }
    donation_payment_charges(amount: Number, currency: string, source: string) {

        return this.http.post<any>(`${environment.apiUrl}/api/donation/donation_payment_charges`,
            { amount: amount, currency: currency, source: source })
            .pipe(map(donation => {

                return donation;

            }));
    }

    update_source_sheet_donation(donation: Donation) {

        return this.http.post<any>(`${environment.apiUrl}/api/donation/update_source_sheet_donation`,
            { donation: donation })
            .pipe(map(donation => {

                return donation;

            }));
    }

    payment(amount: number, currency: string, source: string, key: string) {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Authorization': 'Basic ' + btoa(key)
            })
        };
        return this.http.post<any>('https://api.omise.co/charges',
            { amount: amount, currency: currency, source: source }, httpOptions)
            .pipe(map(donation => {

                return donation;

            }));


    }


}