import { Component, OnInit , ViewChild , Input ,ViewEncapsulation } from '@angular/core';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
    selector: 'context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.css'],
    encapsulation: ViewEncapsulation.None
  })
  export class ContextMenuComponent implements OnInit {

    @Input() dimensionX: Number;
    @Input() dimensionY: Number;

    constructor() { }

    ngOnInit(): void {

    }

}
