
import {Deserializable} from "./deserializable.model";

export class ElementImage {

    item_name:string;
    part:string;
    link:string;
    styles:string;
    file:File;
    updated_by:string;
    updated_date:Date;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
