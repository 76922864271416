import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, OnChanges, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';
import { Course, Lesson, LessonDetail, User, ElementFiles, ElementImage, ElementText } from '@app/model';
import { CourseService, WindowRefService } from '../service/';
import { AuthenticationService } from '@app/service';
import { AlertService } from '../helper/alert';
import { ModalService } from '../helper/modal';
import { CourseStateEnum } from '@app/model/enum/CourseStateEnum';
import { ImageUploadEnum } from '@app/model/enum/ImageUpload';
import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';;
import { first } from 'rxjs/operators';
import { isThisQuarter } from 'date-fns';
import { FeelingBlog } from '@app/model/FeelingBlog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Router, Params, ActivatedRoute, NavigationExtras } from '@angular/router';
import { HostListener } from '@angular/core';
// import { Ng2ImgMaxService } from 'ng2-img-max';
import { CropperComponent, ImageCropperResult } from 'angular-cropperjs'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ContextMenuModel } from '@app/helper/context-menu/Interfaces/context-menu-model';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatMenu } from '@angular/material/menu';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TokenStorageService } from '@app/service';
import { selectLang } from '@environments/languageEnvironment';

@Component({
  selector: 'course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CourseComponent implements OnInit {

  courseList: Course[] = [];
  course: Course;
  addCourse: Course;
  addInstructorList: User[];
  userList: User[]
  instructor_list: User[] = []
  user: User = this.tokenService.getUser()

  image_cover: any;
  is_image_cover_cropper = false;
  is_delete_course = false;

  public CourseStateEnum = CourseStateEnum;
  public state = CourseStateEnum.list;

  apiUrl: string = environment.apiUrl;

  configCover = {
    aspectRatio: 1 / 1,
    dragMode: 'move',
    background: true,
    movable: true,
    rotatable: true,
    scalable: false,
    zoomable: true,
    viewMode: 1,
    checkImageOrigin: true,
    checkCrossOrigin: true
  };
  config = {
    aspectRatio: 20 / 5,
    dragMode: 'move',
    background: true,
    movable: true,
    rotatable: true,
    scalable: false,
    zoomable: true,
    viewMode: 1,
    checkImageOrigin: true,
    checkCrossOrigin: true
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'รายละเอียด',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'KANIT',
    sanitize: false,
    toolbarHiddenButtons: [
      ['bold']
    ],
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'KANIT', name: 'KANIT' }
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };


  resultImage: any;
  resultResult: any;

  isDisplayContextMenu: boolean;
  rightClickMenuItems: Array<ContextMenuModel> = [];
  rightClickMenuPositionX: number;
  rightClickMenuPositionY: number;

  menuTopLeftPosition = { x: '0', y: '0' }
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  editedName: string;
  placeholderNmae: string;

  @ViewChild('imageCoverCropper') public imageCoverCropper: CropperComponent;
  @ViewChild('imageCropper') public imageCropper: CropperComponent;
  public ImageUploadEnum = ImageUploadEnum;
  public imageCoverState = ImageUploadEnum.view;
  public imageState = ImageUploadEnum.view;
  imageResult: any;
  imageCover: any;
  image: any;
  selectLang = selectLang;
  lang: string;

  constructor(private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private courseService: CourseService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    // private ng2ImgMax: Ng2ImgMaxService,
    private sanitizer: DomSanitizer,
    private tokenService: TokenStorageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService) {

    this.lang = localStorage.getItem('lang');
  }

  ngOnInit(): void {

    this.reload()
  }

  reload() {

    if (isPlatformBrowser(this.platformId)) {
      this.editedName = '';
      this.placeholderNmae = '';
      this.addCourse = new Course();
      this.addCourse.course_no = ''
      this.addCourse.cover_image = new ElementImage()
      this.addCourse.topic = new ElementText()
      this.addCourse.topic.th_html = ''
      this.addCourse.detail = new ElementText()
      this.addCourse.detail.th_html = ''
      this.addCourse.about = new ElementText()
      this.addCourse.about.th_html = ''
      this.addCourse.objective = new ElementText()
      this.addCourse.objective.th_html = ''
      this.addCourse.criterion = new ElementText()
      this.addCourse.criterion.th_html = ''
      this.addCourse.url = '';
      this.addCourse.instructor_list = [];
      this.addCourse.student_list = [];
      this.addCourse.lesson_list = [];
      this.addInstructorList = []
      this.imageCover = this.apiUrl + '/media/default_profile/no-image.png';
      this.image = this.apiUrl + '/media/default_profile/no-image.png';
      this.state = CourseStateEnum.list;
      this.is_delete_course = false;
      this.courses_list()
      this.user_list()
    }
  }

  courses_list() {

    if (isPlatformBrowser(this.platformId)) {
      this.route.queryParams.subscribe(params => {
        this.courseService.get_CourseList()
          .pipe(first())
          .subscribe(
            response => {
              if (response['serviceStatus'] == 'success') {

                this.courseList = response['data_list'];

                var courseNo = params['course_no']

                if (courseNo) {

                  this.course = this.courseList.filter(c => c.course_no == courseNo)[0]
                  this.state = CourseStateEnum.detail
                }
              }
              else {
                this.alertService.error(response['massage'], { autoClose: true })
              }
            });
      });
    }
  }

  user_list() {

    if (isPlatformBrowser(this.platformId)) {
      this.authenticationService.user_list()
        .pipe(first())
        .subscribe(
          response => {
            if (response.serviceStatus == 'success') {

              this.userList = response.data_list
            }
            else {
              this.alertService.error(response.massage, { autoClose: true })
            }
          });
    }
  }

  selected_course(course: Course) {

    if (isPlatformBrowser(this.platformId)) {
      const queryParams: any = {};
      queryParams.course_no = course.course_no;
      const navigationExtras: NavigationExtras = {
        queryParams
      };
      this.router.navigate(['/course'], navigationExtras).then(() => {

      });
    }

  }

  onRightClick(event: MouseEvent) {

    if (this.user && this.user.user_role.role_name == ('admin' || 'webmaster')) {

      if (isPlatformBrowser(this.platformId)) {
        var target = event.target
        if (target && target['classList']) {

          let isCanRightClick = (target['id'] == 'course')
          if (isCanRightClick) {

            event.preventDefault();
            this.menuTopLeftPosition.x = event.clientX + 'px';
            this.menuTopLeftPosition.y = event.clientY + 'px';
            this.state = CourseStateEnum.add;
            this.matMenuTrigger.openMenu()
          }
        }
      }
    }
  }

  changedImageCover(event: any): void {

    if (isPlatformBrowser(this.platformId)) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: any) => {

          this.imageCover = event.target.result;
          this.imageCoverState = ImageUploadEnum.Cropper;;

        }
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  changedImage(event: any): void {

    if (isPlatformBrowser(this.platformId)) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: any) => {

          this.image = event.target.result;
          this.imageState = ImageUploadEnum.Cropper;;

        }
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  setImageCoverCropperStatusCropper(image_result) {

    if (isPlatformBrowser(this.platformId)) {
      this.imageCover = this.imageCoverCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.imageCoverState = ImageUploadEnum.view;
    }

  }

  setImageCropperStatusCropper(image_result) {

    if (isPlatformBrowser(this.platformId)) {
      this.image = this.imageCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.imageState = ImageUploadEnum.view;
    }

  }

  checkstatus(event: any) {

    if (isPlatformBrowser(this.platformId)) {
      if (event.blob === undefined) {
        return;
      }

      let urlCreator = window.URL;
      this.imageResult = this.sanitizer.bypassSecurityTrustUrl(
        urlCreator.createObjectURL(new Blob(event.blob)));
    }
  }

  add_course() {


    if (isPlatformBrowser(this.platformId)) {
      let user = new User()
      this.addInstructorList.push(user)

      this.image_cover = this.apiUrl + '/media/default_profile/no-image.png';

      this.state = CourseStateEnum.add
    }
  }

  changed_image_cover(event: any): void {

    if (isPlatformBrowser(this.platformId)) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: any) => {

          this.is_image_cover_cropper = true;
          this.image_cover = event.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }



  CropCoverImage() {

    if (isPlatformBrowser(this.platformId)) {
      if (this.imageCoverCropper) {
        this.imageCoverCropper.exportCanvas();
      }
    }
  }

  CropImage() {

    if (isPlatformBrowser(this.platformId)) {
      if (this.imageCropper) {
        this.imageCropper.exportCanvas();
      }
    }
  }

  openEditedTopic(event: MouseEvent, name, placeholderNmae) {

    if (isPlatformBrowser(this.platformId)) {
      var target = event.target
      if (target && target['classList']) {

        let isCanRightClick = (target['id'] == 'course')
        if (isCanRightClick) {

          event.preventDefault();
          this.menuTopLeftPosition.x = event.clientX + 'px';
          this.menuTopLeftPosition.y = event.clientY + 'px';
          this.editedName = name;
          this.placeholderNmae = placeholderNmae;
          this.matMenuTrigger.openMenu()
        }
      }
    }
  }

  add_instructor() {

    if (isPlatformBrowser(this.platformId)) {
      let user = new User()
      this.instructor_list.push(user)
    }
  }

  change_instructor($event: any, index) {

    if (isPlatformBrowser(this.platformId)) {
      let user = this.userList.filter(u => u.user_key == $event.target.value)[0]
      this.instructor_list[index] = user
    }
  }

  openEditedModal() {

    if (isPlatformBrowser(this.platformId)) {

      this.modalService.open('add-course')
    }

  }

  closeEditedModal() {

    if (isPlatformBrowser(this.platformId)) {

      this.reload()
      this.modalService.close('add-course')
    }
  }

  save_course() {

    if (isPlatformBrowser(this.platformId)) {
      this.addCourse.instructor_list = this.instructor_list

      let imageCover = this.checkImage(this.imageCover);
      let image = this.checkImage(this.image);

      this.courseService.save_Course(this.addCourse, imageCover, image)
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.modalService.close('add-course');
              this.alertService.success(response['massage'], { autoClose: true })
              this.reload()
            }
            else {
              this.alertService.error(response['massage'], { autoClose: true })
            }
          });
    }
  }

  checkImage(image) {

    if (this.isBase64(image)) {

      return this.dataURItoBlob(image);
    }
    else {

      return null;
    }

  }

  private isBase64(str) {

    return str.startsWith("data:");
  }

  delete_course(course) {

    if (isPlatformBrowser(this.platformId)) {

      this.courseService.delete_course(course)
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.reload()

              this.alertService.success(response['massage'], { autoClose: true })
            }
            else {
              this.alertService.error(response['massage'], { autoClose: true })
            }
          });
    }

  }

  openDeleteCourse() {

    this.is_delete_course = true;

  }

  cancelEditedModal() {
    this.is_delete_course = false;
  }

  private dataURItoBlob(dataURI) {

    if (isPlatformBrowser(this.platformId)) {
      var byteString = atob(dataURI.split(',')[1]);
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var _ia = new Uint8Array(arrayBuffer);

      for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
      }

      var dataView = new DataView(arrayBuffer);
      var blob = new Blob([dataView], { type: mimeString });
      return blob;
    }
  }

  private toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {

    if (isPlatformBrowser(this.platformId)) {
      window.location.reload()
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === "Enter") {

      if (this.imageCoverState == ImageUploadEnum.Cropper) {

        this.CropCoverImage();
      }
      if (this.imageState == ImageUploadEnum.Cropper) {

        this.CropImage();
      }
    }
  }


}
