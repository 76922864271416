
import {Deserializable} from "./deserializable.model";

export class ElementText implements Deserializable{

    item_name:string;
    th_html:string = '';
    en_html:string = '';
    text:string;
    link:string;
    styles:string;
    updated_by:string;
    updated_date:Date;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
