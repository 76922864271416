import {Deserializable} from "./deserializable.model";
import { BaseForm } from "./BaseForm"

export class CheckBoxForm extends BaseForm{

  form_no:string;
  type:string = 'checkbox';
  question:string;
  is_other:boolean;
  is_choice_other: boolean;
  choice_list:any [];
  answer_key:string [];

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
