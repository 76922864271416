import { Component, ViewChild, OnInit,Input,Output,OnChanges, EventEmitter } from '@angular/core';
import { CropperComponent, ImageCropperResult } from 'angular-cropperjs'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'; 

@Component({
    selector: 'image-cropper',
    templateUrl: './image-croppper.component.html',
    styleUrls: ['./image-croppper.component.css'],
    host: {
        '(document:keypress)': 'handleKeyboardEvent($event)'
      }
})
export class ImageCroppperComponent implements OnChanges  {
    @Input() image : any;
    @Input() imageUrl: string;
    @Input() width_ratior_image: number;
    @Input() height_ratior_image: number;
    @Output() resultCropImage = new EventEmitter<string>(); 
  
    @ViewChild('angularCropper') public angularCropper: CropperComponent;
    config = {
        aspectRatio : 1/1,
        dragMode : 'move',
        background : true,
        movable: true,
        rotatable : true,
        scalable: false,
        zoomable: true,
        viewMode: 1,
        checkImageOrigin : true,
        checkCrossOrigin: true
    };

    
 

  resultImage: any;
  resultResult: any;

  constructor(
    private sanitizer: DomSanitizer
  ){
    this.config.aspectRatio =   this.width_ratior_image/this.height_ratior_image
  }

  ngOnChanges() {

    this.config.aspectRatio =   this.width_ratior_image/this.height_ratior_image

  }

  CropMe() {

    this.angularCropper.exportCanvas();
  }

  resultImageFun(event: ImageCropperResult) {
    let urlCreator = window.URL;
    this.resultImage = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    this.resultCropImage.emit(this.resultImage);
  }

checkstatus(event: any) {
    
  if (event.blob === undefined) {
    return;
  }
  // this.resultResult = event.blob;
  let urlCreator = window.URL;
  this.resultResult = this.sanitizer.bypassSecurityTrustUrl(
      urlCreator.createObjectURL(new Blob(event.blob)));
}

handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key == 'Enter')
    {
      this.CropMe()
    }
  }
}
