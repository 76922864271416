import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ChatChannel, Chat } from '@app/model';

@Injectable({ providedIn: 'root' })
export class ChatMassageService {
    private modals: any;
    private selectedChatChannel = new Subject<ChatChannel>();
    private messageChat = new Subject<Chat>();



    changeSelectedChatChannel(chatChannel: ChatChannel) {

        this.selectedChatChannel.next(chatChannel);
    }

    clearSelectedChatChannel() {

        this.selectedChatChannel.next();
    }

    getSelectedChatChannel(): Observable<ChatChannel> {

        return this.selectedChatChannel.asObservable();
    }

    changeMessageChat(chat: Chat) {

        this.messageChat.next(chat);
    }

    clearMessageChat() {

        this.messageChat.next();
    }

    getMessageChat(): Observable<Chat> {

        return this.messageChat.asObservable();
    }
}