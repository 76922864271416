import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  ViewContainerRef,
  Inject,
  Input,
  OnDestroy,
  QueryList,
  ComponentFactoryResolver,
  HostListener
} from "@angular/core";
import { fromEvent, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ContextMenuComponent } from '@app/editor/context-menu/context-menu.compoent'
import { ImageComponentDirective, TextEditorDirective } from '@app/editor/directive';

@Directive({
  selector: "[appWrapperComponent]",
})

export class WrapperComponentDirective implements AfterViewInit {

  @Input() name: string;
  @ContentChildren(ImageComponentDirective) imageEditorDirectiveList: QueryList<ImageComponentDirective>;
  @ContentChild(TextEditorDirective) textEditorDirective: QueryList<TextEditorDirective>;

  private contextMenuRef: any = null


  constructor(
    public elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {

  }

  // @Input('appWrapperEditor') set appWrapperEditor(text: string) {
  //   console.log('myDir:', text);

  // }

  ngAfterViewInit(): void {

    console.log(this.name)
    this.getImageEditorDirective()
    // for(let i =0;i<this.imageEditorDirectiveList.length;i++){


    //   console.log(this.imageEditorDirectiveList[i])
    // }
    // this.imageEditorDirective.forEach(element => {
    //   console.log(element)
    //   //console.log(element.value)
    // });
  }

  getImageEditorDirective(){

    this.imageEditorDirectiveList.forEach(el => {
      console.log(el.elementRef.nativeElement.getAttribute('id'));
  });

  }


  private addContextMenuComponent(){

    const componentFactory = this.componentFactoryResolver
      .resolveComponentFactory(ContextMenuComponent);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    const host = this.elementRef.nativeElement;
    host.insertBefore(componentRef.location.nativeElement, host.firstChild)
    this.contextMenuRef = componentRef;

    return this.contextMenuRef;
  }

  private removeContextMenuComponent(){

    this.contextMenuRef.destroy();
    this.contextMenuRef = null;
    return this.contextMenuRef
  }



  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {

    //this will disable default action of the context menu
    event.preventDefault()
    /// add custom ContextMenu component
    this.addContextMenuComponent()
  }

  @HostListener('document:click')
  closeContextMenu() {
    if(this.contextMenuRef) {
      /// remove ContextMenu component
      this.removeContextMenuComponent()
    }
  }

  // @HostListener('mousemove', ['$event']) onMouseMove(event) {
  //   console.log(event.clientX, event.clientY);
  // }




}
