import { Component, OnInit,ViewChild,ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'training-mle',
    templateUrl: './training-mle.component.html',
    styleUrls: ['./training-mle.component.css'],
    encapsulation: ViewEncapsulation.None
  })
  
export class TrainingMleComponent implements OnInit {

  
  constructor() {
   

   }

   ngOnInit(): void {  

  }

  
    
}
