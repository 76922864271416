import { Component, OnInit,OnDestroy, Inject, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit, OnChanges, SimpleChanges, PLATFORM_ID } from '@angular/core';
import { Blog, TagBlog } from '@app/model';
import { BlogService, WindowRefService, ElementService } from '@app/service/';
import { AuthenticationService } from '@app/service';
import { ModalService } from '../helper/modal';
import { BlogStateEnum } from '@app/model/enum/BlogStateEnum';
import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';;
// import { Subscription } from 'rxjs';
import { AlertService } from '@app/helper/alert';
import { first } from 'rxjs/operators';
import { isThisQuarter, isThisSecond } from 'date-fns';
import { FeelingBlog } from '@app/model/FeelingBlog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Router, Params, ActivatedRoute, NavigationExtras } from '@angular/router';
import { HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';
import { TokenStorageService } from '@app/service';
import { selectLang } from '@environments/languageEnvironment';
import { Subscription } from "rxjs";

@Component({
    selector: 'blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.css'],

})
export class BlogComponent implements OnInit, AfterViewInit,OnDestroy {

    menuTopLeftPosition = { x: '0', y: '0' }
    @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
    @ViewChild('BlogListElement') BlogListElement: ElementRef;
    public BlogStateEnum = BlogStateEnum;
    public state = BlogStateEnum.list;
    htmlContent: string = ''
    add_blog: Blog;
    detail_blog: Blog;
    add_TagBlog_obj: TagBlog;
    tagBlog_list: TagBlog[];
    blog_list: Blog[];
    startBlogList: Blog[] = [];
    centerBlogList: Blog[] = [];
    endBlogList: Blog[] = [];
    add_commend_obj: Blog;
    sort: string = 'newer'
    blogId: string = ''
    tags: string[] = []
    currentUser: any = this.authenticationService.currentUserValue;
    apiUrl: string = environment.apiUrl
    user = this.tokenService.getUser()
    isOpenDeleteBlog: boolean = false;
    isOpenUpdatedBlog: boolean = false;
    isOpenDeleteTag: boolean = false;
    isEditedBgImage: boolean = false;
    picBackgroundBlog: any;
    selectLang = selectLang;
    lang: string = localStorage.getItem('lang')
    private subscriptions: Subscription[] = [];

    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '500px',
        minHeight: '200px',
        placeholder: 'รายละเอียด',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'KANIT',
        sanitize: false,
        fonts: [
            { class: 'arial', name: 'ARIAL' },
            { class: 'times-new-roman', name: 'TIMES NEW ROMAN' },
            { class: 'calibri', name: 'CALIBRI' },
            { class: 'KANIT', name: 'KANIT' },
            { class: 'IANNNNNDOG-REQULAR', name: 'IANNNNNDOG REQULAR' },
            { class: 'THSARABUN', name: 'THSARABUN' },
            { class: 'LYFNARAK', name: 'LYFNARAK' },
            { class: 'SOVKLORNG', name: 'SOVKLORNG' },
            { class: 'MMNIRATPARIS', name: 'MMNIRATPARIS' },
            { class: 'SOVBAAN', name: 'SOVBAAN' },
            { class: 'TALAMDUAN', name: 'TALAMDUAN' },
            { class: 'TAMAGAZINE', name: 'TAMAGAZINE' },
            { class: 'SOV3DSLIM', name: 'SOV3DSLIM' },
            { class: 'FCOUTLINECOLOR', name: 'FCOUTLINECOLOR' },
            { class: 'SOVJDKRANOK', name: 'SOVJDKRANOK' },
            { class: 'NOODLEDEMO', name: 'NOODLEDEMO' },
            { class: 'SOVSAMCHUK', name: 'SOVSAMCHUK' },
            { class: 'WHALE', name: 'WHALE' },
            { class: 'AKKHARAKT', name: 'AKKHARAKT' },
            { class: 'SPLAISAN', name: 'SPLAISAN' },
            { class: 'SOVRATAPHAN', name: 'SOVRATAPHAN' },
        ],
        customClasses: [
            {
                name: "quote",
                class: "quote",
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: "titleText",
                class: "titleText",
                tag: "h1",
            },
        ],

    };

    constructor(public authenticationService: AuthenticationService,
        private modalService: ModalService,
        private blogService: BlogService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private elementService: ElementService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private windowRefService: WindowRefService,
        private tokenService: TokenStorageService,) {

    }

    ngOnInit(): void {

        if (isPlatformBrowser(this.platformId)) {
            this.tags = []
            this.reload()
        }
    }

    getUser() {

        this.subscriptions.push(this.tokenService.getUserBehavior().subscribe(user => {

            this.user = user;
        }))
    }

    ngAfterViewInit() {

        this.set_background()

    }

    reload() {

        if (isPlatformBrowser(this.platformId)) {
            this.add_blog = new Blog()
            this.add_blog.topic = ''
            this.add_blog.detail = ''
            this.add_blog.tagBlog_list = []
            this.add_blog.is_root = true

            this.add_TagBlog_obj = new TagBlog()
            this.add_TagBlog_obj.tag_name = ''

            this.add_commend_obj = new Blog()
            this.add_commend_obj.topic = ''
            this.add_commend_obj.detail = ''
            this.startBlogList = [];
            this.centerBlogList = [];
            this.endBlogList = [];
            this.get_blogList()
            this.get_TagBlog_list()
            this.set_background()
        }
    }

    set_background() {

        this.getElementImage('pic_background_blog').subscribe((data) => {

            let url = this.apiUrl + '/media/' + data['image_part'] + '/' + data['image_name'];

            if (this.BlogListElement) {

                this.BlogListElement.nativeElement.style.backgroundImage = 'url(' + url + ')';
                this.BlogListElement.nativeElement.style.backgroundAttachment = "fixed";
                this.picBackgroundBlog = data;
                this.picBackgroundBlog.link = ''
                this.picBackgroundBlog.styles = JSON.parse(this.picBackgroundBlog.styles)
                if (!this.picBackgroundBlog.styles) {

                    this.picBackgroundBlog.styles = { hover: '', wmAnimate: '', filter: '' }
                }
            }
        });

    }

    getElementImage(element_name): Observable<string> {

        var subject = new Subject<string>();
        this.elementService.get_elementImage(element_name).pipe(first())
            .subscribe({
                next: (response: any) => {

                    if (response.serviceStatus == 'success') {
                        subject.next(response.data);
                    }
                    else {
                        subject.next(null);
                    }

                },
                error: error => {
                }
            });

        return subject.asObservable();
    }

    open_edited_image_bg() {

        this.modalService.open('pic_background_blog');
        // this.isEditedBgImage = true;
    }
    openAddBlog() {

        if (isPlatformBrowser(this.platformId)) {
            this.state = BlogStateEnum.add;
        }

    }

    closeOpenAddBlog() {

        if (isPlatformBrowser(this.platformId)) {
            var addCollapse = this.windowRefService.nativeWindow.document.getElementById("addCollapse")
            addCollapse.classList.remove('show');
        }
        this.state = BlogStateEnum.list;
        this.set_background()
    }


    add_tagBlog_to_blog(tagBlog: TagBlog) {
        if (isPlatformBrowser(this.platformId)) {
            const index: number = this.add_blog.tagBlog_list.indexOf(tagBlog);
            if (index != -1) {

                this.add_blog.tagBlog_list.splice(index, 1);

            } else {

                this.add_blog.tagBlog_list.push(tagBlog)
            }
        }
    }

    is_tagBlog_in_blog(blog: Blog, tagBlog: TagBlog) {

        var tagBlog_list = blog.tagBlog_list.filter(t => t.tag_name == tagBlog.tag_name)
        if (tagBlog_list.length > 0) {

            return true
        }

        return false
    }


    get_blogList() {

        if (isPlatformBrowser(this.platformId)) {
            this.route.queryParams.subscribe(params => {
                this.blogService.get_blogList().pipe(first())
                    .subscribe({
                        next: (response: any) => {

                            if (response.serviceStatus == 'success') {

                                this.blog_list = response.data_list

                                this.startBlogList = [];
                                this.centerBlogList = [];
                                this.endBlogList = [];

                                var blogId = params['blog_id']

                                if (blogId) {

                                    var blogList = this.blog_list.filter(blog => blog.blog_id == Number(blogId));

                                    if (blogList.length > 0) {

                                        this.detail_blog = blogList[0]
                                        this.view_feelling(this.detail_blog)
                                        this.state = BlogStateEnum.detail
                                        this.blogId = blogId
                                    }
                                }
                                else {

                                    this.state = BlogStateEnum.list;
                                    this.set_background();
                                }
                                this.sort = params['sort'];

                                if (this.sort == 'newer') {

                                    this.blog_list = this.blog_list.sort((b1, b2) => new Date(b2.updated_date).getTime() - new Date(b1.updated_date).getTime());
                                }
                                else if (this.sort == 'older') {

                                    this.blog_list = this.blog_list.sort((b1, b2) => new Date(b1.updated_date).getTime() - new Date(b2.updated_date).getTime());
                                }
                                else if (this.sort == 'popular') {

                                    this.blog_list = this.blog_list.sort((b1, b2) => b2.feelingBlog_list.filter(f => f.feeling_status == 3).length - b1.feelingBlog_list.filter(f => f.feeling_status == 3).length)

                                }
                                else if (this.sort == 'own') {

                                    this.blog_list = this.blog_list.filter((blog) => blog.created_user.user_key == this.user.user_key)

                                }
                                else {

                                    this.blog_list = this.blog_list.sort((b1, b2) => new Date(b2.updated_date).getTime() - new Date(b1.updated_date).getTime());
                                }

                                if (params['tags']) {

                                    this.tags = JSON.parse(params['tags']);

                                    if (this.tags && this.tags.length) {

                                        this.blog_list = this.blog_list.filter(b => b.tagBlog_list.filter(t => this.tags.some(x => x === t.tag_name)).length > 0)

                                    }

                                }

                                var index, len;
                                var court = 1;
                                for (index = 0, len = this.blog_list.length; index < len; ++index) {

                                    if (court == 1) {

                                        this.startBlogList.push(this.blog_list[index])
                                        court = 2
                                    }
                                    else if (court == 2) {

                                        this.centerBlogList.push(this.blog_list[index])
                                        court = 3
                                    }
                                    else if (court == 3) {

                                        this.endBlogList.push(this.blog_list[index])
                                        court = 1
                                    }

                                    // this.blog_list

                                    // console.log(a[index]);
                                }

                            }
                            else {

                            }

                        },
                        error: error => {
                        }
                    });
            });
        }
    }

    add_blogs() {

        this.state = BlogStateEnum.add;
        if (isPlatformBrowser(this.platformId)) {
            this.blogService.add_blog(this.add_blog).pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            if (isPlatformBrowser(this.platformId)) {
                                var addCollapse = this.windowRefService.nativeWindow.document.getElementById("addCollapse")
                                addCollapse.classList.remove('show');
                            }
                            this.state = BlogStateEnum.list;
                            this.reload()
                        }
                        else {

                        }

                    },
                    error: error => {
                    }
                });
        }

    }

    add_tagBlog() {

        if (isPlatformBrowser(this.platformId)) {
            this.blogService.add_tagBlog(this.add_TagBlog_obj).pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            this.add_TagBlog_obj = new TagBlog()
                            this.add_TagBlog_obj.tag_name = ''
                            this.get_TagBlog_list()

                        }
                        else {

                        }

                    },
                    error: error => {
                    }
                });
        }
    }

    get_TagBlog_list() {

        if (isPlatformBrowser(this.platformId)) {
            this.blogService.get_TagBlog_list().pipe(first())
                .subscribe({
                    next: (response: any) => {

                        if (response.serviceStatus == 'success') {

                            this.tagBlog_list = response.data_list

                        }
                        else {

                        }

                    },
                    error: error => {
                    }
                });
        }
    }

    click_detail(blog: Blog) {

        if (isPlatformBrowser(this.platformId)) {
            this.detail_blog = blog;
            this.add_commend_obj = new Blog()
            this.add_commend_obj.topic = ''
            this.add_commend_obj.detail = ''
            this.add_commend_obj.parent_blog = blog;
            if (!this.detail_blog.feelingBlog_list) {

                this.detail_blog.feelingBlog_list = []
            }
            this.view_feelling(blog)
            this.state = BlogStateEnum.detail

            const queryParams: any = {};
            queryParams.blog_id = this.detail_blog.blog_id;
            const navigationExtras: NavigationExtras = {
                queryParams
            };
            this.router.navigate(['/blog'], navigationExtras).then(() => {

            });
        }
    }

    sort_tag(tag_name) {

        if (isPlatformBrowser(this.platformId) && !this.isOpenDeleteTag) {
            if (!this.tags) {
                this.tags = []
            }
            var tag_list = this.tags.filter(tag => tag == tag_name)

            if (tag_list.length == 0) {

                this.tags.push(tag_name)
                const queryParams: any = {};
                queryParams.tags = JSON.stringify(this.tags);
                queryParams.sort = this.sort
                const navigationExtras: NavigationExtras = {
                    queryParams
                };
                this.router.navigate(['/blog'], navigationExtras).then(() => {

                });
            }
            else {

                this.tags = this.tags.filter(tag => tag != tag_name)
                const queryParams: any = {};
                queryParams.tags = JSON.stringify(this.tags);
                queryParams.sort = this.sort
                const navigationExtras: NavigationExtras = {
                    queryParams
                };
                this.router.navigate(['/blog'], navigationExtras).then(() => {

                });
            }
        }
    }

    sort_feeds(sort) {

        if (isPlatformBrowser(this.platformId)) {
            const queryParams: any = {};
            queryParams.sort = sort;
            queryParams.tags = JSON.stringify(this.tags);
            queryParams.refresh = Date.now().toString()
            const navigationExtras: NavigationExtras = {
                queryParams
            };
            this.router.navigate(['/blog'], navigationExtras).then(() => {

            });

        }
    }


    active_tag(tag_name) {

        if (isPlatformBrowser(this.platformId)) {
            let tag_list = this.tags.filter(t => t == tag_name)

            if (tag_list.length > 0) {

                return true
            }

            return false
        }
    }

    check_likeFeeling(blog: Blog) {

        return blog.feelingBlog_list.filter(f => f.feeling_status == 1 && f.feeling_user.user_key === this.user.user_key)
    }

    like_feelling(blog: Blog) {

        if (this.user) {
            if (isPlatformBrowser(this.platformId)) {
                let feeling_blog = new FeelingBlog()
                feeling_blog.feeling_status = 1
                feeling_blog.blog = blog

                this.blogService.add_feelingBlog(feeling_blog, this.detail_blog).pipe(first())
                    .subscribe({
                        next: (response: any) => {

                            if (response.serviceStatus == 'success') {

                                blog.feelingBlog_list = response.data.feelingBlog_list
                                // this.detail_blog = response.data

                            }
                        }
                    });
            }
        } else {

            this.router.navigate(['/login']);
        }

    }

    check_unlikeFeeling(blog: Blog) {

        return blog.feelingBlog_list.filter(f => f.feeling_status == 2 && f.feeling_user.user_key === this.user.user_key)
    }

    unlike_feelling(blog: Blog) {

        if (this.user) {
            if (isPlatformBrowser(this.platformId)) {
                let feeling_blog = new FeelingBlog()
                feeling_blog.feeling_status = 2
                feeling_blog.blog = blog

                this.blogService.add_feelingBlog(feeling_blog, this.detail_blog).pipe(first())
                    .subscribe({
                        next: (response: any) => {

                            if (response.serviceStatus == 'success') {

                                blog.feelingBlog_list = response.data.feelingBlog_list
                            }
                        }
                    });

            }
        }else{

            this.router.navigate(['/login']);
        }
    }

    check_viewFeelling(blog: Blog) {

        if (this.user) {

            return blog.feelingBlog_list.filter(f => f.feeling_status == 3 && f.feeling_user.user_key === this.user.user_key);

        } else {

            return blog.feelingBlog_list.filter(f => f.feeling_status == 3);

        }
    }

    view_feelling(blog: Blog) {

        if (isPlatformBrowser(this.platformId)) {
            let view_blog_list = this.check_viewFeelling(blog)
            if (view_blog_list.length <= 0) {
                let feeling_blog = new FeelingBlog()
                feeling_blog.feeling_status = 3
                feeling_blog.blog = blog

                this.blogService.add_feelingBlog(feeling_blog, this.detail_blog).pipe(first())
                    .subscribe({
                        next: (response: any) => {

                            if (response.serviceStatus == 'success') {

                                blog.feelingBlog_list = response.data.feelingBlog_list
                            }
                        }
                    });
            }
        }
    }

    goto_comment(blog: Blog) {

        this.add_commend_obj.parent_blog = blog;
        if (isPlatformBrowser(this.platformId)) {

            let el = this.windowRefService.nativeWindow.document.getElementById('opinion-commend');

            if (el) {

                el.scrollIntoView();
            }
        }

    }

    add_commend() {

        if (this.user) {
            if (isPlatformBrowser(this.platformId)) {
                this.blogService.add_comment(this.add_commend_obj, this.detail_blog).pipe(first())
                    .subscribe({
                        next: (response: any) => {
                            if (response.serviceStatus = 'success') {
                                this.detail_blog = response.data
                                this.reload()
                            }
                        }
                    });
            }
        } else {

            this.router.navigate(['/login'])
        }
    }

    sort_blog_comment(blog_list) {

        return blog_list.sort((a: Blog, b: Blog) => new Date(a.updated_date).getTime() - new Date(b.updated_date).getTime())
    }

    covertHTMLToText(topic: string) {

        var doc = new DOMParser().parseFromString(topic, "text/html");

        if (doc.childNodes && doc.childNodes.length) {

            return doc.childNodes[0].textContent;

        }
    }

    getPicFromHTML(topic: string) {

        if (isPlatformBrowser(this.platformId)) {
            var canvas = new DOMParser().parseFromString(topic, "text/html");;
            var img = canvas.querySelectorAll('img')[0]

            if (img) {
                return "<img class='img-fluid w-100 h-auto rounded mb-1' src='" + img.src + "' />"
            }
            else {
                return ''
            }
        }

    }

    cleckSubComment(blog_id) {

        if (isPlatformBrowser(this.platformId)) {
            var blog = this.windowRefService.nativeWindow.document.getElementsByClassName(blog_id);

            if (blog[0].classList.contains('show')) {

                let i = 0
                for (i = 0; i < blog.length; i++) {

                    blog[i].classList.remove('show');
                }

            }
            else {

                let i = 0
                for (i = 0; i < blog.length; i++) {

                    blog[i].classList.add('show');
                }
            }
        }
    }
    onRightClick(event: MouseEvent) {

        if (this.user && this.user.user_role.role_name == 'admin') {
            event.preventDefault();
            this.menuTopLeftPosition.x = event.clientX + 'px';
            this.menuTopLeftPosition.y = event.clientY + 'px';
            this.matMenuTrigger.openMenu()
        }
    }
    openDeleteBlog() {

        this.isOpenDeleteBlog = true;
    }
    closeDeleteBlog() {

        this.isOpenDeleteBlog = false;
    }

    openDeleteTag() {

        this.isOpenDeleteTag = true;
    }
    closeDeleteTag() {

        this.isOpenDeleteTag = false;
    }
    deleteTag(tagBlog: TagBlog) {

        this.blogService.deleted_tagBlog(tagBlog).pipe(first())
            .subscribe({
                next: (response: any) => {

                    if (response.serviceStatus == 'success') {

                        this.get_TagBlog_list()
                        this.get_blogList()
                        this.closeDeleteTag()
                        this.alertService.success(response.massage);
                    }
                    else {
                        this.alertService.error(response.massage);
                    }
                }
            });
    }

    deleteBlog(blog: Blog) {

        this.blogService.delete_blog(blog).pipe(first())
            .subscribe({
                next: (response: any) => {

                    if (response.serviceStatus == 'success') {

                        this.get_blogList()
                        this.closeDeleteBlog()
                        this.alertService.success(response.massage);
                    }
                    else {
                        this.alertService.error(response.massage);
                    }
                }
            });
    }

    openUpdatedBlog() {

        this.isOpenUpdatedBlog = true;
    }
    closeUpdatedBlog() {

        this.isOpenUpdatedBlog = false;
    }

    add_tagBlog_to_detailblog(tagBlog: TagBlog) {
        if (isPlatformBrowser(this.platformId)) {
            const index: number = this.detail_blog.tagBlog_list.findIndex(b => b.tag_name === tagBlog.tag_name);
            if (index != -1) {

                this.detail_blog.tagBlog_list.splice(index, 1);

            } else {

                this.detail_blog.tagBlog_list.push(tagBlog)
            }
        }
    }
    updated_blog() {

        this.blogService.updated_blog(this.detail_blog).pipe(first())
            .subscribe({
                next: (response: any) => {

                    if (response.serviceStatus == 'success') {

                        this.closeUpdatedBlog()
                        this.alertService.success(response.massage);
                    }
                    else {
                        this.alertService.error(response.massage);
                    }
                }
            });

    }

    deleted_comment(blog: Blog) {


    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s?.unsubscribe());
    }
}
