import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoginService } from '@app/login/login.service';
import { AuthenticationService } from '@app/service';
import { Router } from '@angular/router';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor  {
  constructor(private authenticationService: AuthenticationService,
    private loginService: LoginService,
    private router: Router,) { }

    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // return next.handle(request).pipe(catchError(err => {
      //     if ([401, 403].includes(err.status)) {
      //         // auto logout if 401 or 403 response returned from api
      //         this.authenticationService.logout();
      //     }

      //     const error = (err && err.error && err.error.message) || err.statusText;
      //     console.error(err);
      //     return throwError(error);
      // }))
  // }
  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   return next.handle(request).pipe(catchError(err => {
  //     // if (err.status === 401 ||err.status === 403) {
  //     const currentUser = this.authenticationService.currentUserValue()

  //     if (err.status === 401 && this.router.url != '/login') {

  //       let error = err.error;
  //       if (error.detail == 'expiredToken') {

  //         this.authenticationService.currentUserValue().pipe(
  //           filter(token => token !== null),
  //           take(1),
  //           switchMap((account) => next.handle(this.getRequest(request, account['access_token']))))

  //       }

  //         // this.authenticationService.refreshToken().subscribe((account) => {
  //         //   console.log(account)
  //         //   request = request.clone({
  //         //     setHeaders: {
  //         //       Authorization: `Token ${account.access_token}`
  //         //     }
  //         //   });



  //         // });
  //         // this.authenticationService.refreshToken().subscribe((account) => {
  //         //   console.log(account)
  //         //   let return_request = request.clone({
  //         //       setHeaders: {
  //         //         Authorization: `Token ${account.access_token}`
  //         //       }
  //         //     });
  //         //   return next.handle(return_request);
  //         // })
  //         // return this.authenticationService.refreshToken().pipe(
  //         //   filter(result => result !== null),
  //         //   take(1),
  //         //   switchMap((res) => {
  //         //       return next.handle(this.injectToken(request))
  //         //   })
  //         // );

  //       else {

  //         localStorage.setItem('beforePage', this.router.url);
  //         this.router.navigate(['/login'])
  //       }

  //     }
  //     else if (err.status === 403) {

  //       localStorage.setItem('beforePage', this.router.url);
  //       this.router.navigate(['/forbiden-page'])
  //     }

  //     const error = err.error.message || err.statusText;
  //     return throwError(error);
  //   }))
  // }

  // getRequest(request,access_token){

  //   console.log(access_token)
  //   request = request.clone({
  //           setHeaders: {
  //             Authorization: `Token ${access_token}`
  //           }
  //         });
  //   return request;
  // }
}
