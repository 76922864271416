import { Injectable, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthenticationService, TokenStorageService } from '@app/service';
import { Permission } from '@app/model';
import { LoginService } from '@app/login/login.service';
import { first } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';;

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  permission: Permission;
  currentUser: any = null;
  permissionURL: string = ''
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private loginService: LoginService,
    private tokenStorageService: TokenStorageService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return new Promise((resolve) => {

      this.currentUser = this.tokenStorageService.getUser();
      if (this.currentUser) {

        this.permissionURL = state.url.substring(1);
        this.getPermission(this.permissionURL).subscribe((permission) => {

          console.log(permission)
          console.log(this.currentUser)
          this.permission = permission;
          let userRoleName = this.currentUser.user_role.role_name;

          let userType = '';
          if (this.currentUser.user_type) {

            userType = this.currentUser.user_type.user_type;
          }

          let isUserRole = this.switchCaseUserRole(userRoleName);
          let isUserType = this.switchCaseUserType(userType);
          console.log('userRoleName:' + userRoleName);
          console.log('userType:' + userType);
          console.log('isUserRole:' + isUserRole);
          console.log('isUserType:' + isUserType);
          if (isUserRole && isUserType) {

            resolve(true);
          } else {

            this.router.navigate(['/forbiden-page'])
            resolve(false);

          }

        });

      } else {

        this.router.navigate(['/login']);
        resolve(false);

      }
    });
  }


  switchCaseUserRole(role): boolean {

    if (role) {

      switch (role.toLowerCase()) {
        case 'admin':

          return true
          break;
        case 'webmaster':

          return true
          break;
        case 'visitor':

          return this.checkUserRolePermission(this.currentUser.user_role);
          break;
        default:

          return true;
          break;
      }
    }
    else{

      return true
    }

  }


  checkUserRolePermission(userRole): boolean {
    console.log('userRole' + userRole.role_id)
    console.log(this.permission)
    if (this.permission && this.permission.userRole_list) {

      let index = this.permission.userRole_list.findIndex(role => role.role_id == userRole.role_id);
      console.log('userRoleindex: ' + index)
      if (index !== -1) {

        return true;
      } else {

        return false;
      }
    } else {

      return true;
    }
  }

  switchCaseUserType(type): boolean {

    if (type) {
      switch (type.toLowerCase()) {
        case 'visitor':

          return this.checkUserTypePermission(this.currentUser.user_type);
          break;

        default:

          return true;
          break;
      }
    }else{

      return true;
    }
  }

  checkUserTypePermission(userType): boolean {
    console.log('userType' + userType.userType_id)
    if (this.permission && this.permission.userRole_list) {

      let index = this.permission.userType_list.findIndex(type => type.userType_id == userType.userType_id);
      console.log('usertypeindex: ' + index)
      if (index !== -1) {

        return true;
      } else {

        return false;
      }
    } else {

      return true;
    }
  }

  getPermission(permission): Observable<Permission> {

    var subject = new Subject<Permission>();
    this.authenticationService.get_permission(permission)
      .pipe(first())
      .subscribe(
        response => {
          if (response['serviceStatus'] == 'success') {

            let permission = response['data']
            subject.next(permission);
          }
          else {

            subject.next(permission);

          }
        });
    return subject.asObservable();
  }
}




//   async check_permission() {

//     this.permission = await this.authenticationService.get_permission_promise(this.url).toPromise()
//     this.permission = this.permission.data
//     console.log(this.permission)
//     console.log(this.permission)

//     if (this.permission.userRole_list && this.permission.userRole_list.length > 0) {

//       if (this.permission.userRole_list.filter(userRole => userRole.role_id == this.currentUser.user_role.role_id).length > 0) {

//         if (this.permission.userType_list && this.permission.userType_list.length > 0) {

//           if (this.permission.userType_list.filter(userType => userType.userType_id == this.currentUser.user_type.userType_id).length > 0) {


//             return true
//           }
//           else{
//             console.log(2)
//             return false
//           }

//         }
//         else {
//           console.log(3)
//           return true
//         }

//       }
//       else {
//         console.log(4)
//         return false
//       }

//     }
//     else {
//       console.log(5)
//       return true;
//     }

//     return false
//   }
// }
