
import {Deserializable} from "./deserializable.model";
import {User} from "./User";
import {UserRole} from "./UserRole"
export class EndPoint implements Deserializable{

    endpoint_id:number;
    app:string;
    end_point:string;
    userRole_list:UserRole[];
    user_list:User[];
    is_active:boolean;
    is_deleted:boolean = false;
    created_date:Date
    created_by:string;
    updated_by:string;
    
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



