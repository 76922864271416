import { Injectable, OnInit } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient, HttpRequest, HttpParams, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ElementFiles,Instrument,VideoTraining } from '@app/model';

@Injectable({ providedIn: 'root' })
export class VideoTrainingService {

  constructor(private http: HttpClient) {

  }

  get_videoTraining_list() {

    let params = new HttpParams();
    return this.http.get(`${environment.apiUrl}/api/videoTraining/videoTraining_list`,
    { params: params })
      .pipe(map(videoTraining => {

        return videoTraining;

      }));;
  }

  save_videoTraining(videoTraining: VideoTraining) {

    const formData: FormData = new FormData();
    // formData.append('file', file);
    formData.append('videoTraining', JSON.stringify(videoTraining));
    formData.append('lang', localStorage.getItem('lang'));

    return this.http.post<any>(`${environment.apiUrl}/api/videoTraining/save_videoTraining`,
      formData)
      .pipe(map(videoTraining => {

        return videoTraining;

      }));
  }

  delete_videoTraining(videoTraining: VideoTraining) {

    return this.http.post<any>(`${environment.apiUrl}/api/videoTraining/delete_videoTraining`,
      { videoTraining: videoTraining })
      .pipe(map(videoTraining => {

        return videoTraining;

      }));
  }
}
