import {Deserializable} from "./deserializable.model";
import { BaseForm } from "./BaseForm"

export class ParagraphForm extends BaseForm{

  form_no:string;
  type:string = 'paragraph';
  description:string;
  question:string;
  answer:string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
