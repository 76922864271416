import { Pipe, PipeTransform } from '@angular/core';
import { getDay } from 'ngx-bootstrap';
@Pipe({
    name: 'datetime'
})
export class DateTimePipe implements PipeTransform {
    transform(date: Date) {

        if(date) {

            let nowDate = new Date()
            let nowDateUtc = new Date(nowDate.toISOString());
            date = new Date(new Date(date).toISOString());

            let minute = (nowDateUtc.getTime() - date.getTime()) / 60000;
            var offset = new Date().getTimezoneOffset();
            if (minute < 60) {

                return Math.ceil(minute) + ' minute ago'
            }
            else if (minute >= 60 && minute < 1440) {

                let hour = minute / 60
                return Math.ceil(hour) + ' hour ago'
            }
            else if (minute >= 1440 && minute < 10080) {

                date.setMinutes(offset)
                return this.getDay(date) + ' ago'
            }
            else {

                date.setMinutes(offset)

                return date.getDay() + "/" + date.getMonth() + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
            }
        }
    }

    getDay(date: Date) {

        let day = '';
        switch (date.getDay()) {
            case 0:
                day = "Sunday";
                break;
            case 1:
                day = "Monday";
                break;
            case 2:
                day = "Tuesday";
                break;
            case 3:
                day = "Wednesday";
                break;
            case 4:
                day = "Thursday";
                break;
            case 5:
                day = "Friday";
                break;
            case 6:
                day = "Saturday";
        }

        return day
    }
}


