import { Component, Input, Output, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { DropdownForm } from '@app/assessment/question-form/model'
import { FormStateEnum } from '@app/assessment/question-form/model/enum/FormStateEnum'
import { selectLang } from '@environments/languageEnvironment';
import { BaseFormComponent } from '@app/assessment/question-form/base-form/baseform.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'dropdown-form',
  templateUrl: './dropdown-form.component.html',
  styleUrls: ['./dropdown-form.component.css']
})

export class DropdownComponent extends BaseFormComponent implements OnInit {

  // @Output() outAnswer: EventEmitter<{}> = new EventEmitter<{}>();

  baseForm: DropdownForm;
  other_text: string = "";
  other_choice_index: number;
  is_other: boolean = false;
  answer: string = null;

  ngOnInit() {
    /* extends method from BaseFormComponent */
    super.ngOnInit();

  }

  changed_dropdown(choice) {

    this.answer_list = [];
    this.answer_list.push(choice);
    this.changed_answer();
  }



  changed_option() {

    this.out_form_event();
  }

  added_option() {

    let index = this.baseForm.choice_list.length;
    this.baseForm.choice_list.push({ option: 'option' + (index + 1) });
    this.out_form_event();
  }

  deleted_option(index) {
    
    this.baseForm.choice_list.splice(index, 1);
    this.out_form_event();
  }
}