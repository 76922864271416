
import {Deserializable} from "./deserializable.model";
import { User } from "./User";
import { Chat } from "./Chat";
export class ChatChannel {

    chatChannel_id:number;
    channel_name:string;
    group_name:string;
    updated_date:Date;
    user_list: User[];
    chat_list:Chat[];
 

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



