import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  HostListener,
  ComponentFactoryResolver,
  ViewContainerRef
} from "@angular/core";
import { User } from '@app/model';
import { ElementComponent } from "@app/element/element.component";
import { JWModalComponent } from '@app/helper/modal/modal.component';
import { EditorComponent } from '@app/helper/editor/editor.component';
import { RightClickComponent } from "@app/element/directive/right-click/right-click.component";
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ElementService } from '@app/service/';
import { ElementFiles } from '@app/model'
import { environment } from '@environments/environment';
import { TokenStorageService } from '@app/service';

@Directive({
  selector: "[fileElement]",
})

export class FileElementDirective implements AfterViewInit {

  @Input() name: string;
  @Input() user_editor: string[];
  file: ElementFiles = new ElementFiles()
  apiUrl: string = environment.apiUrl
  rightClickcomponentRef: any;
  user: User = this.tokenStorageService.getUser();

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private elementService: ElementService,
    public vcRef: ViewContainerRef,
    public elementRef: ElementRef,
    private tokenStorageService: TokenStorageService
  ) {

  }

  ngAfterViewInit() {
    this.get_elementFile(this.name).subscribe((data) => {
      this.file = data;

    });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {

    if (this.user_editor.includes(this.user.user_role.role_name)) {

      event.preventDefault();

      let rightClickComponentFactory = this.componentFactoryResolver.resolveComponentFactory(RightClickComponent)
      this.rightClickcomponentRef = this.vcRef.createComponent(rightClickComponentFactory);
      this.rightClickcomponentRef.instance.menuTopLeftPosition.x = event.clientX + 'px';
      this.rightClickcomponentRef.instance.menuTopLeftPosition.y = event.clientY + 'px';
      this.rightClickcomponentRef.instance.type = 'file';
      this.rightClickcomponentRef.instance.name = this.name;
      this.rightClickcomponentRef.instance.file_element_edited = { name: this.name, file: this.file };
    }
  }

  private wasInside = false;
  @HostListener('click')
  clickInside() {

    if (this.user) {

      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', this.apiUrl + '/media/' + this.file.file);
      link.setAttribute('download', this.file.file);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    if (this.rightClickcomponentRef) {

      this.rightClickcomponentRef.instance.close_right_click()
    }
    this.wasInside = true;
    // this.vcRef.clear();
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      if (this.rightClickcomponentRef) {

        this.rightClickcomponentRef.instance.close_right_click()
      }
    }
    this.wasInside = false;
  }

  get_elementFile(element_name): Observable<any> {

    var subject = new Subject<string>();
    this.elementService.get_elementFile(element_name).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus = 'success') {

            subject.next(response.data);

          }
          else {
            subject.next(null);
          }

        },
        error: error => {
        }
      });

    return subject.asObservable();
  }
}
