import { Directive, Output, Input, EventEmitter, HostBinding, HostListener, HostBindingDecorator } from '@angular/core';

@Directive({
    selector: '[drag-drop]'
})
export class DragdropDirective {

    @Input('drag-drop') form_no: string
    @Output() dragdropOutput = new EventEmitter<any>();
    @HostBinding('style.background-color') public background = '#fff';
    @HostBinding('style.opacity') public opacity = '1';

    // @HostListener("click", ["$event"])
    // public onListenerTriggered(evt) {
    //     evt.preventDefault();
    //     evt.stopPropagation();
    //     this.dragdropOutput.emit({ action: 'drag', value:this.form_no });
    // }

    @HostListener("drag", ["$event"])
    public onDragoStart(evt) {
        evt.preventDefault();
        console.log('drag'+evt.target.id)
        this.dragdropOutput.emit({ action: 'drag', value:evt.target.id });
    }

    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#9ecbec';
        this.opacity = '0.8'
    
    };

    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#fff'

    }

    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#f5fcff'
        this.opacity = '1'
        this.dragdropOutput.emit({ action: 'drop', value: this.form_no });
    }



    constructor() { }

}
