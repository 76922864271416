
import {Deserializable} from "./deserializable.model";
import { User } from "./User";
import {ElementImage} from "./ElementImage";

export class Assessments {

  assessment_no:string;
  name:string;
  topic:string;
  description:string;
  forms:string;
  image:ElementImage;
  is_actived:boolean;
  updated_date:Date;
  updated_by:User;
  permissions:any;


    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
