import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { WebsocketService } from './websocket.service';
import { ChatChannel,Chat } from '@app/model';
import { AbstractService } from './abstract.service';

export interface Message {
    author: string;
    message: string;
  }

@Injectable({ providedIn: 'root' })
export class ChatService extends AbstractService {
    headers: any
    authToken: string;
    username: string;
    public messages;

    private chat_reload = new Subject<any>();
    public  $chat_reload = this.chat_reload.asObservable();
    apiUrl: string = environment.apiUrl;
    websocketUrl: string = environment.websocketUrl;
    // public  $redirect_url = this.messages.asObservable();
    // public  $messages = this.messages.asObservable();
    constructor(
        private router: Router,
        private wsService: WebsocketService,
        http: HttpClient
    ) {

        super(http)
        this.messages = <Subject<Message>>wsService.connect(this.websocketUrl+'/ws/chat/chat' + '/').map(
            (response: MessageEvent): Message => {
                let data = JSON.parse(response.data);
                return data
            }
        );

        this.messages.subscribe(msg => {
            this.chat_reload.next(msg);

        })
    }

    get_chatChannel() {

        return this.http.get(`${environment.apiUrl}/api/chat/chatChannel_list`)
            .pipe(map(ChatChannel => {

                return ChatChannel;

            }));;
    }

    add_chat(chat:Chat) {

        return this.http.post<any>(`${environment.apiUrl}/api/chat/add_chat`,
                { chat: chat })
                .pipe(map(chat => {

                    return  chat;

                }));

    }

    read_chat(chatChannel:ChatChannel) {

        return this.http.post<any>(`${environment.apiUrl}/api/chat/read_chat`,
                { chatChannel: chatChannel })
                .pipe(map(chatChannel => {

                    return  chatChannel;

                }));

    }

}
