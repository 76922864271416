import { Component, OnInit, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { AuthenticationService, SystemInfoService } from '@app/service';
import { first } from 'rxjs/operators';
import { SystemInfo } from '@app/model';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { NewsService, WindowRefService } from '@app/service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  systemInfo: SystemInfo = new SystemInfo()

  constructor(private systemInfoService: SystemInfoService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService) {

    this.get_systemInfo()
  }

  ngOnInit(): void {
  }

  public executeSelectedChange = (event) => {
  }

  get_systemInfo() {

    if (isPlatformBrowser(this.platformId)) {
      this.systemInfoService.get_systemInfo().pipe(first())
        .subscribe({
          next: (response: any) => {

            if (response.serviceStatus == 'success') {

              this.systemInfo = response.data;
              this.edited_systemInfo()

            }
            else {

            }

          },
          error: error => {
          }
        });
    }
  }

  edited_systemInfo() {

    if (isPlatformBrowser(this.platformId)) {
      this.systemInfoService.edited_systemInfo(this.systemInfo).pipe(first())
        .subscribe({
          next: (response: any) => {

            if (response.serviceStatus == 'success') {

              this.systemInfo = response.data;
            }
            else {

            }

          },
          error: error => {
          }
        });
    }

  }


  href_facebook() {

    if (isPlatformBrowser(this.platformId)) {
      window.location.href = 'https://web.facebook.com/Foundation.for.Applied.Linguistics?_rdc=1&_rdr';
    }
  }

  href_youtube() {

    if (isPlatformBrowser(this.platformId)) {
      window.location.href = 'https://www.youtube.com/user/thaifal?app=desktop';
    }
  }


}
