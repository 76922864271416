

import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalService } from '../helper/modal';
import { ElementService } from '../service/';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { ElementImage, ElementText, News } from '@app/model';
import { NewsService } from '@app/service/';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-story-info',
    templateUrl: './story-info.component.html',
    styleUrls: ['./story-info.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class StoryInfoComponent implements OnInit {


    storyId: string;
    news: News = new News()
    newsList: News [];

    constructor(private activatedRoute: ActivatedRoute, private newsService: NewsService) {

        this.storyId = this.activatedRoute.snapshot.paramMap.get("storyId")
        // this.reload()
    }

    ngOnInit(): void {

        this.reload()

    }

    reload() {

        this.get_news()
        this.get_newsList()
        
    }

    get_news() {

        this.newsService.get_news(this.storyId).pipe(first())
            .subscribe({
                next: (response: any) => {

                    if (response.serviceStatus == 'success') {

                        this.news = response.data;
                        this.edited_news()

                    }
                    else {

                    }

                },
                error: error => {
                }
            });
    }

    get_newsList() {

        this.newsService.get_storyList().pipe(first())
            .subscribe({
                next: (response: any) => {

                    if (response.serviceStatus == 'success') {

                        var  today = new Date();
                        var data_list = response.data_list.sort((a,b) => b.views - a.views);
                        
                        if(data_list.length >=5){
                            
                            this.newsList = data_list.slice(0, 5)
                        }
                        else{
                          
                            this.newsList = data_list
                        }

                    }
                    else {

                    }

                },
                error: error => {
                }
            });
    }

    edited_news() {

        this.newsService.edited_news(this.news).pipe(first())
            .subscribe({
                next: (response: any) => {

                    if (response.serviceStatus == 'success') {

                        // this.reload()

                    }
                    else {

                    }

                },
                error: error => {
                }
            });
    }

    covertHTMLToText(topic){

        var doc = new DOMParser().parseFromString(topic, "text/html");

        if(doc.childNodes&&doc.childNodes.length){

            return doc.childNodes[0].textContent

        }
    }

    linkNewsDetail(link){

        window.location.href = link;
    }

}