
import {Deserializable} from "./deserializable.model";
import { User } from "./User";

export class Media implements Deserializable{

    media_id:number;
    type:string;
    file:File;
    created_user:User;
    created_date:Date;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
