import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
} from "@angular/core";
import { fromEvent, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({
  selector: "[appTextEditor]",
})

export class TextEditorDirective implements AfterViewInit {

  constructor(
    public elementRef: ElementRef
  ){

  }

  ngAfterViewInit(): void {

  }
}
