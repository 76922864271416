import { Component, OnInit, HostListener, AfterViewInit, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { Assessments, UserRole, UserType, Permission } from '@app/model'
import { BaseForm, SectionForm, TitleForm, ShortTextForm, MultipleChoice, DropdownForm, MultipleChoiceGrid, CheckBoxForm, LinkForm } from '@app/assessment/question-form/model';
import { AuthenticationService } from '@app/service/';
import { AssessmentService } from '@app/assessment/services/assessment.service';
import { SectionFormComponent } from '@app/assessment/question-form/section-form/section-form.component'
import { TitleFormComponent } from '@app/assessment/question-form/title-form/title-form.component'
import { ShortTextComponent } from '@app/assessment/question-form/shorttext-form/shorttext-form.component'
import { MultipleChoiceComponent } from '@app/assessment/question-form/multiplechoice-form/multiplechoice-form.component'
import { MultipleChoiceGridComponent } from '@app/assessment/question-form/multiplechoicegrid-form/multiplechoicegrid-form.component'
import { DropdownComponent } from '@app/assessment/question-form/dropdown-form/dropdown-form.component'
import { CheckBokComponent } from '@app/assessment/question-form/checkbox-form/checkbok-form.component'
import { LinkComponent } from '@app/assessment/question-form/link-form/link-form.component'
import { AssessmentDirective } from '@app/assessment/directive/assessment.directive'
import { AlertService } from '@app/helper/alert';
import { Router, Params, ActivatedRoute, NavigationExtras } from '@angular/router';
import { first } from 'rxjs/operators';
import { Validation } from '../question-form/model/Validation';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { BehaviorSubject } from 'rxjs';
import { basename } from 'path';
import { _ } from 'core-js';
import { Observable, Subject } from 'rxjs';;

@Component({
  selector: 'created-assessment',
  templateUrl: './created-assessment.component.html',
  styleUrls: ['./created-assessment.component.css']
})

export class CreatedAssessmentComponent implements OnInit, AfterViewInit {

  @ViewChild(AssessmentDirective) assessmentDirective: AssessmentDirective;

  assessment_no: string;
  assessment: Assessments = new Assessments();
  form_list: any[] = [];
  component_form_list: any[] = [];
  selected_formNo: string = null;
  private view_container_ref: ViewContainerRef;
  $updeted_behavior = new BehaviorSubject({});
  add_form: BaseForm;

  // assessments: Assessments = new Assessments()
  // question_list: any[] = [];
  selectedUserRoleId: number;
  selectedUserTypeId: number;
  // select_userType_id: number;
  userType_list: UserType[] = [];
  userRole_list: UserRole[] = [];
  permission: Permission = new Permission();
  // userType_list: UserType[] = [];
  // keep_userType_list: UserType[] = [];
  // copy_form: any = null;
  // assessments_no: string;
  // permission: Permission;
  // component_question_list: any[] = [];
  // drag_form_no: string = null;
  // drop_form_no: string = null;
  // // order_number: number = 0;
  // current_number: number = 0;
  // private viewContainerRef: ViewContainerRef;
  // $current_update = new BehaviorSubject({});

  // section_list: any[] = [];

  constructor(public viewsContainerRef: ViewContainerRef,
    private assessmentService: AssessmentService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private AuthenticationService: AuthenticationService,) {

  }

  ngOnInit() {

    this.get_userRole_list();
    this.get_userType_list();
    this.route.queryParams
      .subscribe(params => {

        let permission = 'assessment-views?assessments_no=' + params.assessments_no;
        this.getPermission(permission).subscribe((permission) => {
          console.log(permission);
          if(permission.userRole_list&&permission.userRole_list.length >0){

            this.selectedUserRoleId = permission.userRole_list[0].role_id;
          }
          if(permission.userType_list&&permission.userType_list.length >0){

            this.selectedUserTypeId = permission.userType_list[0].userType_id;
          }
        });

      });
  }

  ngAfterViewInit() {

    this.get_assessments();
    // this.current_number = 0;
    // this.get_assessments();
    // this.get_assessments();

    // this.get_permission();

  }

  getPermission(permission): Observable<Permission> {

    var subject = new Subject<Permission>();

    this.AuthenticationService.get_permission(permission)
      .pipe(first())
      .subscribe(
        response => {
          if (response['serviceStatus'] == 'success') {

            this.permission = response['data'];
            subject.next(this.permission);
          }
          else {

            this.alertService.error(response['massage']);
            subject.next(null);
          }
        });
    return subject.asObservable();
  }

  get_userType_list() {

    this.AuthenticationService.userType_list()
      .pipe(first())
      .subscribe(
        response => {
          if (response['serviceStatus'] == 'success') {

            this.userType_list = response['data_list'];
            console.log(this.userType_list);
          } else {

            this.alertService.error(response['massage']);
          }
        });
  }

  get_userRole_list() {

    this.AuthenticationService.userRole_list()
      .pipe(first())
      .subscribe(
        response => {

          if (response['serviceStatus'] == 'success') {

            this.userRole_list = response['data_list'];
          } else {

            this.alertService.error(response['massage']);
          }
        });

  }

  select_userType_from_role() {

    if (this.selectedUserRoleId) {

      let userType_list = this.userType_list.filter(userType => userType.user_role && (userType.user_role['role_id'] == this.selectedUserRoleId));

      return userType_list;
    } else {

      return [];
    }
  }

  changeUserRolePermission() {

    let selectedUserRole = this.userRole_list.filter(userRole => userRole.role_id == this.selectedUserRoleId)[0];
    this.permission.userRole_list = [];
    this.permission.userRole_list.push(selectedUserRole);

    this.savePermission();

  }

  changeUserTypePermission() {

    let selectedUserType = this.userType_list.filter(userType => userType.userType_id == this.selectedUserTypeId)[0];
    this.permission.userType_list = [];
    this.permission.userType_list.push(selectedUserType);

    this.savePermission();

  }

  savePermission() {

    this.AuthenticationService.save_permission(this.permission)
      .pipe(first())
      .subscribe(
        response => {

          if (response['serviceStatus'] == 'success') {

            this.permission = response['data'];
          } else {

            this.alertService.error(response['massage']);
          }

        });
  }

  get_assessments() {

    this.route.queryParams.subscribe(params => {

      this.assessment_no = params['assessments_no'];
      this.assessmentService.get_assessments(params['assessments_no'])
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.assessment = response['data'];

              if (this.assessment && this.assessment.forms) {


                let form_list = JSON.parse(this.assessment.forms)
                this.add_forms_to_crate(form_list);

              } else {

                let sectionForm = new SectionForm();
                this.added_form('section');
              }
            }
            else {

              console.log(response.massage)
            }
          });
    });
  }

  changed_form() {

    this.$updeted_behavior.next({ action: 'changed_form', form_list: this.form_list })
  }
  created_component(component) {

    let component_factory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.view_container_ref = this.assessmentDirective.viewContainerRef;
    let created_component = this.view_container_ref.createComponent(component_factory);

    this.component_form_list.push(created_component);

    return created_component;
  }

  deleted_component(index) {

    // let deleted_component = this.component_form_list[index];
    this.component_form_list.splice(index, 1);
    this.view_container_ref.remove(index);

  }

  move_component(form_index, to_index: number, componentRef: ComponentRef<any>) {


    this.component_form_list.splice(form_index, 1);
    this.component_form_list.splice(to_index, 0, componentRef);
    console.log(componentRef);
    this.view_container_ref.move(componentRef.hostView, to_index);

  }

  added_formList(form: any) {

    this.form_list.push(form);
    this.updated_json_assessments();
  }

  added_index_formList(from_index, to_index, form: any) {

    this.form_list.splice(from_index, 1);
    this.form_list.splice(to_index, 0, form);
  }

  updated_formList(form) {

    let index = this.form_list.findIndex(e => e.form_no == form.form_no);
    if (!(index == -1)) {

      this.form_list[index] = form;
    }
  }

  deleted_formList(form) {

    let index = this.form_list.findIndex(e => e.form_no == form.form_no);

    if (!(index == -1)) {

      this.form_list.splice(index, 1);

    }
  }

  add_forms_to_crate(form_list) {

    for (let index = 0; index < form_list.length; index++) {

      if (index == 0) {

        this.selected_formNo = form_list[index].form_no;
      }
      this.create_form(form_list[index]);

    }
  }

  added_form(type) {

    let basseForm = new BaseForm();
    let form_no = this.generate_uuid4();
    basseForm.form_no = form_no;
    basseForm.type = type;
    basseForm.order_number = this.form_list.length - 1;
    this.create_form(basseForm);
  }

  create_form(form) {

    switch (form.type) {
      case 'section': {

        let component = this.created_component(SectionFormComponent)
        this.add_sectionForm(form, component)
        break;

      }
      case 'title': {

        let component = this.created_component(TitleFormComponent)
        this.add_titleForm(form, component)
        break;
      }
      case 'shorttext': {

        let component = this.created_component(ShortTextComponent)
        this.add_shorttextForm(form, component)

        break;
      }
      case 'checkbox': {

        let component = this.created_component(CheckBokComponent)
        this.add_checkBoxForm(form, component)

        break;
      }
      case 'multiplechoice': {

        let component = this.created_component(MultipleChoiceComponent)
        this.add_multiplechoiceForm(form, component)

        break;
      }
      case 'multiplechoicegrid': {

        let component = this.created_component(MultipleChoiceGridComponent)
        this.add_multiplechoiceGridForm(form, component)

        break;
      }
      case 'dropdown': {

        let component = this.created_component(DropdownComponent)
        this.add_dropdownForm(form, component)

        break;
      }
      case 'link': {
        let component = this.created_component(LinkComponent)
        this.add_linkForm(form, component)
      }
    }
  }

  add_sectionForm(form, component) {

    let sectionForm = new SectionForm()
    sectionForm.form_no = form.form_no;
    sectionForm.title = form.title;
    sectionForm.description = form.description;
    sectionForm.is_required = form.is_required;
    sectionForm.is_deleted = form.is_deleted;
    sectionForm.order_number = form.order_number;
    console.log('add_sectionForm');
    /* pass data to section form component */
    component.instance['baseForm'] = sectionForm;
    component.instance['is_views'] = false;
    component.instance['updeted_behavior'] = this.$updeted_behavior;

    this.added_formList(sectionForm);

    component.instance['outFormEvent'].subscribe(event => {

      this.switched_out_event(event);
    });

  }

  add_titleForm(form, component) {

    let titleForm = new TitleForm()
    titleForm.form_no = form.form_no;
    titleForm.title = form.title;
    titleForm.description = form.description;
    titleForm.is_required = form.is_required;
    titleForm.is_deleted = form.is_deleted;
    titleForm.order_number = form.order_number;

    /* pass data to section form component */
    component.instance['baseForm'] = titleForm;
    component.instance['is_views'] = false;
    component.instance['updeted_behavior'] = this.$updeted_behavior;

    this.added_formList(titleForm);

    component.instance['outFormEvent'].subscribe(event => {

      this.switched_out_event(event);

    });
  }

  add_shorttextForm(form, component) {

    let shortTextForm = new ShortTextForm();

    shortTextForm.form_no = form.form_no;
    shortTextForm.question = form.question;
    shortTextForm.validation = form.validation;
    shortTextForm.is_required = form.is_required;
    shortTextForm.is_deleted = form.is_deleted;
    shortTextForm.order_number = form.order_number;

    /* pass data to section form component */
    component.instance['baseForm'] = shortTextForm;
    component.instance['is_views'] = false;
    component.instance['updeted_behavior'] = this.$updeted_behavior;

    this.added_formList(shortTextForm);

    component.instance['outFormEvent'].subscribe(event => {

      this.switched_out_event(event);
    })
  }

  add_checkBoxForm(form, component) {

    let checkBoxForm = new CheckBoxForm();
    checkBoxForm.form_no = form.form_no;
    checkBoxForm.question = form.question;
    checkBoxForm.is_choice_other = form.is_choice_other;
    checkBoxForm.choice_list = [];
    for (var index = 0; index < form.choice_list.length; index++) {

      checkBoxForm.choice_list.push({ option: form.choice_list[index].option });
    }

    if (form.choice_list.length == 0) {

      checkBoxForm.choice_list.push({ option: 'option1' })
    }

    checkBoxForm.is_required = form.is_required;
    checkBoxForm.is_deleted = form.is_deleted;
    checkBoxForm.order_number = form.order_number;

    component.instance['baseForm'] = checkBoxForm;
    component.instance['is_views'] = false;
    component.instance['updeted_behavior'] = this.$updeted_behavior;

    this.added_formList(checkBoxForm);

    component.instance['outFormEvent'].subscribe(event => {

      this.switched_out_event(event);
    })

  }

  add_multiplechoiceForm(form, component) {

    let multipleChoice = new MultipleChoice();
    multipleChoice.form_no = form.form_no;
    multipleChoice.question = form.question;
    multipleChoice.choice_list = [];
    for (var index = 0; index < form.choice_list.length; index++) {

      multipleChoice.choice_list.push({ option: form.choice_list[index].option });
    }

    if (form.choice_list.length == 0) {

      multipleChoice.choice_list.push({ option: 'option1' })
    }

    multipleChoice.is_choice_other = form.is_choice_other;
    multipleChoice.is_required = form.is_required;
    multipleChoice.is_deleted = form.is_deleted;
    multipleChoice.order_number = form.order_number;

    component.instance['baseForm'] = multipleChoice;
    component.instance['is_views'] = false;
    component.instance['updeted_behavior'] = this.$updeted_behavior;

    this.added_formList(multipleChoice);

    component.instance['outFormEvent'].subscribe(event => {

      this.switched_out_event(event);
    });

  }

  add_multiplechoiceGridForm(form, component) {

    let multipleChoiceGrid = new MultipleChoiceGrid();
    multipleChoiceGrid.is_deleted = form.is_deleted;
    multipleChoiceGrid.form_no = form.form_no;
    multipleChoiceGrid.question = form.question;
    multipleChoiceGrid.choice_list = [];
    for (var index = 0; index < form.choice_list.length; index++) {

      multipleChoiceGrid.choice_list.push({ option: form.choice_list[index].option });
    }

    if (form.choice_list.length == 0) {

      multipleChoiceGrid.choice_list.push({ option: 'option1' })
    }

    multipleChoiceGrid.rows_list = [];
    for (var index = 0; index < form.rows_list.length; index++) {

      multipleChoiceGrid.rows_list.push({ option: form.rows_list[index].option });
    }

    if (form.rows_list.length == 0) {

      multipleChoiceGrid.rows_list.push({ option: 'row 1' })
    }

    multipleChoiceGrid.is_required = form.is_required;
    multipleChoiceGrid.is_deleted = form.is_deleted;
    multipleChoiceGrid.order_number = form.order_number;

    component.instance['baseForm'] = multipleChoiceGrid;
    component.instance['is_views'] = false;
    component.instance['updeted_behavior'] = this.$updeted_behavior;

    this.added_formList(multipleChoiceGrid);

    component.instance['outFormEvent'].subscribe(data => {

      this.switched_out_event(data);

    });
  }

  add_dropdownForm(form, component) {

    let dropdownForm = new DropdownForm();
    dropdownForm.form_no = form.form_no;
    dropdownForm.question = form.question;
    dropdownForm.choice_list = [];
    for (var index = 0; index < form.choice_list.length; index++) {

      dropdownForm.choice_list.push({ option: form.choice_list[index].option });
    }

    if (form.choice_list.length == 0) {

      dropdownForm.choice_list.push({ option: 'option1' })
    }
    dropdownForm.is_required = form.is_required;
    dropdownForm.is_deleted = form.is_deleted;
    dropdownForm.order_number = form.order_number;

    component.instance['baseForm'] = dropdownForm;
    component.instance['is_views'] = false;
    component.instance['updeted_behavior'] = this.$updeted_behavior;


    this.added_formList(dropdownForm);

    component.instance['outFormEvent'].subscribe(event => {

      this.switched_out_event(event);
    });
  }

  add_linkForm(form, component) {

    let linkForm = new LinkForm();
    linkForm.form_no = form.form_no;
    linkForm.title = form.title;
    linkForm.description = form.description;
    linkForm.is_deleted = form.is_deleted;
    linkForm.order_number = form.order_number;
    linkForm.link = form.link;

    component.instance['baseForm'] = linkForm;
    component.instance['is_views'] = false;
    component.instance['updeted_behavior'] = this.$updeted_behavior;

    this.added_formList(linkForm);

    component.instance['outFormEvent'].subscribe(event => {

      this.switched_out_event(event);
    })
  }

  async switched_out_event(event) {

    switch (event.action) {
      case 'selected_form': {

        this.selected_form(event);
        break;
      }
      case 'created_form': {

        this.created_form(event)
        break;
      }
      case 'updated_form': {

        this.updated_form(event);
        break;
      }
      case 'deleted_form': {

        this.deleted_form(event.form);
        break;
      }
      case 'moveup_form': {

        this.move_form(event.form, -1);
        break;
      }
      case 'movedown_form': {

        this.move_form(event.form, 1);
        break;
      }
      case 'punctuate_form': {

        this.punctuated_form(this.selected_formNo, event.value);
        break;
      }
      case 'copied_form': {

        let copied_form = event.form;
        this.copied_form(copied_form);
        break;
      }
      case 'changed_formType': {

        this.changed_formType(event.type);
        break;
      }
      // case 'answer': {

      // }
    }
  }


  selected_form(event) {

    this.selected_formNo = event.form.form_no;
    this.$updeted_behavior.next({ action: 'selected_formNo', formNo: this.selected_formNo });

  }

  created_form(event) {

    let baseForm = new BaseForm();
    let form_no = this.generate_uuid4();
    baseForm.form_no = form_no;
    baseForm.type = event.type;

    this.create_form(baseForm);

    let selected_index = this.form_list.findIndex(form => form.form_no == this.selected_formNo);
    let to_form = this.form_list[selected_index + 1].form_no;

    this.punctuated_form(form_no, to_form);

    this.changed_form();
  }

  updated_form(event) {

    let form = event.form;
    this.updated_formList(form);
    this.updated_json_assessments();

  }

  deleted_form(form) {

    let deleted_form = form;
    let deleted_index = this.form_list.findIndex(form => form.form_no == deleted_form.form_no);
    this.form_list[deleted_index].is_deleted = true;
    this.deleted_formList(deleted_form);
    this.deleted_component(deleted_index);
    this.updated_json_assessments();
    this.changed_form();
  }

  move_form(form, shift) {

    let from_index = this.form_list.findIndex(form => form.form_no == this.selected_formNo);
    let length = this.form_list.length;
    let to_index = this.validated_destination_index(from_index + shift, length);

    if (!(from_index == -1) && !(to_index == -1)) {

      let from_component = this.component_form_list[from_index];
      this.added_index_formList(from_index, to_index, this.form_list[from_index]);
      this.move_component(from_index, to_index, from_component);
    }

    // let form = event.form;
    // let from_index = this.form_list.findIndex(form => form.form_no == this.selected_formNo);
    // let length = this.form_list.length;
    // let to_index = this.validated_destination_index(shift, length);

    // if (!(from_index == -1) && !(to_index == -1)) {

    //   /* changed index of component*/
    //   this.added_index_formList(from_index, to_index, form);
    //   // this.deleted_formList(form);

    //   let from_component = this.component_form_list[from_index];

    //   this.move_component(from_index, to_index, from_component);

    // }
  }


  punctuated_form(from_formNo, to_formNo) {

    let from_index = this.form_list.findIndex(form => form.form_no == from_formNo);
    let to_index = this.form_list.findIndex(form => form.form_no == to_formNo);

    if (!(from_index == -1) && !(to_index == -1)) {

      let component = this.component_form_list[from_index];
      this.added_index_formList(from_index, to_index, this.form_list[from_index]);
      this.move_component(from_index, to_index, component);
      this.updated_json_assessments();
      this.changed_form();

    }
  }

  copied_form(copied_form) {

    let to_index = this.form_list.findIndex(forom => forom.form_no == copied_form.form_no);
    let from_formNo = this.generate_uuid4();


    let new_form = new BaseForm();
    new_form.form_no = from_formNo;
    new_form.type = copied_form.type;
    new_form.question = copied_form.question;
    new_form.is_other = copied_form.is_other;
    new_form.is_choice_other = copied_form.is_choice_other;
    new_form.choice_list = copied_form.choice_list;
    new_form.is_required = copied_form.is_required;
    new_form.is_deleted = copied_form.is_deleted;

    this.create_form(new_form);
    let to_fornNo = this.form_list[to_index + 1].form_no;

    this.punctuated_form(from_formNo, to_fornNo);

    this.changed_form();
  }

  changed_formType(type) {

    let index = this.form_list.findIndex(forom => forom.form_no == this.selected_formNo);

    let new_form = new BaseForm();
    let form_no = this.form_list[index].form_no;
    new_form.form_no = form_no;
    new_form.type = type;
    this.create_form(new_form);
    this.deleted_form(this.form_list[index]);

    this.punctuated_form(this.form_list[index].form_no, form_no);

    this.changed_form();
    this.updated_json_assessments();

  }

  updated_json_assessments() {

    if (this.assessment) {

      this.assessment.forms = JSON.stringify(this.form_list.filter(form => !form.is_deleted));

      this.assessmentService.updated_assessment(this.assessment).pipe(first())
        .subscribe({
          next: (response: any) => {

            if (response.serviceStatus == 'success') {

            }
            else {
              this.alertService.error(response['massage'], { autoClose: true });
            }
          },
          error: error => {

            this.alertService.error(error, { autoClose: true });
          }
        });
    }

  }

  navigated_views() {

    const queryParams: any = {};
    queryParams.assessments_no = this.assessment_no;
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['/assessment-views'], navigationExtras).then(() => {

    });

  }

  navigated_info() {

    const queryParams: any = {};
    queryParams.assessments_no = this.assessment_no;
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['/assessment-info'], navigationExtras).then(() => {

    });
  }

  changed_text(event) {

    let text_area = event.target;

    if (text_area) {

      text_area.style.overflow = 'hidden';
      text_area.style.height = '0px';
      text_area.style.height = text_area.scrollHeight + 'px';

      this.updated_json_assessments();

    }
  }

  private validated_destination_index(to_index, length) {

    let destination_index = to_index;
    if (destination_index > length) {

      destination_index = 0;
    }
    if (destination_index === -1) {

      destination_index = length - 1;
    }

    return destination_index;
  }


  private generate_uuid4() {
    return 'xxxxxxxx-xxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}