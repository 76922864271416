import { Component, OnInit,ViewChild } from '@angular/core';
import { ModalService } from '../helper/modal';
import { ElementService } from '../service/';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
    selector: 'app-children-rights',
    templateUrl: './children-rights.component.html',
    styleUrls: ['./children-rights.component.css']
  })
export class ChildrenRightsComponent implements OnInit {

    menuTopLeftPosition =  {x: '0', y: '0'}
    @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

    app_name = environment.app_name
    apiUrl:string =environment.apiUrl


    constructor(private modalService: ModalService,private elementService:ElementService) { }

    ngOnInit(): void {

        // this.reload()  
      }
    
   
}

