import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { selectLang } from '@environments/languageEnvironment';
import { ModalService } from '@app/helper/modal';
import { CalendarService } from '@app/service';
import { first } from 'rxjs/operators';
import { AlertService } from '@app/helper/alert';
import { Event } from '@app/model';
import { DatePipe } from '@angular/common'
import { DateTimeAdapter } from 'ng-pick-datetime';
import { EventStateEnum } from '@app/model/enum/EventStateEnum';
const DAY_MS = 60 * 60 * 24 * 1000;

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  dates: any[] = [];
  days = ['SUN', 'MON', 'TUES', 'WED', 'THURS', 'FRI', 'SATUR'];
  date = new Date();
  event_list: any[] = []
  add_event_obj: Event = new Event()
  is_add_event: boolean = false;
  selected_event_list: any[] = []
  event_date = new Date();
  is_event_update:boolean = false
  @Output() selected = new EventEmitter();
  selectLang = selectLang;
  EventStateEnum = EventStateEnum
  user = JSON.parse(localStorage.getItem('currentUser'))
  lang: string = localStorage.getItem('lang')
  state = EventStateEnum.list

  constructor(private modalService: ModalService,
    private datepipe: DatePipe,
    private calendarService: CalendarService,
    private alertService: AlertService,
    private dateTimeAdapter: DateTimeAdapter<any>) {

    this.dateTimeAdapter.setLocale('th-TH');
    this.getAllEventCalendar()

  }

  ngOnInit() {

    this.getAllEventCalendar()
    // this.getAllEventCalendar()
    // this.dates = this.getCalendarDays(this.date);
  }

  getAllEventCalendar() {
    this.calendarService.get_EventList()
      .pipe(first())
      .subscribe(
        response => {
          if (response['serviceStatus'] == 'success') {
            this.event_list = response['data_list'];
            this.dates = this.getCalendarDays(this.date);
            // this.dates = this.getCalendarDays(this.date);
          }
          else {

            this.alertService.error(response['massage'])
          }
        });
  }

  setMonth(inc) {
    const [year, month] = [this.date.getFullYear(), this.date.getMonth()];
    this.date = new Date(year, month + inc, 1);
    this.dates = this.getCalendarDays(this.date);
  }

  isSameMonth(date) {
    let t = new Date()
    return date.return_date.getMonth() === this.date.getMonth();
  }

  private getCalendarDays(date = new Date) {
    // this.getAllEventCalendar()
    const calendarStartTime = this.getCalendarStartDay(date).getTime();
    return this.range(0, 41)
      .map(num => {

        let date_return = { events: [], return_date: new Date() }
        let map_date = new Date(calendarStartTime + DAY_MS * num)
        let plus_map_date = new Date(calendarStartTime + DAY_MS * (num + 1))
        date_return.events = this.event_list.filter(event => this.dateRangeOverlaps(new Date(event.start_date.toString()), new Date(event.end_date.toString()), map_date, plus_map_date))
        date_return.return_date = map_date;

        return date_return
      }
      );
  }
  private dateRangeOverlaps(a_start, a_end, b_start, b_end) {
    if (a_start < b_start && b_start < a_end) return true; // b starts in a
    if (a_start < b_end && b_end < a_end) return true; // b ends in a
    if (b_start < a_start && a_end < b_end) return true; // a in b
    return false;
  }
  private getCalendarStartDay(date = new Date) {
    const [year, month] = [date.getFullYear(), date.getMonth()];
    const firstDayOfMonth = new Date(year, month, 1).getTime();

    return this.range(1, 7)
      .map(num => new Date(firstDayOfMonth - DAY_MS * num)
      )
      .find(dt => dt.getDay() === 0)
  }

  private range(start, end, length = end - start + 1) {
    return Array.from({ length },
      (_, i) => start + i)
  }
  week_range(start, end) {

    return this.dates.slice(start, end);
  }
  click_open_event() {

    this.add_event_obj = new Event()
    this.is_add_event = !this.is_add_event;
  }
  add_event() {

    if (this.add_event_obj.summary) {

      this.calendarService.add_event(this.add_event_obj)
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.is_add_event = false;
              let data = response['data']
              let event = new Event()
              event.id = data.id;
              event.summary = data.summary;
              event.description = data.description;
              event.location = data.location;
              event.start_date = new Date(data.start_date)
              event.end_date = new Date(data.end_date)
              this.event_list.push(event)
              this.dates = this.getCalendarDays(this.date);

              var plus_event_date = new Date(this.event_date['return_date'].getTime() + DAY_MS)
              if(this.dateRangeOverlaps(event.start_date, event.end_date, this.event_date['return_date'], plus_event_date)){
                this.selected_event_list.push(event)
              }

              this.add_event_obj = new Event()

              this.alertService.success(response['massage'],{autoClose:true})
            }
            else {

              this.alertService.error(response['massage'],{autoClose:true})
            }
          });
    }
  }
  click_event(date) {

    this.event_date = date;
    this.selected_event_list = date.events
    this.modalService.open('calendar-event')

  }
  click_edited_event(event:Event){

    event['is_event_update'] = true;
    this.state = EventStateEnum.update

  }
  click_close_edited_event(event:Event){

    this.state = EventStateEnum.list
    event['is_event_update'] = false;
  }
  close_event(){

    this.event_date = null;
    this.modalService.close('calendar-event')
  }

  update_event(event:Event) {

    if (event.summary) {

      this.calendarService.update_event(event)
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.is_add_event = false;
              this.dates = this.getCalendarDays(this.date);
              let data = response['data']
              // let event = new Event()
              event.id = data.id;
              event.summary = data.summary;
              event.description = data.description;
              event.location = data.location;
              event.start_date = new Date(data.start_date)
              event.end_date = new Date(data.end_date)
              event['is_event_update'] = false;
              var index = this.event_list.findIndex(e => e.id === event.id )
              this.event_list[index] = event;
              this.dates = this.getCalendarDays(this.date);

              this.state = EventStateEnum.list;

              this.alertService.success(response['massage'],{autoClose:true})
            }
            else {

              this.alertService.error(response['massage'],{autoClose:true})
            }
          });
    }
  }
  delete_event(event:Event) {

    this.calendarService.delete_event(event)
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.is_add_event = false;
              let data = response['data']
              let event = new Event()
              event.id = data.id
              event.summary = data.summary;
              event.description = data.description;
              event.location = data.location;
              event.start_date = new Date(data.start_date)
              event.end_date = new Date(data.end_date)
              event['is_event_update'] = false
              this.event_list = this.event_list.filter(e => e.id !== event.id);
              this.dates = this.getCalendarDays(this.date);


              var plus_event_date = new Date(this.event_date['return_date'].getTime() + DAY_MS)
              if(this.dateRangeOverlaps(event.start_date, event.end_date, this.event_date['return_date'], plus_event_date)){
                this.selected_event_list.splice(this.selected_event_list.findIndex(e => e['id'] == event.id), 1)
              }

              this.alertService.success(response['massage'],{autoClose:true})
            }
            else {

              this.alertService.error(response['massage'],{autoClose:true})
            }
          });

  }

  get_short_month() {

    return this.datepipe.transform(this.date, 'MMM').toUpperCase()
  }
}
