import { Component, Input, AfterViewInit, ViewEncapsulation, ViewChild, HostListener, ElementRef } from '@angular/core';
import { FileContainerDirective } from '@app/element/directive/file-container/file-container.directive';
import { ElementService } from '@app/service/';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatMenu } from '@angular/material/menu';
import { ModalService } from '@app/helper/modal';
import { selectLang } from '@environments/languageEnvironment';

@Component({
  selector: 'course-right-click',
  templateUrl: './course-right-click.component.html',
  styleUrls: ['./course-right-click.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CourseRightClickComponent implements AfterViewInit{

  @Input() name: string;
  @Input() type: string;

  public menuTopLeftPosition = { x: '0', y: '0' }
  file: any;
  openEditor:boolean = false;
  selectLang = selectLang;
  lang: string;

  @ViewChild('rightClick', { static: true })  rightClickElement: ElementRef;

  constructor(private modalService: ModalService,
    private elementService: ElementService){

      this.lang = localStorage.getItem('lang');
  }


  ngAfterViewInit() {

    this.rightClickElement.nativeElement.style.top = this.menuTopLeftPosition.y;
    this.rightClickElement.nativeElement.style.left = this.menuTopLeftPosition.x;
    console.log('test')


  }

  onRightClick() {

    this.openEditor = true;
    this.modalService.open(this.name)


  }

  public close_right_click(){

    if(this.rightClickElement){

      this.rightClickElement.nativeElement.style.display = "none";
    }
  }
}
