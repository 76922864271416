import { Injectable, OnInit } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient, HttpRequest, HttpParams, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ElementFiles, Course, Lesson, LessonDetail } from '@app/model';

@Injectable({ providedIn: 'root' })
export class CourseService {

  constructor(private http: HttpClient) {

  }

  

  get_CourseList() {

    return this.http.get(`${environment.apiUrl}/api/course/course_list`)
      .pipe(map(Course => {

        return Course;

      }));;
  }

  save_Course(course: Course, coverImage: any,image:any) {

    const formData: FormData = new FormData();
    formData.append('coverImage', coverImage);
    formData.append('image', image);
    formData.append('course', JSON.stringify(course));
    formData.append('lang', localStorage.getItem('lang'));

    return this.http.post<any>(`${environment.apiUrl}/api/course/save_course`,
      formData)
      .pipe(map(course => {

        return course;

      }));
  }

  edited_course(course: Course){

    const formData: FormData = new FormData();
    formData.append('course', JSON.stringify(course));
    formData.append('lang', localStorage.getItem('lang'));

    return this.http.post<any>(`${environment.apiUrl}/api/course/edited_course`,
      formData)
      .pipe(map(course => {

        return course;

      }));
  }

  update_Course(course: Course) {
    const formData: FormData = new FormData();
    formData.append('course', JSON.stringify(course));
    formData.append('lang', localStorage.getItem('lang'));

    return this.http.post<any>(`${environment.apiUrl}/api/course/update_course`,
      formData)
      .pipe(map(course => {

        return course;

      }));
  }

  delete_course(course: Course) {

    return this.http.post<any>(`${environment.apiUrl}/api/course/delete_course`,
      { course: course })
      .pipe(map(course => {

        return course;

      }));
  }

  get_course(course_no: string) {

    return this.http.post<any>(`${environment.apiUrl}/api/course/get_course`,
      { course_no: course_no })
      .pipe(map(course => {

        return course;

      }));
  }

  enrolled_course(course: Course) {

    return this.http.post<any>(`${environment.apiUrl}/api/course/enrolled_course`,
      { course: course })
      .pipe(map(course => {

        return course;

      }));

  }

  save_lesson(lesson: Lesson) {

    return this.http.post<any>(`${environment.apiUrl}/api/course/save_lesson`,
      { lesson: lesson })
      .pipe(map(lesson => {

        return lesson;

      }));
  }

  save_lessonDetail(lessonDetail: LessonDetail, file: File): Observable<HttpEvent<any>> {

    const formData: FormData = new FormData();
    formData.append('lessonDetail', JSON.stringify(lessonDetail));
    formData.append('file', file);
    formData.append('lang', localStorage.getItem('lang'));

    const request = new HttpRequest('POST', `${environment.apiUrl}/api/course/save_lessonDetail`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(request);
  }
}
