import { Component, OnInit, AfterViewInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { LinkForm } from '@app/assessment/question-form/model';
import { FormStateEnum } from '@app/assessment/question-form/model/enum/FormStateEnum';
import { BaseFormComponent } from '@app/assessment/question-form/base-form/baseform.component';
import { selectLang } from '@environments/languageEnvironment';
import { Observable } from 'rxjs';

@Component({
    selector: 'link-form',
    templateUrl: './link-form.component.html',
    styleUrls: ['./link-form.component.css']
})

export class LinkComponent extends BaseFormComponent implements OnInit {

    baseForm: LinkForm;
    other_text: string = "";
    other_choice_index: number;
    is_other: boolean = false;
    answer: string = null;

    ngOnInit() {

        super.ngOnInit();
    }

    created_validation() {

        this.erorr_message = ''

        if (!this.isValidURL(this.baseForm.link)){

            this.erorr_message = 'กรุณากรอกในรูปแบบ URL'
        }
    }


    link(){
    
        window.location.href = this.baseForm.link;
    }

    isValidURL(url) {

        var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
      };

    
}