import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { JWModalModule } from '@app/helper/modal';
import { ElementComponent } from '@app/element/element.component';
import { EditorComponent } from '@app/helper/editor/editor.component';
import { VideoEditorComponent } from '@app/helper/editor/video-editor/video-editor.component';
import { FileEditorComponent } from '@app/helper/editor/file-editor/file-editor.component';
import { FileElementDirective } from '@app/element/directive/file-element/file-element.directive';
import { MenuBarEditor } from '@app//helper/editor/menu-bar-editor/menu-bar-editor.component';
import { ImageCropperContentComponent } from '@app/helper/editor/image-cropper-content/image-cropper-content.component';
import { ImageCroppperComponent } from '@app/helper/imageCropper/image-cropper.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { AnimateModule } from '@app/helper/animate/animate.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RightClickComponent } from '@app/element/directive/right-click/right-click.component';
import { TextViewElementDirective } from '@app/element/directive/text-view-element/text-view-element';
import { VideoAutoplayDirective } from '@app/element/directive/video-auto/videoAutoplay';
import { EllipsisDirective } from '@app/directive/ellipsis/ellipsis.directive';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { SafePipe } from '@app/helper/filter/safe/safe.pipe';
import { AlertModule } from '@app/helper/alert';

@NgModule({
  imports: [CommonModule, BrowserModule, FormsModule, MatMenuModule, JWModalModule, AngularCropperjsModule, AnimateModule, AngularEditorModule,NgxYoutubePlayerModule.forRoot(),AlertModule],
  declarations: [SafePipe,ElementComponent, EditorComponent, MenuBarEditor, ImageCropperContentComponent, ImageCroppperComponent, VideoEditorComponent, FileEditorComponent, RightClickComponent, FileElementDirective, TextViewElementDirective, VideoAutoplayDirective,EllipsisDirective],
  // entryComponents: [  ],
  exports: [ElementComponent, EditorComponent, MenuBarEditor, ImageCropperContentComponent, ImageCroppperComponent, VideoEditorComponent, FileEditorComponent, RightClickComponent, FileElementDirective, TextViewElementDirective]
})
export class ElementModule { }
