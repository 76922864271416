import { Component, Input, AfterViewInit, ViewEncapsulation, ViewChild, HostListener, ElementRef } from '@angular/core';
import { FileContainerDirective } from '@app/element/directive/file-container/file-container.directive';
import { ElementService } from '@app/service/';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatMenu } from '@angular/material/menu';
import { ModalService } from '@app/helper/modal';

@Component({
  selector: 'right-click',
  templateUrl: './right-click.component.html',
  styleUrls: ['./right-click.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class RightClickComponent implements AfterViewInit{

  @Input() name: string;
  @Input() type: string;
  @Input() file_element_edited:any;
  public menuTopLeftPosition = { x: '0', y: '0' }
  file: any;
  openEditor:boolean = false;

  @ViewChild('rightClick', { static: true })  rightClickElement: ElementRef;

  constructor(private modalService: ModalService,
    private elementService: ElementService){

  }


  ngAfterViewInit() {

    this.rightClickElement.nativeElement.style.top = this.menuTopLeftPosition.y;
    this.rightClickElement.nativeElement.style.left = this.menuTopLeftPosition.x;
    console.log('test')


  }

  onRightClick() {

    this.openEditor = true;
    this.modalService.open(this.name)
    // this.file_element_edited = { name: this.name,  };
    // this.modalService.open(this.name);
    // this.get_elementFile(this.name).subscribe((data) => {

    //   console.log(data)
    //   this.file_element_edited = { name: this.name, file: data };

    // });
    // this.getElementImage(this.name).subscribe((data) => {

      // this.image = data;
      // this.image.styles = JSON.parse(this.image.styles)
      // if (!this.image.styles) {

      //   this.image.styles = { hover: '', wmAnimate: '', filter: '' }

      // }

      // this.image_element_edited = { name: this.name, image: this.image };
      // this.openEditor = true;
      // this.modalService.open(this.name)
      // console.log(this.image_element_edited)
    // });


  }

  public close_right_click(){

    if(this.rightClickElement){

      this.rightClickElement.nativeElement.style.display = "none";
    }
  }


  get_elementFile(element_name): Observable<string> {

    var subject = new Subject<string>();
    this.elementService.get_elementFile(element_name).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus = 'success') {

            subject.next(response.data);

          }
          else {
            subject.next(null);
          }

        },
        error: error => {
        }
      });

    return subject.asObservable();
  }

}
