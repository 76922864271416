import { Component, OnInit,AfterViewInit,ViewEncapsulation  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { CarouselService } from '@app/helper/carousel/carousel.service';
// import { Subscription } from 'rxjs';
import { SocialAuthService ,FacebookLoginProvider, SocialUser } from 'angularx-social-login';
import { AuthenticationService } from '@app/service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  select :string = localStorage.getItem('menu')

  app_name = environment.app_name

  public news:Array<object> ;
  public stories:Array<object> ;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private socialAuthService: SocialAuthService
    ) {

     
     }

  ngOnInit(): void {
    // this.activatedRoute.queryParams.subscribe(params => {
    // });

    // this.socialAuthService.authState.subscribe((user) => {

    //     console.log(user)
    //     // this.user = user;
    //     // this.loggedIn = (user != null);
    //   });

//     this.news = [
//       {picturn: 'image-1.jfif', topic: 'นิเทศติดตามการสอน',detail:'มูลนิธิภาษาศาสตร์ประยุกต์ติดตามการสอน'},
//       {picturn: 'image-3.jfif', topic: 'ผลการประเมินผู้เรียน',detail:'มูลนิธิภาษาศาสตร์ประยุกต์ติดตามการสอน'},
//       {picturn: 'image-4.jfif', topic: 'ติดตามห้องเรียน',detail:'มูลนิธิภาษาศาสตร์ประยุกต์ติดตามการสอน'},
//       {picturn: 'image-1.jfif', topic: 'อมรมและพัฒนาครู',detail:'มูลนิธิภาษาศาสตร์ประยุกต์ติดตามการสอน'},
//   ];
//   this.stories = [
//     {picturn: 'image-1.jfif', topic: 'เด็กร้องขอการบ้าน',detail:'มูลนิธิภาษาศาสตร์ประยุกต์ติดตามการสอน'},
//     {picturn: 'image-4.jfif', topic: 'ทำงานกลุ่ม',detail:'มูลนิธิภาษาศาสตร์ประยุกต์ติดตามการสอน'},
//     {picturn: 'image-3.jfif', topic: 'เพื่อนช่วยเพื่อน',detail:'มูลนิธิภาษาศาสตร์ประยุกต์ติดตามการสอน'},
//     {picturn: 'image-1.jfif', topic: 'วิ่งพาหนูสู้ฝัน',detail:'มูลนิธิภาษาศาสตร์ประยุกต์ติดตามการสอน'}
// ];


}



}