
import {Deserializable} from "./deserializable.model";
import { UserRole } from "./UserRole";

export class UserType implements Deserializable{

  userType_id:number;
  user_type:string;
  user_role =UserRole
  is_actived:boolean;
  created_date:Date;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
