import { throwMatDuplicatedDrawerError } from "@angular/material/sidenav";
import { UserRole } from "./UserRole";
import {EndPoint} from "./EndPoint"
import {UserType} from "./UserType"

export class User {

    user_key:string;
    name_title:string;
    id_citizen:string;
    email: string;
    tel:string = '';
    user_role:UserRole;
    password: string;
    comfirm_password: string;
    role_id:number;
    first_name:string;
    last_name:string;
    image_url:string;
    provider:string;
    user_type:UserType;
    endpoint_list:EndPoint[]
    is_actived:boolean =true
    is_first:boolean= false;
    created_date: Date;
    is_deleted:boolean = false;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
}
