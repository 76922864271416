import { Component, OnInit, ViewChild, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { ModalService } from '@app/helper/modal';
import { Partner, User, ElementText, ElementImage } from '@app/model';
import { ElementService, PartnerService } from '@app/service/';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { ImageUploadEnum } from '@app/model/enum/ImageUpload';
import { AlertService } from '@app/helper/alert';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { CropperComponent, ImageCropperResult } from 'angular-cropperjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TokenStorageService } from '@app/service';


@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PartnerComponent implements OnInit {

  app_name = environment.app_name;
  apiUrl: string = environment.apiUrl;
  addPartner: Partner;

  public ImageUploadEnum = ImageUploadEnum;
  public imageState = ImageUploadEnum.view;

  @ViewChild('angularCropper') public angularCropper: CropperComponent;

  imagePartner: any;
  imageResult: any;

  partnerList: Partner[] = [];

  is_delete_partner = false;

  user:User = this.tokenService.getUser()

  config = {
    aspectRatio: 1 / 1,
    dragMode: 'move',
    background: true,
    movable: true,
    rotatable: true,
    scalable: false,
    zoomable: true,
    viewMode: 1,
    checkImageOrigin: true,
    checkCrossOrigin: true
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'รายละเอียด',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'KANIT',
    sanitize: false,
    toolbarHiddenButtons: [
      ['bold']
    ],
    fonts: [
      { class: 'arial', name: 'ARIAL' },
      { class: 'times-new-roman', name: 'TIMES NEW ROMAN' },
      { class: 'calibri', name: 'CALIBRI' },
      { class: 'KANIT', name: 'KANIT' },
      { class: 'IANNNNNDOG-REQULAR', name: 'IANNNNNDOG REQULAR' },
      { class: 'THSARABUN', name: 'THSARABUN' },
      { class: 'LYFNARAK', name: 'LYFNARAK' },
      { class: 'SOVKLORNG', name: 'SOVKLORNG' },
      { class: 'MMNIRATPARIS', name: 'MMNIRATPARIS' },
      { class: 'SOVBAAN', name: 'SOVBAAN' },
      { class: 'TALAMDUAN', name: 'TALAMDUAN' },
      { class: 'TAMAGAZINE', name: 'TAMAGAZINE' },
      { class: 'SOV3DSLIM', name: 'SOV3DSLIM' },
      { class: 'FCOUTLINECOLOR', name: 'FCOUTLINECOLOR' },
      { class: 'SOVJDKRANOK', name: 'SOVJDKRANOK' },
      { class: 'NOODLEDEMO', name: 'NOODLEDEMO' },
      { class: 'SOVSAMCHUK', name: 'SOVSAMCHUK' },
      { class: 'WHALE', name: 'WHALE' },
      { class: 'AKKHARAKT', name: 'AKKHARAKT' },
      { class: 'SPLAISAN', name: 'SPLAISAN' },
      { class: 'SOVRATAPHAN', name: 'SOVRATAPHAN' },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  menuTopLeftPosition = { x: '0', y: '0' }
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  constructor(private alertService: AlertService
    , private elementService: ElementService, @Inject(PLATFORM_ID) private platformId: any,
    private modalService: ModalService, private sanitizer: DomSanitizer,
    private partnerService: PartnerService,private tokenService: TokenStorageService,) { }

  ngOnInit(): void {

    this.reload()
  }

  reload() {

    if (isPlatformBrowser(this.platformId)) {

      this.addPartner = new Partner()
      this.addPartner.topic = new ElementText()
      this.addPartner.image = new ElementImage()
      this.imagePartner = this.apiUrl + '/media/default_profile/no-image.png';

      this.is_delete_partner = false;
      this.partner_list()
    }
  }

  partner_list() {

    if (isPlatformBrowser(this.platformId)) {
      this.partnerService.get_partner_list()
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.partnerList = response['data_list'];
            }
            else {
              this.alertService.error(response['massage'])
            }
          });
    }
  }

  onRightClick(event: MouseEvent) {

    var target = event.target
      if (target && target['classList']) {

        let isCanRightClick = (target['id'] == 'partner')
        if (isCanRightClick) {

          event.preventDefault();
          this.menuTopLeftPosition.x = event.clientX + 'px';
          this.menuTopLeftPosition.y = event.clientY + 'px';
          this.matMenuTrigger.openMenu()
        }
      }
  }

  openAddModal() {

    if (isPlatformBrowser(this.platformId)) {

      this.modalService.open('add-partner');
    }
  }

  changedImagePartner(event: any): void {

    if (isPlatformBrowser(this.platformId)) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: any) => {

          this.imagePartner = event.target.result;
          this.imageState = ImageUploadEnum.Cropper;;

        }
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  setImageCropperStatusCropper(image_result) {

    if (isPlatformBrowser(this.platformId)) {
      this.imagePartner = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.imageState = ImageUploadEnum.view;
    }
  }

  checkstatus(event: any) {

    if (isPlatformBrowser(this.platformId)) {
      if (event.blob === undefined) {
        return;
      }

      let urlCreator = window.URL;
      this.imageResult = this.sanitizer.bypassSecurityTrustUrl(
        urlCreator.createObjectURL(new Blob(event.blob)));
    }
  }

  CropCoverImage() {

    if (isPlatformBrowser(this.platformId)) {
      this.angularCropper.exportCanvas();
    }
  }

  save_partner() {

    if (isPlatformBrowser(this.platformId)) {
      this.partnerService.save_partner(this.addPartner, this.dataURItoBlob(this.imagePartner))
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.closeAddModal()
              this.alertService.success(response['massage'])
            }
            else {
              this.alertService.error(response['massage'])
            }
          });
    }
  }

  closeAddModal() {

    if (isPlatformBrowser(this.platformId)) {

      this.reload()
      this.modalService.close('add-partner')
    }
  }

  openDeletedPartner(){

    this.is_delete_partner = true;
  }

  cancelDeleteModal() {
    this.is_delete_partner = false;
  }

  delete_partner(partner) {

    if (isPlatformBrowser(this.platformId)) {

      this.partnerService.delete_partner(partner)
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.reload()

              this.alertService.success(response['massage'])
            }
            else {
              this.alertService.error(response['massage'])
            }
          });
    }

  }
  private dataURItoBlob(dataURI) {

    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);

    for (var i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], { type: mimeString });
    return blob;
  }
}
