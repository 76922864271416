import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { LoginService } from '@app/login/login.service';
import { AuthenticationService, SystemInfoService } from './service';
import { first } from 'rxjs/operators';
import { User, SystemInfo } from './model';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    currentUser: User;
    title = 'ang-material-owneraccount';
    systemInfo: SystemInfo;
    @ViewChild('content') content: ElementRef;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private loginService: LoginService,
        private systemInfoService: SystemInfoService,

    ) {

        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'TH');
        }

        

    }

    newsPageLocalStorage() {

        let newsPage = localStorage.getItem('news-page')
        if (!newsPage) {

            localStorage.setItem('news-page', '1');

            return localStorage.getItem('news-page')
        }

        return newsPage;
    }
    storyPageLocalStorage() {

        let storyPage = localStorage.getItem('story-page');
        if (!storyPage) {

            localStorage.setItem('story-page', '1');

            return localStorage.getItem('story-page');
        }

        return storyPage;
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    ngOnInit() {

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });

        this.newsPageLocalStorage();
        this.storyPageLocalStorage();
    
    }
}
