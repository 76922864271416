
import {Deserializable} from "./deserializable.model";
import {ElementText} from "./ElementText";
import {ElementImage} from "./ElementImage";
import {User} from "./User"
export class News implements Deserializable{

    news_id:number;
    cover_image:ElementImage;
    topic:ElementText;
    // title:ElementText;
    views:number;
    detail:ElementText;
    news_type:number;
    updated_by:User;
    updated_date:Date;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



