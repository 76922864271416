import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseForm } from '@app/assessment/question-form/model';
import { FormStateEnum } from '@app/assessment/question-form/model/enum/FormStateEnum';
import { Observable } from 'rxjs';
import { WindowRefService } from '@app/service';

@Component({
    selector: 'formbase',
    templateUrl: './baseform.component.html',
    styleUrls: ['./baseform.component.css']
})
export class BaseFormComponent  implements OnInit {

    @Input() public updeted_behavior: Observable<any>;
    @Input() public baseForm: BaseForm;
    @Input() public selected_formNo: string;
    @Input() is_views: boolean;
    public answer_list: string[] = [];
    public erorr_message: string = null;
    public FormStateEnum = FormStateEnum;
    public state = FormStateEnum.detail;

    @Output() outFormEvent: EventEmitter<{}> = new EventEmitter<{}>();
    @Output() outAnswer: EventEmitter<{}> = new EventEmitter<{}>();

    constructor(public windowRefService: WindowRefService){
        
    }

    ngOnInit() {

        if (this.updeted_behavior) {
            this.updeted_behavior.subscribe(data => {

                if (data.action == 'changed_form') {

                    let form_list = data.form_list;
                    let index = form_list.findIndex(e => e.form_no == this.baseForm.form_no);
                    if (!(index == -1)) {
                        this.baseForm.order_number = index;
                        this.out_form_event();
                    }
                }
                else if (data.action == 'selected_formNo') {

                    this.selected_formNo = data.formNo;
                }
            });
        }
    }

    public validation() {

        this.erorr_message = '';

        if (this.baseForm.is_required && !this.answer_list || this.answer_list.length <= 0) {

            this.state = FormStateEnum.validation;
            return false;
        }

        return true;
    }

    public changed_answer() {

        if (this.validation()) {

            this.out_answer(false);
        }
        else {

            this.out_answer(false);
        }

    }

    public changed_requirement() {

        this.outFormEvent.emit({ action: 'updated_form', form: this.baseForm })
    }

    public copied_form() {

        this.outFormEvent.emit({ action: 'copied_form', form: this.baseForm });
    }

    public changed_formType(type) {

        this.outFormEvent.emit({ action: 'changed_formType', form: this.baseForm, type: type });
    }

    public deleted_form() {

        this.outFormEvent.emit({ action: 'deleted_form', form: this.baseForm });

    }

    public out_form_event() {

        this.outFormEvent.emit({ action: 'updated_form', form: this.baseForm });
    }

    public selected_form() {

        this.outFormEvent.emit({ action: 'selected_form', form: this.baseForm })
    }

    public move_up() {

        this.outFormEvent.emit({ action: 'moveup_form', form: this.baseForm });
    }

    public move_down() {

        this.outFormEvent.emit({ action: 'movedown_form', form: this.baseForm });
    }

    public punctuate(data) {

        this.outFormEvent.emit({ action: 'punctuate_form', form: this.baseForm, value: data.value });
    }

    created_form(type) {

        this.outFormEvent.emit({ action: 'created_form', type: type });
    }

    public out_punctuate_event(data) {

        if (data.action == 'drag' && this.baseForm.form_no == data.value) {

            this.selected_form();
        }
        else if (data.action == 'drop') {

            this.punctuate(data);
        }
    }

    public changed_text(event) {

        let text_area = event.target;

        if (text_area) {

            text_area.style.overflow = 'hidden';
            text_area.style.height = '0px';
            text_area.style.height = text_area.scrollHeight + 'px';

            this.out_form_event();
        }
    }

    public out_answer(is_error){

        this.outAnswer.emit({ form_no: this.baseForm.form_no, value: this.answer_list, is_error: is_error })
    }
   
}