import { Component, Input, Output, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { CheckBoxForm } from '@app/assessment/question-form/model'
import { FormStateEnum } from '@app/assessment/question-form/model/enum/FormStateEnum'
import { selectLang } from '@environments/languageEnvironment';
import { Observable } from 'rxjs';
import { BaseFormComponent } from '@app/assessment/question-form/base-form/baseform.component';

@Component({
  selector: 'check-form',
  templateUrl: './checkbox-form.component.html',
  styleUrls: ['./checkbox-form.component.css']
})

export class CheckBokComponent extends BaseFormComponent implements OnInit {

  baseForm: CheckBoxForm;
  other_text: string = "";
  other_choice_index: number;
  is_other: boolean = false;

  ngOnInit() {
    /* extends method from BaseFormComponent */
    super.ngOnInit();

  }

  added_option() {

    let index = this.baseForm.choice_list.length;
    this.baseForm.choice_list.push({ option: 'option' + (index + 1) });
    this.out_form_event();
  }
  deleted_option(index) {

    this.baseForm.choice_list.splice(index, 1);
    this.out_form_event();
  }

  changed_option() {

    this.out_form_event();
  }


  changed_otherChoice() {

    this.baseForm.is_choice_other = !this.baseForm.is_choice_other;
    this.out_form_event();
  }

  changed_textOther() {

    this.answer_list[this.other_choice_index] = this.other_text;
    this.out_answer(false);
  }

  changed_is_other(){

    this.is_other = !this.is_other;
    this.other_text = '';

    if (this.is_other) {

      this.answer_list.push(this.other_text)
      this.other_choice_index = this.answer_list.length - 1;
      this.changed_answer();
    }
    else {

      this.answer_list.splice(this.other_choice_index, 1);
      this.changed_answer();
    }
  }

  changed_radio(choice) {

    var index = this.answer_list.findIndex(answer => answer == choice)

    if (!(index == -1)) {

      this.answer_list.splice(index, 1);
      this.changed_answer();
    }
    else {

      this.answer_list.push(choice);
      this.changed_answer();
    }
  }
}
