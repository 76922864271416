import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ElementFiles, ElementImage, ElementText, ElementVideo, Media } from '@app/model';
import { AbstractService } from './abstract.service';
import { BehaviorSubject } from "rxjs/BehaviorSubject"

@Injectable({ providedIn: 'root' })
export class ElementService extends AbstractService {

  headers: any
  authToken: string = '';
  username: string = '';

  private $element = new BehaviorSubject<any>(null);
  constructor(
    private router: Router,
    http: HttpClient
  ) {

    super(http)
  }

  get_elementImage(item_name: any) {

    return this.http.post(`${environment.apiUrl}/api/element/get_image`,
      {
        item_name: item_name
      }
    )
      .pipe(map(Image => {

        return Image;

      }));;
  }

  get_elementAllImage(link: any) {

    return this.http.post(`${environment.apiUrl}/api/element/get_all_image_linked`,
      {
        link: link
      }
    )
      .pipe(map(elementImage => {

        return new ElementImage().deserialize(elementImage);

      }));
  }

  edited_image(image_file: any, image: any) {

    const formData = new FormData();
    formData.append('file', image_file);
    formData.append('image', JSON.stringify(image));
    formData.append('lang', localStorage.getItem('lang'));

    return this.http.post(`${environment.apiUrl}/api/element/edited_image`,
      formData
      ,
      {
        headers: this.headers
      }
    )
      .pipe(map(elementImage => {

        return new ElementImage().deserialize(elementImage);

      }));;
  }

  get_elementText(item_name: any) {

    return this.http.post(`${environment.apiUrl}/api/element/get_text`,
      {
        item_name: item_name,
        lang: localStorage.getItem('lang')
      }
    )
      .pipe(map(elementText => {

        return new ElementText().deserialize(elementText);

      }));
  }

  get_elementAllText(link: any) {

    return this.http.post(`${environment.apiUrl}/api/element/get_all_text_linked`,
      {
        link: link,
        lang: localStorage.getItem('lang')
      }
    )
      .pipe(map(elementText => {

        return new ElementText().deserialize(elementText);

      }));
  }

  edited_text(elementText: ElementText) {
    return this.http.post(`${environment.apiUrl}/api/element/edited_text`,
      elementText
    )
      .pipe(map(elementText => {

        return new ElementText().deserialize(elementText);

      }));;
  }

  save_file(elementText: ElementText) {
    return this.http.post(`${environment.apiUrl}/api/element/edited_text`,
      elementText
    )
      .pipe(map(elementText => {

        return new ElementText().deserialize(elementText);

      }));;
  }

  get_elementVideo(item_name: any) {

    return this.http.post(`${environment.apiUrl}/api/element/get_video`,
      {
        item_name: item_name
      }
    )
      .pipe(map(elementText => {

        return new ElementText().deserialize(elementText);

      }));
  }

  edited_video(elementVideo: ElementVideo, video: any) {

    const formData = new FormData();
    formData.append('videoFile', video);
    formData.append('video', JSON.stringify(elementVideo));
    return this.http.post(`${environment.apiUrl}/api/element/edited_video`,
      formData
      ,
      {
        headers: this.headers
      }
    )
      .pipe(map(elementVideo => {

        return new ElementVideo().deserialize(elementVideo);

      }));;
  }

  edited_file(elementFile: ElementFiles, file: any) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('elementFile', JSON.stringify(elementFile));
    formData.append('lang', localStorage.getItem('lang'));
    
    return this.http.post(`${environment.apiUrl}/api/element/edited_file`,
      formData
      ,
      {
        headers: this.headers
      }
    )
      .pipe(map(ElementFile => {

        return new ElementFiles().deserialize(ElementFile);

      }));;
  }

  get_elementFile(item_name: any) {

    return this.http.post(`${environment.apiUrl}/api/element/get_file`,
      {
        item_name: item_name
      }
    )
      .pipe(map(elementFile => {

        return new ElementFiles().deserialize(elementFile);

      }));
  }



  uploadImage(image_file: any, quality: any) {
    const formData = new FormData();
    formData.append('file', image_file);
    formData.append('quality', quality);
    return this.http.post(`${environment.apiUrl}/api/element/upload_image`,
      formData
    ).pipe(map(response => {

      return response;

    }));;

  }

  uploadResizeImage(width: any, height: any, file: any) {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('width', width);
    formData.append('height', height);
    return this.http.post(`${environment.apiUrl}/api/element/upload_resize_image`,
      formData
    ).pipe(map(response => {

      return response;

    }));;

  }

  get_media_list() {

    return this.http.post(`${environment.apiUrl}/api/element/get_media_list`,
      {

      }
    )
      .pipe(map(media => {

        return new Media().deserialize(media);

      }));
  }


  upload_media(media: any, file: any) {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('media', JSON.stringify(media));

    return this.http.post(`${environment.apiUrl}/api/element/upload_media`,
      formData
      ,
      {
        reportProgress: true,
        observe: "events",
        headers: this.headers
      }
    )
      .pipe(map(media => {

        return new Media().deserialize(media);

      }));;
  }

  selectedElement(element) {

    this.$element.next(element);
  }

  getselectedElement() {

    return this.$element.asObservable();
  }
  
}
