import {Deserializable} from "./deserializable.model";
import { Blog } from "./Blog";
import { User } from "./User";

export class TagBlog implements Deserializable{

    tagBlog_id:number;
    tag_name:string;
    is_actived:boolean;
    created_user:User;
    created_date:Date;
    blog_list:Blog[]
   
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



