import { Component, Input, Output, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { MultipleChoiceGrid } from '@app/assessment/question-form/model'
import { FormStateEnum } from '@app/assessment/question-form/model/enum/FormStateEnum'
import { selectLang } from '@environments/languageEnvironment';
import { Observable } from 'rxjs';
import { BaseFormComponent } from '@app/assessment/question-form/base-form/baseform.component';

@Component({
  selector: 'multiplechoicegrid-form',
  templateUrl: './multiplechoicegrid-form.compoent.html',
  styleUrls: ['./multiplechoicegrid-form.component.css']
})

export class MultipleChoiceGridComponent extends BaseFormComponent implements OnInit {

  baseForm: MultipleChoiceGrid;
  other_text: string = "";
  other_choice_index: number;
  is_other: boolean = false;
  answer_list: any[] = [];

  ngOnInit() {
    /* extends method from BaseFormComponent */
    super.ngOnInit();
  }

  added_option() {

    let index = this.baseForm.choice_list.length;
    this.baseForm.choice_list.push({ option: 'option' + (index + 1) });
    this.out_form_event();
  }

  changed_option() {

    this.out_form_event();
  }

  deleted_option(index) {

    this.baseForm.choice_list.splice(index, 1);
    this.out_form_event();

  }

  added_rows() {

    let index = this.baseForm.rows_list.length
    this.baseForm.rows_list.push({ option: 'row' + (index + 1) })
    this.out_form_event();
  }

  changed_rows() {

    this.out_form_event();
  }

  deleted_rows(index) {

    this.baseForm.rows_list.splice(index, 1);
    this.out_form_event();

  }


  changed_radio(choice, rows) {

    var index = this.answer_list.findIndex(answer => answer.choice == choice.option);
    if (!(index == -1)) {

      this.answer_list.splice(index, 1);
      this.answer_list.push({ choice: choice.option, rows: rows.option });
      this.changed_answer();

    }
    else {

      this.answer_list.push({ choice: choice.option, rows: rows.option });
      this.changed_answer();

    }
  }

  changed_answer() {

    if (this.validation()) {
      
      this.out_answer(false);

    }
    else {
      
      this.out_answer(true);
    }

  }
}
