
import {Deserializable} from "./deserializable.model";
import { User } from "./User";
import { ElementText } from "./ElementText"
import { ElementFiles } from "./ElementFiles"
export class Instrument {

  instrument_id:number;
  topic:ElementText;
  detail:ElementText;
  file:ElementFiles;
  download_count:number;
  type:number;
  created_date:Date;
  created_user:User;
  is_actived:boolean;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
