import { Injectable, OnInit } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient,HttpRequest,HttpParams, HttpHeaders,HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ElementFiles,News } from '@app/model';

@Injectable({ providedIn: 'root' })
export class FileService  {

    constructor(private http: HttpClient) {

  
    }

    get_FileList() {

        return this.http.get(`${environment.apiUrl}/api/file/file_list`)
            .pipe(map(Files => {

                return Files;

            }));;
    }

    save_file(files: ElementFiles,file:File):Observable<HttpEvent<any>> {

        const formData: FormData = new FormData(); 
        formData.append('file', file);
        formData.append('files', JSON.stringify(files));
        formData.append('lang', localStorage.getItem('lang'));

        const request = new HttpRequest('POST', `${environment.apiUrl}/api/file/save_file`, formData, {
            reportProgress: true,
            responseType: 'json'
          });
      
          return this.http.request(request);

        // return this.http.post<any>(`${environment.apiUrl}/api/file/save_file`,
        //     formData,{
        //         reportProgress: true,
        //         responseType: 'json'
        //       })
        //     .pipe(map(file => {

        //         return file;

        //     }));
    }

    update_file(file: ElementFiles) {

        return this.http.post<any>(`${environment.apiUrl}/api/file/update_file`,
            { file: file })
            .pipe(map(file => {

                return file;

            }));
    }
}