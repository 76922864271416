export class Validation {

  type:string='';
  operation:string;
  valueOne:any;
  valueTwo:any;
  error_massage:string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
