import { Component, Inject, AfterViewInit, Input, Output, EventEmitter, PLATFORM_ID } from '@angular/core';
import { environment } from '@environments/environment';
import { isPlatformBrowser, isPlatformServer } from '@angular/common'

@Component({
  selector: 'file-editor',
  templateUrl: './file-editor.component.html',
  styleUrls: ['./file-editor.component.css']
})


export class FileEditorComponent implements AfterViewInit {

  @Input() file: any;
  @Output() outFileEvent = new EventEmitter<{}>();

  api_url: string = environment.apiUrl;

  existingFile: File = null;
  progress = 0;
  url:any = null;
  constructor(@Inject(PLATFORM_ID) private platformId: any) {

  }
  ngAfterViewInit() {

    this.existingFile = null;
    this.progress = 0;
  }

  uploadFile(event): void {

    if (isPlatformBrowser(this.platformId)) {

      this.existingFile = event.target.files && event.target.files[0];
      if (this.existingFile) {

        var reader = new FileReader();
        reader.readAsDataURL(this.existingFile);

        reader.onload = (event) => {

          this.url = (<FileReader>event.target).result;
          this.outFileEvent.emit({action: 'upload', value: this.existingFile})
        }
      }
    }
  }

  covert_file_to_name(file){

    if(file){

      return file.split("/")[1];
    }else{

      return '';
    }
    
  }
}
