
import {Deserializable} from "./deserializable.model";
import {User} from "./User"
export class LoginAccess implements Deserializable{

    user:User;
    access_token:string;
    access_refresh:string;
    expiry_date:string;
    userType_id:string;
    is_actived:boolean;
    created_date:Date;
   
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



