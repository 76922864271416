import { Directive, ElementRef, AfterViewInit } from "@angular/core";

@Directive({
    selector: 'video[autoplay]',
    host: {
        'autoplay': '',
        'oncanplay': 'this.play()',
        'onloadedmetadata': 'this.muted = true'
    }
})
export class VideoAutoplayDirective implements AfterViewInit {

    muteNode: any;
    constructor(public el: ElementRef) {

        // el.nativeElement.volume = 1.0;
    }

    ngAfterViewInit() {

        this.el.nativeElement.parentNode.classList.add("position-relative");

        this.el.nativeElement.style.display = 'block';

        this.muteNode = document.createElement('I');
        this.muteNode.classList.add('fas');
        this.muteNode.classList.add('fa-volume-up');
        this.muteNode.classList.add('fs-1');
        this.muteNode.classList.add('position-absolute');
        this.muteNode.classList.add('cursor-pointer');
        this.muteNode.classList.add('d-none');
        this.muteNode.style.opacity = "0.5";

        // muteNode.style.position="absolute;";
        this.muteNode.style.bottom = "50%";
        this.muteNode.style.left = "50%";

        this.el.nativeElement.parentNode.appendChild(this.muteNode);

        this.el.nativeElement.addEventListener("mouseover", this.mouseOver.bind(this));
        this.muteNode.addEventListener("mouseover", this.mouseOver.bind(this));
        this.el.nativeElement.addEventListener("mouseout", this.mouseout.bind(this));

        this.muteNode.addEventListener("click", this.clickMute.bind(this));
     
    }
    mouseOver() {

        this.muteNode.classList.remove("d-none");
    }

    mouseout(){

        this.muteNode.classList.add("d-none");
    }

    clickMute(){

        this.el.nativeElement.muted = !this.el.nativeElement.muted;

        if(this.el.nativeElement.muted){

            this.muteNode.classList.remove('fa-volume-mute');
            this.muteNode.classList.add('fa-volume-up');
            
        }else{
        
            this.muteNode.classList.remove('fa-volume-up');
            this.muteNode.classList.add('fa-volume-mute');
        }


    }
}