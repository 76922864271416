
import {Deserializable} from "./deserializable.model";
import { User } from "./User";
import { Course } from "./Course";
import { LessonDetail } from "./LessonDetail";
import {ElementText} from "./ElementText";
export class Lesson {

    lesson_id:number;
    title:ElementText;
    course:Course;
    lessonDetail_list:LessonDetail[];
    created_date:Date;


    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



