import { Component, OnInit, Input, Output, ElementRef, ViewEncapsulation, AfterViewInit, OnDestroy, ViewChild, EventEmitter, OnChanges, Inject, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';;
import { MatMenuTrigger } from '@angular/material/menu';
import { MatMenu } from '@angular/material/menu';
import { ElementService, UtilityService, WindowRefService } from '../service/';
import { AuthenticationService } from '@app/service';
import { ModalService } from '../helper/modal';
import { first } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TokenStorageService } from '@app/service';
import { AlertService } from '../helper/alert';
import { Subscription } from "rxjs";

@Component({
  selector: 'element',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ElementComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() name: string;
  @Input() type: string;
  @Input() class: string;
  @Input() ellipsis: { length: 10 } = null;
  @Input() feature_list: string[];
  @Input() user_editor: string[];
  @Input() isNewsTab: boolean = false;
  @Output() outVideoEvent = new EventEmitter<{}>();

  api_url: string = environment.apiUrl;
  app_name = environment.app_name

  menuTopLeftPosition = { x: '0', y: '0' }
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  @ViewChild("textElement") text_view: ElementRef;

  text_element_edited: any = { name: '', text: '' };
  image_element_edited: any = { name: '', image: '' };
  video_element_edited: any = { name: '', video: '' };

  text: any;
  image: any;
  video: any;

  @ViewChild("text", { static: false }) textDiv: ElementRef;

  openEditor: boolean = false;

  lang: string = localStorage.getItem('lang')

  user: any = this.tokenService.getUser();
  private subscriptions: Subscription[] = [];

  constructor(private modalService: ModalService,
    private elementService: ElementService,
    private utilityService: UtilityService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService,
    private tokenService: TokenStorageService
  ) {

  }

  reload() {

    this.text_element_edited = { name: '', text: '' };
    this.image_element_edited = { name: '', image: '' };
    this.video_element_edited = { name: '', video: '' };

  }

  ngOnChanges(changes) {
    console.log('changes')

  }

  ngOnInit(): void {

    this.subscriptions.push(this.tokenService.getUserBehavior().subscribe(user => {

      this.user = user;

    }))
  }

  ngAfterViewInit() {

    if (isPlatformBrowser(this.platformId)) {

      if (this.type == 'text') {

        this.getElementText(this.name).subscribe((data) => {


          this.text = data;
          if (this.lang == 'TH') {

            if (this.ellipsis) {

              this.textDiv.nativeElement.innerHTML = this.text.th_html;
              this.textDiv.nativeElement.textContent = this.ellipsisText(this.textDiv.nativeElement.textContent);

            } else {
              this.textDiv.nativeElement.innerHTML = this.text.th_html;
            }
          }
          else {

            if (this.ellipsis) {

              this.textDiv.nativeElement.innerHTML = this.text.en_html;
              this.textDiv.nativeElement.textContent = this.ellipsisText(this.textDiv.nativeElement.textContent);

            } else {
              this.textDiv.nativeElement.innerHTML = this.text.en_html;
            }
          }

          if (this.class) {

            let classList = this.class.split(" ")
            this.textDiv.nativeElement.classList.add(...classList)

          }

          this.text.styles = JSON.parse(this.text.styles)
          if (!this.text.styles) {
            this.text.styles = { hover: '', wmAnimate: '' }
          } else {

            if (this.text.styles.hover) {
              let classList = this.text.styles.hover.split(" ")
              if (classList) {

                this.textDiv.nativeElement.classList.add(...classList)
              }
            }
          }
        }
        )
      }
      if (this.type == 'image') {

        this.getElementImage(this.name).subscribe((data) => {

          this.image = data;
          if (this.image && !this.image.styles) {

            this.image.styles = { hover: '', wmAnimate: '', filter: '' }
          } else if (this.image && this.image.styles) {

            this.image.styles = JSON.parse(this.image.styles)
          }
        });
      }
      if (this.type == 'video') {

        this.getElementVideo(this.name).subscribe((data) => {

          this.video = null;
          this.video = data;
          console.log(this.video)
          this.video.styles = JSON.parse(this.video.styles)
          if (!this.video.styles) {

            this.video.styles = { hover: '', wmAnimate: '', filter: '' }
          }
        });
      }
      this.lang = localStorage.getItem('lang');

    }

  }

  ellipsisText(text) {

    let ellipsisText = '';
    if (text.length > this.ellipsis.length) {

      ellipsisText = text.substring(0, this.ellipsis.length)
      ellipsisText = ellipsisText + ' ...';
    }

    return ellipsisText;
  }

  ngOnDestroy() {

    this.openEditor = false;
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  getElementImage(element_name): Observable<string> {

    var subject = new Subject<string>();
    this.elementService.get_elementImage(element_name).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus == 'success') {
            subject.next(response.data);
          }
          else {
            subject.next(null);
          }

        },
        error: error => {
        }
      });

    return subject.asObservable();
  }


  getElementText(element_name): Observable<string> {

    var subject = new Subject<string>();
    this.elementService.get_elementText(element_name).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus = 'success') {

            subject.next(response.data);

          }
          else {
            subject.next(null);
          }

        },
        error: error => {
        }
      });

    return subject.asObservable();
  }

  getElementVideo(element_name): Observable<string> {

    var subject = new Subject<string>();
    this.elementService.get_elementVideo(element_name).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus = 'success') {

            subject.next(response.data);

          }
          else {
            subject.next(null);
          }

        },
        error: error => {
        }
      });

    return subject.asObservable();
  }

  onRightClick(event: MouseEvent) {

    event.preventDefault();
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    this.openEditor = true;

    if (this.type == 'image') {

      this.image_element_edited = { name: this.name, image: this.image };
    }
    if (this.type == 'text') {

      this.text_element_edited = { name: this.name, text: this.text };
    }
    if (this.type == 'video') {

      this.video_element_edited = { name: this.name, video: this.video };
    }
    this.matMenuTrigger.openMenu()

  }

  permission_editor() {


    if (this.user&&this.user.user_role.role_name == 'admin') {

      return true;
    }
    else {

      return false;
    }
  }

  update_element_edited() {

    if (this.type == 'image') {

      this.elementService.edited_image(this.utilityService.dataURItoBlob(this.image), this.image).pipe(first())
        .subscribe({
          next: (response: any) => {

            if (response.serviceStatus == 'success') {

              this.modalService.close(this.name);

              this.alertService.success(response['massage'], { autoClose: true });
            

            } else {

              this.alertService.error(response['massage'], { autoClose: true });

            }

          },
          error: error => {
          }
        });

    }
  }

  link() {

    if (this.type == 'image') {
      if (this.image.link) {
        if (this.isNewsTab) {

          window.open(this.image.link, "_blank");
        } else {
          window.location.href = this.image.link;
        }
      }
    }
    if (this.type == 'text') {
        if (this.text.link) {
          if (this.isNewsTab) {
            window.open(this.text.link, "_blank");
          } else {
            window.location.href = this.text.link;
          }
        }
      }
    }

    video_finish() {

      this.outVideoEvent.emit({ action: 'videoEnd' })
    }

    result_element(element_edited) {
      console.log(element_edited)
    }

    close_modal() {

      this.modalService.close(this.name);
      this.reload()
    }

    openModal() {

      this.modalService.open(this.name);

    }

    get_wmAnimate() {

      if (this.text && this.text.styles && this.text.styles.wmAnimate) {

        return this.text.styles.wmAnimate
      }
      else {

        return null
      }
    }
  }
