import { Component, OnInit, QueryList, ViewChildren, Input, OnChanges, AfterViewInit, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { ChatChannel, Chat } from '@app/model';
import { ChatService } from '@app/service/';
import { ChatMassageService } from '../chat.service';
import { AuthenticationService } from '@app/service';
import { Observable, Subject, Subscription } from 'rxjs';;
import { first } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { WindowRefService } from '@app/service';

@Component({
    selector: 'message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit, OnChanges, AfterViewInit {

    // @Input() chat_list: Chat[];
    @Input() chat_channel: ChatChannel;
    add_chat: Chat = new Chat()
    chatChannelSubscription: Subscription;
    @ViewChildren('chatScroll') private chatScroll: QueryList<ElementRef>;

    constructor(
        private authenticationService: AuthenticationService,
        private chatService: ChatService,
        private chatMassageService: ChatMassageService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private windowRefService: WindowRefService) {

        if (isPlatformBrowser(this.platformId)) {
            this.chatChannelSubscription = this.chatMassageService.getSelectedChatChannel().subscribe(seectedChatChannel => {

                if (seectedChatChannel) {
                    this.chat_channel = seectedChatChannel;

                    if (this.chat_channel.chat_list != null) {

                        this.chat_channel.chat_list.sort((a, b) => new Date(a.updated_date).getTime() - new Date(b.updated_date).getTime());
                    }
                    else {

                        this.chat_channel.chat_list = []
                    }

                } else {
                    // clear messages when empty message received
                    this.chat_channel = new ChatChannel()
                    this.chat_channel.chat_list = []
                }
                this.reload()
            });
        }
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {

        if (isPlatformBrowser(this.platformId)) {
            this.chatScroll.changes.subscribe(() => {
                if (this.chatScroll && this.chatScroll.last) {
                    this.chatScroll.last.nativeElement.focus();
                }
            });
        }

    }

    ngOnChanges(changes) {


    }

    reload() {

        if (isPlatformBrowser(this.platformId)) {
            this.add_chat = new Chat()
            this.add_chat.chat_channel = this.chat_channel
            this.add_chat.message = ''
        }
    }

    add_massage() {

        if (isPlatformBrowser(this.platformId)) {
            this.chatService.add_chat(this.add_chat).pipe(first())
                .subscribe({
                    next: (response: any) => {
                        if (response.serviceStatus == 'success') {

                            this.chatMassageService.changeMessageChat(response.data)
                            this.reload()
                        }
                    }
                });
        }
    }

    onFocus() {

        if (isPlatformBrowser(this.platformId)) {
            let unread_chatChannel_length = this.chat_channel.chat_list.filter(c => c.is_readed == false && c.sent_user.user_key != this.authenticationService.currentUserValue.user.user_key).length

            if (unread_chatChannel_length > 0) {

                this.chatService.read_chat(this.chat_channel).pipe(first())
                    .subscribe({
                        next: (response: any) => {
                            if (response.serviceStatus == 'success') {

                                this.chat_channel = response.data;
                                this.chat_channel.chat_list.sort((a, b) => new Date(a.updated_date).getTime() - new Date(b.updated_date).getTime());
                                this.chatMassageService.changeMessageChat(this.add_chat)
  
                            }
                        }
                    });
            }
        }
    }

    check_mesaage_status(chat) {
        
        if (isPlatformBrowser(this.platformId)) {
            if (this.authenticationService.currentUserValue.user.user_key == chat.sent_user.user_key) {

                return true
            }
            else {

                return false
            }
        }

    }
}

