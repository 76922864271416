import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { CarouselService } from '@app/helper/carousel/carousel.service';
import { ModalService } from '@app/helper/modal';
import { ElementService } from '@app/service';
import { environment } from '@environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { carousel } from '@app/helper/animation/animations';
import { Observable, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
    selector: 'home-carousel',
    templateUrl: './home-carousel.component.html',
    styleUrls: ['./home-carousel.component.css'],
    animations: carousel,
    encapsulation: ViewEncapsulation.None
})
export class HomeCarouselComponent implements OnInit, AfterViewInit {

    @ViewChild('video') video: ElementRef;
    apiUrl: string = environment.apiUrl
    items: any[] = []
    current_item: any = {
        name: '',
        type: 'video'
    }
    currrent_index: number = 0;

    element_image_1: any;
    element_image_2: any;
    element_image_3: any;
    element_image_4: any;
    element_image_5: any;

    autoPlay_state: boolean = false;

    animation_state: string = 'in'

    constructor(private elementService: ElementService) {



    }
    ngOnInit() {
        this.items.push({
            name: this.apiUrl + '/media/vedio.mp4',
            type: 'video'
        })
    }

    ngAfterViewInit() {

        this.add_cover_image()

    }

    add_cover_image() {

        this.getElementImage('pic_carousel_1').subscribe((data) => {

          this.element_image_1 = data,
          this.items.push({
              name: data,
              type: 'image'
          })
        })

        this.getElementImage('pic_carousel_2').subscribe((data) => {
            this.element_image_2 = data,
                this.items.push({
                    name: data,
                    type: 'image'
                })
        })

        this.getElementImage('pic_carousel_3').subscribe((data) => {
            this.element_image_3 = data,
                this.items.push({
                    name: data,
                    type: 'image'
                })
        })

        this.getElementImage('pic_carousel_4').subscribe((data) => {
            this.element_image_4 = data,
                this.items.push({
                    name: data,
                    type: 'image'
                })
        })

        this.getElementImage('pic_carousel_5').subscribe((data) => {
            this.element_image_5 = data,
                this.items.push({
                    name: data,
                    type: 'image'
                })
        })

    }
    getElementImage(element_name: string): Observable<string> {

        var subject = new Subject<string>();
        this.elementService.get_elementImage(element_name).pipe(first())
            .subscribe({
                next: (response: any) => {

                    if (response.serviceStatus = 'success') {
                        subject.next(response.data);
                    }
                    else {
                        subject.next(undefined);
                    }

                },
                error: error => {
                }
            });

        return subject.asObservable();
    }

    video_finish(event) {

        console.log(event)
        this.next()
        this.autoPlay_state = true;
        this.autoPlay()

    }

    next() {

        if (this.currrent_index == (this.items.length - 1)) {

            this.currrent_index = 0;
            this.current_item = this.items[0];
        }
        else {

            this.currrent_index = this.currrent_index + 1;
            this.current_item = this.items[this.currrent_index]
            this.animation_state = 'out'

        }

        this.toggle_autoPlay_state()

    }

    prev() {

        if (this.currrent_index == 0) {

            this.currrent_index = (this.items.length - 1);
            this.current_item = this.items[this.currrent_index];
        }
        else {

            this.currrent_index = this.current_item - 1;
            this.current_item = this.items[this.currrent_index]
            this.animation_state = 'out'

        }

        this.toggle_autoPlay_state()

    }

    onDone($event) {
        this.animation_state = 'in'
      }

    toggle_autoPlay_state() {

        if (this.current_item['type'] == 'image') {

            this.autoPlay_state = true;
        }
        else {

            let video = this.video.nativeElement.getElementsByTagName("video")[0];
            if(video){

              video.play();
            }
            this.autoPlay_state = false;
        }
    }

    indicator(number) {

        this.current_item = this.items[number]
        this.currrent_index = number
        this.animation_state = 'out';
        if (!this.autoPlay_state) {

            this.autoPlay_state = true;
            this.autoPlay()
        }

    }

    autoPlay() {

        if (this.autoPlay_state) {

            setTimeout(() => {

                this.next()
                this.autoPlay()
            },
                5000);
        }

    }
}
// export class HomeCarouselComponent implements OnInit, AfterViewInit {
//   subscription: Subscription;
//   index_current: any = 0

//   element_image_1: any = null;
//   element_image_2: any = null;
//   element_image_3: any = null;
//   element_image_4: any = null;
//   element_image_5: any = null;

//   apiUrl:string =environment.apiUrl

//   ///////////////////////
//   app_name = environment.app_name
//   menuTopLeftPosition = { x: '0', y: '0' }
//   @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger!: MatMenuTrigger;
//   innerHTMLEdited: any = { elementName: '', innerHTML: '' };
//   headerEdited: string = '';
//   typeEdited: string = '';
//   images: any[] = ['pic_carousel_1', 'pic_carousel_2', 'pic_carousel_3', 'pic_carousel_4', 'pic_carousel_5'];
//   ///////////////////////
//   constructor(private modalService: ModalService, private elementService: ElementService, private carouselService: CarouselService) {
//     // this.images = ['pic_carousel_1'];
//     // this.subscription = this.carouselService.getIndex().subscribe(index => {
//     // //   this.images = ['pic_carousel_1'];
//     //    this.index_current = index.index
//     //  });
//   }

//   click_carousel(carousel_index: any) {
//     // this.index_current = carousel_index
//     // this.carouselService.sentIndex(this.index_current);
//   }


//   ngOnInit(): void {

//     this.reload()
//   }
//   ngAfterViewInit() {

//     this.reload();
//   }

//   reload() {
//     // this.images = [];
//     // this.getElementImage('pic_carousel_1').subscribe((data)=>{this.element_image_1 = data ,this.images.push(this.element_image_1)})
//     // this.getElementImage('pic_carousel_2').subscribe((data)=>{this.element_image_2 = data,this.images.push(this.element_image_2) })
//     // this.getElementImage('pic_carousel_3').subscribe((data)=>{this.element_image_3 = data, this.images.push(this.element_image_3)})
//     // this.getElementImage('pic_carousel_4').subscribe((data)=>{this.element_image_4 = data,this.images.push(this.element_image_4) })
//     // this.getElementImage('pic_carousel_5').subscribe((data)=>{this.element_image_5 = data,this.images.push(this.element_image_5) })
//     // console.log(this.images)
//   }

//   getElementImage(element_name: string): Observable<string> {

//     var subject = new Subject<string>();
//     this.elementService.get_elementImage(element_name).pipe(first())
//       .subscribe({
//         next: (response: any) => {

//           if (response.serviceStatus = 'success') {
//             subject.next(response.data);
//           }
//           else {
//             subject.next(undefined);
//           }

//         },
//         error: error => {
//         }
//       });

//     return subject.asObservable();
//   }

//   public executeSelectedChange = (event: any) => {

//   }

//   next() {

//     this.carouselService.sentNext();
//   }

//   prev() {

//     this.carouselService.sentPrev();
//   }

//   onRightClick(event: MouseEvent, elementName: string, innerHTML: string, typeEdited: string) {

//     event.preventDefault();
//     this.menuTopLeftPosition.x = event.clientX + 'px';
//     this.menuTopLeftPosition.y = event.clientY + 'px';
//     if (typeEdited == 'image') {

//       this.innerHTMLEdited = { elementName: elementName, innerHTML: this.apiUrl + "/media/" + innerHTML };
//     }

//     this.typeEdited = typeEdited;
//     this.headerEdited = elementName;
//     this.matMenuTrigger.openMenu()

//   }

//   openModal() {

//     this.modalService.open('home-carousel-modal');
//   }

// }
