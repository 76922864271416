import { Component,ViewChild,Inject,OnInit,Input,Output,OnChanges,AfterViewInit,AfterContentChecked,ElementRef,EventEmitter,SimpleChanges,PLATFORM_ID  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { et } from 'date-fns/locale';
import { EditorService } from '../editor.service';
import { WindowRefService} from '@app/service'
import { isPlatformBrowser, isPlatformServer } from '@angular/common'
import { environment } from '@environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'video-editor',
  templateUrl: './video-editor.component.html',
  styleUrls: ['./video-editor.component.css']
})
export class VideoEditorComponent implements AfterViewInit  {

  @Input() video:any;
  @Input() feature_list:string []
  @Output() outVideoEvent = new EventEmitter<{}>();

  api_url: string = environment.apiUrl;

  existingFile: File = null;
  progress = 0;
  url:any = null;
  // link:string =null;
  is_valid_link_bool:boolean;
  // is_valid_link:boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ){

  }

  ngAfterViewInit() {

    this.existingFile = null;
    this.progress = 0;
    this.url = null;


  }

  uploadFile(event): void {

    if (isPlatformBrowser(this.platformId)) {

      this.existingFile = event.target.files && event.target.files[0];
      if (this.existingFile) {

        var reader = new FileReader();
        reader.readAsDataURL(this.existingFile);

        reader.onload = (event) => {

          this.url = (<FileReader>event.target).result;
          this.outVideoEvent.emit({action: 'upload', value: this.existingFile})
        }
      }
      // this.existingFile = event.target.files.item(0);
      // console.log(this.existingFile )
    }
  }

  changed_link(){

    this.is_valid_link()
  }

  updated_link(){
    console.log('linl')
    this.outVideoEvent.emit({action: 'link', value: this.video.link})
  }

  is_valid_link(){

    if(this.video.link){

      if(this.match_Youtube_Url(this.video.link)){

        this.is_valid_link_bool = true;
  
      }
      else if(this.chech_video_url(this.video.link)){

        this.is_valid_link_bool = true;
 
      }
      else{

        this.is_valid_link_bool = false;
      }

    }else{

      this.is_valid_link_bool = false;
    }
  }
  chnaged_external(){

    this.video.is_external = !this.video.is_external;
  }

  checked_feature(feature){

    let checked_feature = this.feature_list.find(f => f==feature);

    if(checked_feature){

      return true;
    }
    else{

      return false;
    }
  }

  private chech_video_url(url) {

    let is_video_url = false;
    var video = document.createElement("video");
    video.setAttribute("src", url);
    video.addEventListener("canplay", function () {

      is_video_url = true;
      return is_video_url;
    });
    video.addEventListener("error", function () {

      is_video_url = false;
      return is_video_url;
    });

    return is_video_url;
  }

  private match_Youtube_Url(url) {

    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      return url.match(p)[1];
    }
    return false;
  }

}
