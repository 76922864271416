import { Component, AfterViewInit, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { Assessments, AssessmentsAnswers } from '@app/model';
import { first } from 'rxjs/operators';
import { AlertService } from '@app/helper/alert';
import { AssessmentService } from '@app/assessment/services/assessment.service';
import { TitleFormComponent } from '@app/assessment/question-form/title-form/title-form.component'
import { ShortTextComponent } from '@app/assessment/question-form/shorttext-form/shorttext-form.component'
import { MultipleChoiceComponent } from '@app/assessment/question-form/multiplechoice-form/multiplechoice-form.component'
import { DropdownComponent } from '@app/assessment/question-form/dropdown-form/dropdown-form.component'
import { CheckBokComponent } from '@app/assessment/question-form/checkbox-form/checkbok-form.component'
import { LinkComponent } from '@app/assessment/question-form/link-form/link-form.component'
import { MultipleChoiceGridComponent } from '@app/assessment/question-form/multiplechoicegrid-form/multiplechoicegrid-form.component'
import { Router, Params, ActivatedRoute, NavigationExtras } from '@angular/router';
import { AssessmentDirective } from '@app/assessment/directive/assessment.directive';
import { TitleForm, ShortTextForm, CheckBoxForm, MultipleChoice, DropdownForm, SectionForm, MultipleChoiceGrid, LinkForm } from '@app/assessment/question-form/model';
import { isFunction } from 'rxjs/internal-compatibility';
import { FormViewsEnum } from '@app/assessment/question-form/model/enum/FormViewsEnum';
import { SectionFormComponent } from '../question-form/section-form/section-form.component';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'assessment-views',
  templateUrl: './assessment-views.component.html',
  styleUrls: ['./assessment-views.component.css']
})

export class AssessmentViewsComponent implements AfterViewInit {

  @ViewChild(AssessmentDirective) assessmentDirective: AssessmentDirective;
  assessments: Assessments = null;
  form: any;
  form_list: any = [];
  section_list: any = [];
  assessmentsAnswers: AssessmentsAnswers = new AssessmentsAnswers()
  anwser_list: string[] = []
  is_validation_form_list: {}[] = []
  is_validation: boolean = true;
  section_index = 0;
  viewContainerRef: any;
  public FormViewsEnum = FormViewsEnum;
  public state = FormViewsEnum.views;

  constructor(
    public viewsContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private assessmentService: AssessmentService,
    private alertService: AlertService,
    private route: ActivatedRoute,
  ) {

  }

  ngAfterViewInit() {

    this.get_assessments()
  }

  reload() {

    this.anwser_list = [];
    this.is_validation_form_list = [];
    this.is_validation = true;
    this.section_index = 0;
    this.viewContainerRef = null;
    this.form_list = [];
    this.get_assessments();
  }

  get_assessments() {

    this.route.queryParams.subscribe(params => {
      this.assessmentService.get_assessments(params['assessments_no'])
        .pipe(first())
        .subscribe(
          response => {
            if (response['serviceStatus'] == 'success') {

              this.assessments = response['data'];
              this.assessmentsAnswers.assessment_no = this.assessments.assessment_no;
              this.assessmentsAnswers.answers = JSON.stringify([])
              if (this.assessments && this.assessments.forms && this.assessments.forms.length > 0) {

                this.get_forms(this.transform_to_section(JSON.parse(this.assessments.forms)))
              }

            }
            else {

              console.log(response.massage)
            }
          });
    });
  }

  transform_to_section(forms) {

    let question_list = [];
    for (let index = 0; index < forms.length; index++) {


      if (forms[index].type == 'section' && index == 0) {


        question_list.push(forms[index])

        if (forms.length - 1 == 0) {

          this.section_list.push(question_list)
        }

      }
      else if (forms[index].type == 'section' && index >= 0 && index != forms.length - 1) {

        this.section_list.push(question_list)
        question_list = []
        question_list.push(forms[index])
      }
      else if (forms[index].type == 'section' && index == forms.length - 1) {

        this.section_list.push(question_list)
        question_list = []
        question_list.push(forms[index])
        this.section_list.push(question_list)

      }
      else if (index == forms.length - 1) {

        question_list.push(forms[index])
        this.section_list.push(question_list)
      }
      else {

        question_list.push(forms[index])

      }
    }
    return this.section_list;

  }

  get_forms(section_list) {

    for (let index = 0; index < section_list[this.section_index].length; index++) {

      this.form = section_list[this.section_index][index]
      if (this.form.is_required) {

        this.is_validation_form_list.push({ form_no: this.form.form_no, is_error: true })
      }
      else {
        this.is_validation_form_list.push({ form_no: this.form.form_no, is_error: false })
      }
      this.load_question(this.form.type)
    }
  }

  load_question(type) {

    switch (type) {

      case 'section': {

        let component = this.load_compoent(SectionFormComponent)
        this.load_sectionForm(component)

        break;
      }
      case 'title': {

        let component = this.load_compoent(TitleFormComponent)
        this.load_titleForm(component)

        break;
      }
      case 'shorttext': {

        let component = this.load_compoent(ShortTextComponent)
        this.load_shorttextForm(component)

        break;
      }
      case 'multiplechoice': {

        let component = this.load_compoent(MultipleChoiceComponent)
        this.load_multiplechoiceForm(component)

        break;
      }
      case 'checkbox': {

        let component = this.load_compoent(CheckBokComponent)
        this.load_checkboxForm(component)

        break;
      }
      case 'multiplechoicegrid': {

        let component = this.load_compoent(MultipleChoiceGridComponent)
        this.load_multiplechoiceGridForm(component)

        break;
      }
      case 'dropdown': {

        let component = this.load_compoent(DropdownComponent)
        this.load_dropdownForm(component)

        break;
      }
      case 'link': {

        let component = this.load_compoent(LinkComponent)
        this.load_linkForm(component)

        break;
      }
    }
  }

  load_compoent(component) {

    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.viewContainerRef = this.assessmentDirective.viewContainerRef;
    let createdComponent = this.viewContainerRef.createComponent(componentFactory);

    return createdComponent;
  }

  load_sectionForm(component) {

    if (this.form) {

      let sectionForm = new SectionForm()
      sectionForm.form_no = this.form['form_no']
      sectionForm.title = this.form['title'];
      sectionForm.description = this.form['description'];
      sectionForm.is_deleted = this.form['is_deleted']

      component.instance['baseForm'] = sectionForm;
      component.instance['is_views'] = true;
    }
  }

  load_titleForm(component) {

    if (this.form) {

      let titleForm = new TitleForm()
      titleForm.form_no = this.form['form_no']
      titleForm.title = this.form['title'];
      titleForm.description = this.form['description'];
      titleForm.is_deleted = this.form['is_deleted']

      component.instance['baseForm'] = titleForm;
      component.instance['is_views'] = true;
    }
  }

  load_shorttextForm(component) {

    if (this.form) {

      let shortTextForm = new ShortTextForm()
      shortTextForm.form_no = this.form['form_no']
      shortTextForm.question = this.form['question']
      shortTextForm.validation = this.form['validation']
      shortTextForm.is_required = this.form['is_required']
      shortTextForm.is_deleted = this.form['is_deleted']

      component.instance['baseForm'] = shortTextForm;
      component.instance['is_views'] = true;

      this.form_list.push({ form_no: shortTextForm.form_no, value_list: [] })
      this.check_validation()
      component.instance['outAnswer'].subscribe(answer => {

        let index = this.is_validation_form_list.findIndex(form => form['form_no'] == answer.form_no)
        this.is_validation_form_list[index]['is_error'] = answer.is_error;

        let form_index = this.form_list.findIndex(form => form['form_no'] == answer.form_no)
        this.form_list[form_index] = { form_no: answer.form_no, value_list: answer.value }
        this.check_validation()
      }
      );

    }
  }

  load_checkboxForm(component) {

    if (this.form) {

      let checkBoxForm = new CheckBoxForm()
      checkBoxForm.form_no = this.form['form_no']
      checkBoxForm.question = this.form['question']
      checkBoxForm.choice_list = this.form['choice_list']
      checkBoxForm.is_choice_other = this.form['is_choice_other']
      checkBoxForm.is_required = this.form['is_required']
      checkBoxForm.is_deleted = this.form['is_deleted']

      component.instance['baseForm'] = checkBoxForm;
      component.instance['is_views'] = true;

      this.form_list.push({ form_no: checkBoxForm.form_no, value_list: [] })
      this.check_validation()
      component.instance['outAnswer'].subscribe(answer => {

        let index = this.is_validation_form_list.findIndex(form => form['form_no'] == answer.form_no)
        this.is_validation_form_list[index]['is_error'] = answer.is_error;

        let form_index = this.form_list.findIndex(form => form['form_no'] == answer.form_no)
        this.form_list[form_index] = { form_no: answer.form_no, value_list: answer.value }

        this.check_validation()
      }
      );

    }

  }
  load_multiplechoiceForm(component) {

    if (this.form) {

      let multipleChoice = new MultipleChoice()
      multipleChoice.form_no = this.form['form_no']
      multipleChoice.question = this.form['question']
      multipleChoice.choice_list = this.form['choice_list']
      multipleChoice.is_required = this.form['is_required']
      multipleChoice.is_deleted = this.form['is_deleted']
      multipleChoice.is_choice_other = this.form['is_choice_other']

      component.instance['baseForm'] = multipleChoice;
      component.instance['is_views'] = true;

      this.form_list.push({ form_no: multipleChoice.form_no, value_list: [] })
      this.check_validation()
      component.instance['outAnswer'].subscribe(answer => {

        let index = this.is_validation_form_list.findIndex(form => form['form_no'] == answer.form_no)
        this.is_validation_form_list[index]['is_error'] = answer.is_error;

        let form_index = this.form_list.findIndex(form => form['form_no'] == answer.form_no)
        this.form_list[form_index] = { form_no: answer.form_no, value_list: answer.value }
        this.check_validation()
      }
      );

    }
  }



  load_multiplechoiceGridForm(component) {

    if (this.form) {

      let multipleChoiceGrid = new MultipleChoiceGrid()
      multipleChoiceGrid.form_no = this.form['form_no']
      multipleChoiceGrid.question = this.form['question']
      multipleChoiceGrid.choice_list = this.form['choice_list']
      multipleChoiceGrid.rows_list = this.form['rows_list']
      multipleChoiceGrid.is_required = this.form['is_required']
      multipleChoiceGrid.is_deleted = this.form['is_deleted']

      component.instance['baseForm'] = multipleChoiceGrid;
      component.instance['is_views'] = true;

      this.form_list.push({ form_no: multipleChoiceGrid.form_no, value_list: [] })
      this.check_validation()
      component.instance['outAnswer'].subscribe(answer => {

        let index = this.is_validation_form_list.findIndex(form => form['form_no'] == answer.form_no)
        this.is_validation_form_list[index]['is_error'] = answer.is_error;

        let form_index = this.form_list.findIndex(form => form['form_no'] == answer.form_no)
        this.form_list[form_index] = { form_no: answer.form_no, value_list: answer.value }
        this.check_validation()
      }
      );

    }
  }

  load_dropdownForm(component) {

    let dropdown = new DropdownForm()
    dropdown.form_no = this.form['form_no']
    dropdown.question = this.form['question']
    dropdown.choice_list = this.form['choice_list']
    dropdown.is_required = this.form['is_required']
    dropdown.is_deleted = this.form['is_deleted']

    component.instance['baseForm'] = dropdown;
    component.instance['is_views'] = true;

    this.form_list.push({ form_no: dropdown.form_no, value_list: [] })
    this.check_validation()
    component.instance['outAnswer'].subscribe(answer => {

      let index = this.is_validation_form_list.findIndex(form => form['form_no'] == answer.form_no)
      this.is_validation_form_list[index]['is_error'] = answer.is_error;

      let form_index = this.form_list.findIndex(form => form['form_no'] == answer.form_no)
      this.form_list[form_index] = { form_no: answer.form_no, value_list: answer.value }

      this.check_validation()
    }
    );
  }

  load_linkForm(component) {

    let linkForm = new LinkForm()
    linkForm.form_no = this.form['form_no']
    linkForm.title = this.form['title']
    linkForm.link = this.form['link']
    linkForm.description = this.form['description']
    linkForm.is_deleted = this.form['is_deleted']

    component.instance['baseForm'] = linkForm;
    component.instance['is_views'] = true;

    this.form_list.push({ form_no: linkForm.form_no, value_list: [] })
    this.check_validation()

  }
  check_validation() {

    let length = this.is_validation_form_list.filter(v => v['is_error'] == true).length;

    if (length > 0) {
      this.is_validation = false;
    }
    else {
      this.is_validation = true;
    }
  }

  clear_component_factory() {

    this.viewContainerRef.clear()
  }

  next_answer() {


    let answer_list = JSON.parse(this.assessmentsAnswers.answers);

    for (let i = 0; i < this.form_list.length; i++) {
      answer_list.push(this.form_list[i])
    }

    this.assessmentsAnswers.answers = JSON.stringify(answer_list);
    this.section_index = this.section_index + 1;
    this.is_validation_form_list = []
    this.clear_component_factory();
    this.get_forms(this.section_list)


    // }
    // else {

    //   this.assessmentsAnswers.answers = JSON.stringify(this.form_list);
    // }

  }

  submit_answer() {

    let answer_list = JSON.parse(this.assessmentsAnswers.answers);
    for (let i = 0; i < this.form_list.length; i++) {
      answer_list.push(this.form_list[i])
    }
    let form_list = JSON.stringify(answer_list);

    this.assessmentsAnswers.answers = JSON.stringify(answer_list);
    this.assessmentService.created_assessments_answers(this.assessmentsAnswers).pipe(first())
      .subscribe({
        next: (response: any) => {

          if (response.serviceStatus == 'success') {
            
            window.scrollTo(0, 0);
            this.state = FormViewsEnum.finished;
          }
          else {
          }
        },
        error: error => {
        }
      });
    // this.assessmentsAnswers.answers
    // this.assessmentsAnswers.answers = JSON.stringify(this.form_list);
    // this.assessmentService.created_assessments_answers(this.assessmentsAnswers).pipe(first())
    //   .subscribe({
    //     next: (response: any) => {

    //       if (response.serviceStatus == 'success') {

    //         this.state = FormViewsEnum.finished
    //       }
    //       else {

    //       }

    //     },
    //     error: error => {
    //     }
    //   });
  }

  return_views() {

    this.state = FormViewsEnum.views;

    this.reload();
  }

  link_home() {

    window.location.href = 'https://www.fal.or.th/home';
  }


}





  // validation(assessmentsAnswers) {

  //   let form = this.form_list.find(form => form.form_no == assessmentsAnswers.form_no)

  //   if (form.type == 'shorttext') {

  //     form['is_error'] = false
  //     if (form.is_required) {

  //       if (!form.assessmentsAnswers.answers_list[0].text) {

  //         form['is_error'] = true;

  //       }
  //       else {

  //         for (let i = 0; i < form.validation_list; i++) {

  //           if (form.validation_list[i].type == 'number') {

  //             if(isNaN(form.assessmentsAnswers.answers_list[0].text)){

  //               form['is_error'] = true;

  //             }
  //             if (form.validation_list[i].operation == 'greater-than') {

  //               if() {

  //               }

  //             }

  //           }
  //         }
  //       }




  //     }



  //   }
  // }


  // add_shorttextForm(component) {

  //     let shortTextForm = new ShortTextForm()
  //     if(this.copy_model){

  //       shortTextForm.form_no = this.generate_uuid4()
  //       shortTextForm.question = this.copy_model.question;
  //       shortTextForm.validation_list = this.copy_model.validation_list;
  //       shortTextForm.is_required = this.copy_model.is_required;
  //       this.copy_model = null;
  //     }
  //     else{

  //       shortTextForm.validation_list = []
  //       let validation = new Validation()
  //       validation.type = 'number';
  //       shortTextForm.validation_list.push(validation)
  //       shortTextForm.form_no = this.generate_uuid4()

  //     }

  //     component.instance['shortTextForm'] = shortTextForm;
  //     this.question_list.push(shortTextForm)
  //     this.updated_assessments()
  //     component.instance['outShortTextFormEvent'].subscribe(data =>
  //       this.output(data,'shortTextForm')
  //     );
  //   }
  // created_assessment() {

  //   let assessments = new Assessments()
  //   assessments.topic = null;
  //   assessments.description = null;
  //   assessments.forms = null;
  //   assessments.permissions = null;

  //   this.assessmentService.created_assessment(assessments).pipe(first())
  //     .subscribe({
  //       next: (response: any) => {

  //         if (response.serviceStatus == 'success') {

  //           this.alertService.success(response['massage'], { autoClose: true })
  //           this.get_assessment_list()

  //         }
  //         else {

  //           this.alertService.error(response['massage'], { autoClose: true })

  //         }

  //       },
  //       error: error => {
  //       }
  //     });
  // }

  // link_created_assessment(assessments_no){

  //   const queryParams: any = {};
  //           queryParams.assessments_no = assessments_no;
  //           const navigationExtras: NavigationExtras = {
  //               queryParams
  //           };
  //   this.router.navigate(['/created-assessment'], navigationExtras).then(() => {

  //   });
  // }
