import {Deserializable} from "./deserializable.model";
import { BaseForm } from "./BaseForm"
import { Validation } from "./Validation"

export class ShortTextForm extends BaseForm{

  form_no:string;
  type:string = 'shorttext';
  question:string;
  answer:string;
  validation:Validation;
  is_required:boolean = false;
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
