import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ElementComponent } from '@app/element/element.component';
import { AssessmentListComponent } from '@app/assessment/assessment-list/assessment-list.component';
import { CreatedAssessmentComponent } from '@app/assessment/created-assessment/created-assessment.component';
import { AssessmentViewsComponent } from '@app/assessment/assessment-views/assessment-views.component';
import { AssessmentInfoComponent } from '@app/assessment/assessment-info/assessment-info.component';
import { SectionFormComponent } from './question-form/section-form/section-form.component';
import { BaseFormComponent } from './question-form/base-form/baseform.component'
import { TitleFormComponent } from '@app/assessment/question-form/title-form/title-form.component';
import { LinkComponent } from '@app/assessment/question-form/link-form/link-form.component';
import { ShortTextComponent } from '@app/assessment/question-form/shorttext-form/shorttext-form.component';
import { MultipleChoiceComponent } from '@app/assessment/question-form/multiplechoice-form/multiplechoice-form.component';
import { CheckBokComponent } from '@app/assessment/question-form/checkbox-form/checkbok-form.component';
import { DropdownComponent } from '@app/assessment/question-form/dropdown-form/dropdown-form.component';
import { MultipleChoiceGridComponent } from '@app/assessment/question-form/multiplechoicegrid-form/multiplechoicegrid-form.component';
import { AssessmentDirective } from '@app/assessment/directive/assessment.directive';
import { DragdropDirective } from '@app/assessment/directive/dragdrop.directive';
import { MatMenuModule } from '@angular/material/menu';
import { JWModalModule } from '@app/helper/modal';
import { SafePipe } from '@app/assessment/pipe/safePipe'
import { ElementModule } from '@app/element/element.module'


@NgModule({
  imports: [CommonModule, BrowserModule, FormsModule, MatMenuModule, JWModalModule, ElementModule],
  declarations: [AssessmentListComponent, CreatedAssessmentComponent, AssessmentViewsComponent, AssessmentInfoComponent, BaseFormComponent, SectionFormComponent, TitleFormComponent, ShortTextComponent, MultipleChoiceComponent, DropdownComponent, MultipleChoiceGridComponent, CheckBokComponent, LinkComponent, SafePipe, AssessmentDirective, DragdropDirective],
  // entryComponents: [  ],
  exports: [AssessmentListComponent, CreatedAssessmentComponent, AssessmentViewsComponent, AssessmentInfoComponent, BaseFormComponent, SectionFormComponent, TitleFormComponent, ShortTextComponent, MultipleChoiceComponent, DropdownComponent, MultipleChoiceGridComponent, CheckBokComponent, LinkComponent]
})
export class AssessmentModule { }
