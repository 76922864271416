
import {Deserializable} from "./deserializable.model";
import { Blog } from "./Blog";
import { User } from "./User";

export class FeelingBlog implements Deserializable{

    feeling_id:number;
    feeling_status:number;
    feeling_user:User;
    is_actived:boolean;
    blog:Blog
    updated_date:Date;
   
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



