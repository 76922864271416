import { Component, OnInit, AfterViewInit, HostListener, ViewChild, ViewEncapsulation, Inject, PLATFORM_ID } from '@angular/core';
import { ModalService } from '../helper/modal';
import { ElementService, AuthenticationService, NotificationService } from '@app/service/';
import { FormGroup, FormControl, Validators, NgControl } from '@angular/forms';
import { environment } from '@environments/environment';
import { selectLang } from '@environments/languageEnvironment';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatMenu } from '@angular/material/menu';
import { Observable, Observer, Subject } from 'rxjs';;
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { NewsService, WindowRefService } from '@app/service';
import { TokenStorageService } from '@app/service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, AfterViewInit {

  menuTopLeftPosition = { x: '0', y: '0' }
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  app_name = environment.app_name
  apiUrl: string = environment.apiUrl
  messages: Subject<string>;
  selectLang = selectLang
  innerHTMLEdited: any = { elementName: '', innerHTML: '' };
  headerEdited: string = '';
  typeEdited: string = '';

  element_text_menu_1: any = null;
  element_text_menu_2: any = null;
  element_text_menu_3: any = null;
  element_text_menu_4: any = null;
  lang_list = ['th', 'en']
  lang: string =localStorage.getItem('lang')
  socket: any;
  user: any = this.tokenService.getUser()
  profile_pic_url: string = '';
  is_open_profile_popOver: boolean = false;
  currentRoute: string = '';


  constructor(private modalService: ModalService,
    private elementService: ElementService,
    public router: Router,
    private AuthenticationService: AuthenticationService,
    private tokenService: TokenStorageService,
    private notificationService: NotificationService,

    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService) {

    if (isPlatformBrowser(this.platformId)) {
      router.events.subscribe((val) => {
        this.currentRoute = this.router.url;
      });

    }

  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {

      this.user = this.tokenService.getUser()
      this.tokenService.getUserBehavior().subscribe(user => {
       
        this.user = user

        if (user) {

          this.profile_pic_url = '';

          if (this.isValidHttpUrl(user.image_url)) {
            this.profile_pic_url = user.image_url
          }
          else {

            this.profile_pic_url = environment.apiUrl + '/media/' + user.image_url + '?' + (new Date()).getTime();

          }
        }

      });
      this.reload()
    }
  }

  ngAfterViewInit() {

    // if (isPlatformBrowser(this.platformId)) {

    //   this.user = this.tokenService.getUser();
    //   console.log(this.user)

    // }
  }

  reload() {

    this.is_open_profile_popOver = false

  }

  click_sub_mune_training(sub_menu) {

    if (isPlatformBrowser(this.platformId)) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/training'], { state: sub_menu });
      });
    }
  }


  getElementText(element_name): Observable<string> {
    if (isPlatformBrowser(this.platformId)) {
      var subject = new Subject<string>();
      this.elementService.get_elementText(element_name).pipe(first())
        .subscribe({
          next: (response: any) => {
            if (response.serviceStatus = 'success') {
              subject.next(response.data);
            }
            else {
              subject.next(null);
            }

          },
          error: error => {
          }
        });

      return subject.asObservable();
    }
  }

  onRightClick(event: MouseEvent, elementName, innerHTML, typeEdited) {
    if (isPlatformBrowser(this.platformId)) {
      event.preventDefault();
      this.menuTopLeftPosition.x = event.clientX + 'px';
      this.menuTopLeftPosition.y = event.clientY + 'px';
      if (typeEdited == 'image') {

        this.innerHTMLEdited = { elementName: elementName, innerHTML: this.apiUrl + "/media/" + innerHTML };
      }

      if (typeEdited == 'text') {
        this.innerHTMLEdited = { elementName: elementName, innerHTML: innerHTML };
      }
      this.typeEdited = typeEdited;
      this.headerEdited = elementName;
      this.matMenuTrigger.openMenu()
    }
  }

  openModal() {

    if (isPlatformBrowser(this.platformId)) {
      this.modalService.open('headerModal');
    }
  }
  langChanged() {

    if (isPlatformBrowser(this.platformId)) {
      if (this.lang == 'TH') {

        localStorage.setItem('lang', 'EN')
        this.lang = 'EN'
      }

      else if (this.lang == 'EN') {

        localStorage.setItem('lang', 'TH')
        this.lang = 'TH'
      }
      window.location.reload()
    }
  }

  logout() {

    if (isPlatformBrowser(this.platformId)) {
      this.AuthenticationService.logout()
      this.router.navigate(['/home']);
    }
  }

  checkNotification() {
    // this.notificationService.getNotifications();
  }

  // @HostListener('window:scroll', ['$event'])
  // checkScroll() {

  //     // console.log(window.pageYOffset)
  // }




  private connect(url): Subject<MessageEvent> {
    if (isPlatformBrowser(this.platformId)) {
      let ws = new WebSocket(url);

      let observable = Observable.create(
        (obs: Observer<MessageEvent>) => {
          ws.onmessage = obs.next.bind(obs);
          ws.onerror = obs.error.bind(obs);
          ws.onclose = obs.complete.bind(obs);
          return ws.close.bind(ws);
        })
      let observer = {
        next: (data: string) => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(data);
            if (data == "stop")
              ws.close(1000, "bye")
          }
        }
      }
      return Subject.create(observer, observable);
    }
  }
  start() {
    this.messages.next("start");
  }

  stop() {
    this.messages.next("stop");
  }

  openProfilePopover() {

    this.is_open_profile_popOver = !this.is_open_profile_popOver;
  }

  closeProfilePopover() {

    this.is_open_profile_popOver = false;
  }

  isValidHttpUrl(string) {
    if (isPlatformBrowser(this.platformId)) {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    }
  }


}
