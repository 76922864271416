
import {Deserializable} from "./deserializable.model";
import { User } from "./User";
import { ChatChannel } from "./ChatChannel";
import { Lesson } from "./Lesson";
import { ElementFiles} from "./ElementFiles"
import { ElementText } from "./ElementText"
import { ElementImage } from "./ElementImage"
export class Course {

    course_no:string;
    topic:ElementText;
    detail:ElementText;
    about:ElementText;
    objective:ElementText;
    criterion:ElementText;
    cover_image:ElementImage;
    instructor_list:User [];
    student_list:User [];
    lesson_list:Lesson []
    url:string;
    created_date:Date;
    created_user:User;
    is_actived:boolean;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
