import { Component, OnInit } from '@angular/core';
import { AssessmentService } from '@app/assessment/services/assessment.service';
import { Router, Params, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Assessments, AssessmentsAnswers } from '@app/model';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';
import { AlertService } from '@app/helper/alert';

@Component({
  selector: 'assessment-info',
  templateUrl: './assessment-info.component.html',
  styleUrls: ['./assessment-info.component.css']
})

export class AssessmentInfoComponent implements OnInit {

  assessments: Assessments = new Assessments()
  assessmentsAnswers_list: AssessmentsAnswers[] = [];
  current_index = null;
  current_anwser_assessments_list: any[] = [];
  csv: string;

  constructor(
    private assessmentService: AssessmentService,
    private route: ActivatedRoute,
    private alertService: AlertService,
  ) {
  }

  ngOnInit() {

    this.reload()
  }

  reload() {

    this.route.queryParams.subscribe(params => {

      this.get_assessments(params['assessments_no']);
      console.log('assessments_no' + params['assessments_no'])
    });

  }

  get_assessments(assessments_no: string) {

    this.assessmentService.get_assessments(assessments_no)
      .pipe(first())
      .subscribe(
        response => {
          if (response['serviceStatus'] == 'success') {

            this.assessments = response['data'];
            this.get_assessments_answers_list(assessments_no)


          }
          else {
            this.alertService.error(response['massage'], { autoClose: true })
          }

        });
  }

  get_assessments_answers_list(assessments_no: string) {

    this.assessmentService.get_assessments_answers_list(assessments_no)
      .pipe(first())
      .subscribe(
        response => {
          if (response['serviceStatus'] == 'success') {

            console.log('get_assessments_answers_list');
            console.log(response['data_list'])
            this.csv = response['csv'];
            this.assessmentsAnswers_list = response['data_list']
            if (this.assessmentsAnswers_list.length > 0) {

              this.current_index = 0
            }
            this.get_current_anwser_assessments_list()

          }
          else {

            console.log(response['massage'])
          }
        });
  }
  prev() {

    if (this.current_index == 0) {

      this.current_index = this.assessmentsAnswers_list.length - 1
    }
    else {
      this.current_index = this.current_index - 1;
    }

    this.get_current_anwser_assessments_list()
  }

  next() {

    if (this.current_index == this.assessmentsAnswers_list.length - 1) {

      this.current_index = 0

    } else {

      this.current_index = this.current_index + 1;
    }

    this.get_current_anwser_assessments_list()
  }

  get_current_anwser_assessments_list() {

    if (this.assessments.forms) {

      let forms_list = JSON.parse(this.assessments.forms)

      this.current_anwser_assessments_list = [];
      for (let i = 0; i < forms_list.length; i++) {

        if (this.assessmentsAnswers_list) {
          if (this.assessmentsAnswers_list[this.current_index] && this.assessmentsAnswers_list[this.current_index].answers) {
            let answers_list = JSON.parse(this.assessmentsAnswers_list[this.current_index].answers)
            let anwser = answers_list.find(a => a.form_no == forms_list[i].form_no)
            if (!anwser) {
              let anwser_assessments = { question: forms_list[i], anwser_list: [] }
              this.current_anwser_assessments_list.push(anwser_assessments)
            } else {

              let anwser_assessments = { question: forms_list[i], anwser_list: anwser.value_list }
              this.current_anwser_assessments_list.push(anwser_assessments)
            }
          }
        }
      }
    }

  }

  download_csv() {
    window.open(`${environment.apiUrl}/media/` + this.csv);
  }

}
