import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/helper/authentication';
import { HomeComponent } from '@app/home/home.component';
import { environment } from '@environments/environment';
import { LoginComponent } from '@app/login/login.component';
import { ComfirmRegisterComponent } from '@app/register/comfirm-register/confirm-register.component';
import { ForgetPasswordComponent } from '@app/register/forget-password/forget-password.component';
import { AboutComponent } from '@app/about/about.component';
import { ContactComponent } from '@app/contact/contact.component';
import { TrainingComponent } from '@app/training/training.component';
import { TrainingMleComponent } from '@app/training/training-mle/training-mle.component';
import { TrainingTslComponent } from '@app/training/training-tsl/training-tsl.component';
import { TrainingTlComponent } from '@app/training/training-tl/training-tl.component';
import { TrainingIceComponent } from '@app/training/training-ice/training-ice.component';
import { MassiveDirectorComponent } from '@app/massive-director/massive-director.component';
import { AwardComponent } from '@app/award/award.component';
import { ManualComponent } from '@app/manual/manual.component';
import { ChildrenRightsComponent } from './children-rights/children-rights.component';
import { InstructionalMediaComponent } from '@app/instructional-media/instructional-media.component';
import { VolunteerComponent } from '@app/volunteer/volunteer.component';
import { VideoTrainingComponent } from '@app/video-training/video-training.component';
import { InternshipComponent } from '@app/internship/internship.component';
import { PartnerComponent } from '@app/partner/partner.component';
import { ChatComponent } from '@app/chat/chat.component';
import { BlogComponent } from './blog/blog.component';
import { CourseComponent } from '@app/course/course.component';
import { CourseDetailComponent } from '@app/course/course-detail/courseDetail.component';
import { DonationComponent } from '@app/donation/donation.component';
import { NewsInfoComponent } from '@app/news-info/news-info.component';
import { AcademyCourseComponent } from '@app/academy-course/acdemy-course.component';
import { MLECourseComponent } from '@app/MLE-course/MLE-course.component';
import { MissionComponent } from '@app/mission/mission.component';
import { PersonnelComponent } from '@app/personnel/personnel.component';
import { MapComponent } from '@app/map/map.component';
import { AddressComponent } from '@app/address/address.component';
import { StoryInfoComponent } from '@app/story-info/story-info.component';
import { UserComponent } from '@app/user/user.component';
import { CreatedAssessmentComponent } from '@app/assessment/created-assessment/created-assessment.component'
import { AssessmentViewsComponent } from '@app/assessment/assessment-views/assessment-views.component'
import { AssessmentListComponent } from '@app/assessment/assessment-list/assessment-list.component'
import { AssessmentInfoComponent } from '@app/assessment/assessment-info/assessment-info.component'
import { HistoryComponent } from '@app/history/history.component';
import { ForbidenPageCompoent } from '@app/forbidden-page/forbidden-page.component';
import { ElementEditerComponent } from '@app/new-element/elementEditer/element-editer.component'
// import { TextEditerComponent } from '@app/new-element/text-element/text-editer/text-editer.component';
// import { TextElementComponent } from '@app/new-element/text-element/text-element.component'

/* test*/
import { TestComponent } from '@app/editor/test/test.component'

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: environment.app_name == 'admin' || environment.app_name == 'webmaster' ? [AuthGuard] : [] },
  { path: 'login', component: LoginComponent },
  { path: 'comfirm-register/:email', component: ComfirmRegisterComponent },
  { path: 'forget-password/:token', component: ForgetPasswordComponent },
  { path: 'forbiden-page', component: ForbidenPageCompoent },
  { path: 'user', component: UserComponent },
  { path: 'about', component: AboutComponent },
  { path: 'training', component: TrainingComponent },
  { path: 'training-MLE', component: TrainingMleComponent },
  { path: 'training-TSL', component: TrainingTslComponent },
  { path: 'training-TL', component: TrainingTlComponent },
  { path: 'training-ICE', component: TrainingIceComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'massive-director', component: MassiveDirectorComponent },
  { path: 'award', component: AwardComponent },
  { path: 'manual', component: ManualComponent },
  { path: 'children-rights', component: ChildrenRightsComponent },
  { path: 'instructional-media', component: InstructionalMediaComponent },
  { path: 'volunteer', component: VolunteerComponent },
  { path: 'video-training', component: VideoTrainingComponent },
  { path: 'internship', component: InternshipComponent },
  { path: 'partner', component: PartnerComponent },
  { path: 'chat', component: ChatComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'course', component: CourseComponent },
  { path: 'course-detail/:courseNo', component: CourseDetailComponent },
  { path: 'donation', component: DonationComponent },
  { path: 'news-info/:newsId', component: NewsInfoComponent },
  { path: 'story-info/:storyId', component: StoryInfoComponent },
  { path: 'academy-course', component: AcademyCourseComponent },
  { path: 'MLE-course', component: MLECourseComponent },
  { path: 'mission', component: MissionComponent },
  { path: 'personnel', component: PersonnelComponent },
  { path: 'map', component: MapComponent },
  { path: 'address', component: AddressComponent },
  { path: 'created-assessment', component: CreatedAssessmentComponent },
  { path: 'assessment-list', component: AssessmentListComponent },
  { path: 'assessment-views', component: AssessmentViewsComponent, canActivate: [AuthGuard] },
  { path: 'assessment-info', component: AssessmentInfoComponent },
  { path: 'history', component: HistoryComponent },
  //{ path: 'test', component: ElementEditerComponent },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
