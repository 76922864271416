
import {Deserializable} from "./deserializable.model";

export class Donation {

    donation_id:number;
    amount:number;
    email:string;
    comfirm_email:string;
    first_name:string;
    last_name:string;
    id_citizen:string;
    tel:string;
    receipt_address:string;
    certificate_address:string;
    source_id:string;
    charge_id:string;
    status:number;
    is_receipt:boolean;
    is_actived:boolean;
    updated_date:Date;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



