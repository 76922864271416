
import { AfterViewInit,ElementRef,Input,Directive,Inject,Renderer2 } from "@angular/core";
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: "[textViewElement]",
})
export class TextViewElementDirective implements AfterViewInit{

  @Input() HTMLText: string;
  @Input() isOnlyText: boolean;
  @Input() isLimitedText: boolean;

  constructor(public elementRef: ElementRef,private renderer: Renderer2,@Inject(DOCUMENT) private document: Document){

  }
  ngAfterViewInit() {

    this.elementRef.nativeElement.innerHTML = this.HTMLText;
    if(this.isOnlyText){

      var imgElementDelete = this.elementRef.nativeElement.querySelectorAll('img');
      imgElementDelete.forEach(element => {

        element.remove()
        // this.elementRef.nativeElement = imgElementDelete;
      })

    }

    if(this.isLimitedText){

      if(this.elementRef.nativeElement.textContent.length > 100){

        this.elementRef.nativeElement.textContent = this.elementRef.nativeElement.textContent.substring(0,100) + '...'
      }

    }

    let imgElementList = this.elementRef.nativeElement.querySelectorAll('img');
    console.log(imgElementList)
    imgElementList.forEach(element => {

      element.classList.add('img-fluid');
      element.classList.add('w-100');
      element.classList.add('h-auto');
      element.classList.add('rounded');
      // console.log(element)
      // if(element.checked){
      //    element.checked = false
      // }
    })
  }
}
