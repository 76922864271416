import { NgModule, Injector, ComponentFactoryResolver, ApplicationRef, EventEmitter, ComponentRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { createCustomElement } from '@angular/elements';
import { map } from 'rxjs/operators';
import { merge, Observable, Subscription } from 'rxjs';
/* API CALL*/
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
// import { AngularCropperjsModule } from 'angular-cropperjs';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { AngularEditorModule } from '@kolkov/angular-editor';
// import { Ng2ImgMaxModule } from 'ng2-img-max';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
// import { VgCoreModule } from '@videogular/ngx-videogular/core';
// import { VgControlsModule } from '@videogular/ngx-videogular/controls';
// import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
// import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
// import { JwPaginationModule } from 'jw-angular-pagination';
// import { PaginationModule} from '@app/helper/pagination/pagination.module';
import { MatMenuModule } from '@angular/material/menu';
import { FlatpickrModule } from 'angularx-flatpickr';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';


/*  END  */
/* CALL Module */
import { MaterialModule } from '@app/module/material/material.module';
import { JWModalModule } from '@app/helper/modal';
import { AlertModule } from '@app/helper/alert';
import { PaginationModule } from '@app/helper/pagination/pagination.module'
import { JwtInterceptor, ErrorInterceptor } from '@app/helper/authentication';
import { AppRoutingModule } from '@app/app-routing.module';
import { AnimateModule } from '@app/helper/animate/animate.module';
import { AssessmentModule } from '@app/assessment/assessment.module';
import { EditorModule } from '@app/editor/editor.module';
import { ElementModule } from '@app/element/element.module';
import { NewElementModule } from '@app/new-element/element.module';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxColorsModule } from 'ngx-colors';
/* END*/
/* CALL COMPONENT */
import { AppComponent } from '@app/app.component';
import { HomeComponent } from '@app/home/home.component';
// import { ElementComponent } from '@app/element/element.component';
// import { EditorComponent } from '@app/helper/editor/editor.component';
// import { MenuBarEditor } from './helper/editor/menu-bar-editor/menu-bar-editor.component';
// import { ImageCropperContentComponent } from './helper/editor/image-cropper-content/image-cropper-content.component';
// import { ImageCroppperComponent } from '@app/helper/imageCropper/image-cropper.component';
import { HomeCarouselComponent } from './home/carousel/home-carousel.component';
import { NewsComponent } from '@app/news/news.component';
import { NewsInfoComponent } from '@app/news-info/news-info.component';
import { StoryComponent } from '@app/story/story.component';
import { StoryInfoComponent } from '@app/story-info/story-info.component'
import { HeaderComponent } from '@app/header/header.component';
import { LoginComponent } from '@app/login/login.component';
import { AboutComponent } from '@app/about/about.component';
import { ContactComponent } from '@app/contact/contact.component';
import { TrainingComponent } from '@app/training/training.component';
import { TrainingMleComponent } from '@app/training/training-mle/training-mle.component';
import { TrainingTslComponent } from '@app/training/training-tsl/training-tsl.component';
import { TrainingTlComponent } from '@app/training/training-tl/training-tl.component';
import { TrainingIceComponent } from '@app/training/training-ice/training-ice.component';
import { FooterComponent } from '@app/home/footer/footer.component';
import { ComfirmRegisterComponent } from '@app/register/comfirm-register/confirm-register.component';
import { MassiveDirectorComponent } from '@app/massive-director/massive-director.component';
import { AwardComponent } from '@app/award/award.component';
import { ManualComponent } from '@app/manual/manual.component';
import { ForgetPasswordComponent } from '@app/register/forget-password/forget-password.component';
import { InstructionalMediaComponent } from '@app/instructional-media/instructional-media.component';
import { ChildrenRightsComponent } from './children-rights/children-rights.component';
import { VolunteerComponent } from '@app/volunteer/volunteer.component';
import { VideoTrainingComponent } from '@app/video-training/video-training.component';
import { InternshipComponent } from '@app/internship/internship.component';
import { PartnerComponent } from '@app/partner/partner.component';
import { ChatComponent } from '@app/chat/chat.component';
import { MessageComponent } from '@app/chat/message/mesage.component';
import { BlogComponent } from '@app/blog/blog.component';
import { CourseComponent } from '@app/course/course.component';
import { CourseDetailComponent } from '@app/course/course-detail/courseDetail.component';
import { DonationComponent } from '@app/donation/donation.component';
import { AcademyCourseComponent } from '@app/academy-course/acdemy-course.component';
import { MLECourseComponent } from '@app/MLE-course/MLE-course.component';
import { MissionComponent } from '@app/mission/mission.component';
import { PersonnelComponent } from '@app/personnel/personnel.component';
import { MapComponent } from '@app/map/map.component';
import { AddressComponent } from '@app/address/address.component';
import { UserComponent } from '@app/user/user.component';
import { CalendarComponent } from '@app/calendar/calendar.component';
import { HistoryComponent } from '@app/history/history.component';
import { ForbidenPageCompoent } from '@app/forbidden-page/forbidden-page.component';
import { TextElementComponent } from '@app/new-element/text-element/text-element.component'
import { ImageElementComponent } from '@app/new-element/image-element/image-element.component';
import {  SelectedContainerComponent } from '@app/new-element/sleected-container/selected-container.component';
import { CourseEditedDirective } from '@app/course/directive/edited-link.directive';

/* END */
/* CALL SERVICE*/
import { EditorService } from './helper/editor/editor.service';
import { NotificationService } from '@app/service/';
import { WebsocketService } from '@app/service/';
import { ChatService } from '@app/service/';
import { ConfirmationDialogService } from '@app/helper/confirmation-dialog/confirmation-dialog.service';
/* */
/* Pipe */
import { DateTimePipe } from '@app/helper/filter/datetime/DateTime.pipe'
import { LikeFilterPipe } from '@app/helper/filter/blogFeeling/likeFeeling';
import { UnLikeFilterPipe } from '@app/helper/filter/blogFeeling/unlikeFeeling';
import { ViewsFilterPipe } from '@app/helper/filter/blogFeeling/viewsFeeling';


/* */
import { ConfirmationDialogComponent } from '@app/helper/confirmation-dialog/confirmation-dialog.component';
/* */
/* directive */
// import { EllipsisDirective } from '@app/directive/ellipsis/ellipsis.directive';
import { TwoDigitDecimaNumberDirective } from '@app/helper/directive/TwoDigitDecimaNumber.direction';/* */

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeCarouselComponent,
    // ElementComponent,
    // EditorComponent,
    // MenuBarEditor,
    // ImageCropperContentComponent,
    // ImageCroppperComponent,
    NewsComponent,
    NewsInfoComponent,
    StoryComponent,
    StoryInfoComponent,
    HeaderComponent,
    AboutComponent,
    ContactComponent,
    TrainingComponent,
    TrainingMleComponent,
    TrainingTslComponent,
    TrainingTlComponent,
    TrainingIceComponent,
    FooterComponent,
    MassiveDirectorComponent,
    AwardComponent,
    ManualComponent,
    ForgetPasswordComponent,
    InstructionalMediaComponent,
    ChildrenRightsComponent,
    VolunteerComponent,
    VideoTrainingComponent,
    InternshipComponent,
    PartnerComponent,
    ChatComponent,
    MessageComponent,
    BlogComponent,
    ConfirmationDialogComponent,
    LoginComponent,
    CourseComponent,
    CourseDetailComponent,
    // VideogularComponent,
    DonationComponent,
    AcademyCourseComponent,
    MLECourseComponent,
    MissionComponent,
    PersonnelComponent,
    MapComponent,
    AddressComponent,
    UserComponent,
    CalendarComponent,
    HistoryComponent,
    ForbidenPageCompoent,
    // AlertComponent,
    // TextElementComponent,
    DateTimePipe,
    LikeFilterPipe,
    UnLikeFilterPipe,
    ViewsFilterPipe,
    // EllipsisDirective,
    TwoDigitDecimaNumberDirective,
    CourseEditedDirective

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    FlatpickrModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    JWModalModule,
    MatMenuModule,
    AngularCropperjsModule,
    SocialLoginModule,
    AlertModule,
    ModalModule.forRoot(),
    // PdfViewerModule,
    AngularEditorModule,
    // Ng2ImgMaxModule,
    // VgCoreModule,
    // VgControlsModule,
    // VgOverlayPlayModule,
    // VgBufferingModule,
    // JwPaginationModule,
    PaginationModule,
    // NgxColorsModule,
    // AnimateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AssessmentModule,
    EditorModule,
    ElementModule,
    NgxYoutubePlayerModule.forRoot()
    // NewElementModule

    // AdminModule
    // VideogularModule

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '859481630664-g40jc3krdh35g72pk9eqnjo68l7bh6vc.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('314878493789967')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    DatePipe,
    EditorService,
    ConfirmationDialogService,
    NotificationService,
    WebsocketService,
    ChatService
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
  constructor(private injector: Injector) {
    // let ngElement = customElementPlease(TextElementComponent, {
    //   injector: this.injector
    // });
    // customElements.define('text-element', ngElement);

    // const ngElement = customElementPlease(ImageElementComponent, {
    //   injector: this.injector
    // });

    // customElements.define('image-element', ngElement);

    // const selectedContainerElement = customElementPlease(SelectedContainerComponent, {
    //   injector: this.injector
    // });

    // customElements.define('selected-container', selectedContainerElement);
  }


  ngDoBootstrap() {

  }
}
export function customElementPlease(component, { injector }) {
  const factory = getComponentFactory(component, injector);
  const inputs = factory.inputs;
  const attributeToPropertyInputs = getDefaultAttributeToPropertyInputs(inputs);

  class NgElement extends HTMLElement {
    static observedAttributes = Object.keys(attributeToPropertyInputs);
    componentRef: ComponentRef<any>;
    subscription: Subscription;

    constructor() {
      super();
    }

    connectedCallback(): void {
      if (!this.componentRef) {
        this.componentRef = initializeComponent(this, component, injector);
      }

      const outputs = initializeOutputs(factory.outputs, this.componentRef.instance);

      this.subscription = outputs.subscribe(e => {
        const customEvent = createCustomEvent(this.ownerDocument, e.name, e.value);
        this.dispatchEvent(customEvent);
      });
    }

    getInputValue(name: string) {
      return this.componentRef.instance[name];
    }

    setInputValue(property, newValue) {

      if (property && newValue) {
        if (typeof this.componentRef.instance[property] === "string") {

          if(this[property]!=newValue){

            this.setAttribute(property, newValue);
          }
          // if (!this.hasAttribute(property)) {

          //   this.setAttribute(property, newValue);
          // }
          // else {

          //   this[property] = newValue
          // }

          this.componentRef.instance[property] = newValue;
        } else {

          if(this[property]!=newValue){
            
            this.setAttribute(property, newValue);
          }
          // if (!this.hasAttribute(property)) {

          //   this.setAttribute(property, newValue);
          // }
          // else {

          //   this[property] = newValue
          // }

          this.componentRef.instance[property] = JSON.parse(newValue);
        }
        this.componentRef.changeDetectorRef.detectChanges();
      }
    }

    attributeChangedCallback(
      attrName: string, oldValue: string | null, newValue: string): void {
      if (!this.componentRef) {
        this.componentRef = initializeComponent(this, component, injector);
      }
      const propName = attributeToPropertyInputs[attrName]!;
      if(oldValue!=newValue){
        this.setInputValue(propName, newValue);
      }
    }

    disconnectedCallback(): void {
      if (this.componentRef) {
        this.componentRef!.destroy();
        this.componentRef = null;
      }
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
    }
  }

  inputs.map(({ propName }) => propName).forEach(property => {
    Object.defineProperty(NgElement.prototype, property, {
      get: function () {
        return this.getInputValue(property);
      },
      set: function (newValue: any) {
        this.setInputValue(property, newValue);
      },
      configurable: true,
      enumerable: true,
    });
  });

  return NgElement;
}

function createCustomEvent(doc: Document, name: string, detail: any): CustomEvent {
  const bubbles = false;
  const cancelable = false;

  // On IE9-11, `CustomEvent` is not a constructor.
  if (typeof CustomEvent !== 'function') {
    const event = doc.createEvent('CustomEvent');
    event.initCustomEvent(name, bubbles, cancelable, detail);
    return event;
  }

  return new CustomEvent(name, { bubbles, cancelable, detail });
}

function camelToDashCase(input: string): string {
  return input.replace(/[A-Z]/g, char => `-${char.toLowerCase()}`);
}

function getComponentFactory(component, injector) {
  const componentFactoryResolver = injector.get(ComponentFactoryResolver);
  return componentFactoryResolver.resolveComponentFactory(component);
}

function getDefaultAttributeToPropertyInputs(
  inputs: { propName: string, templateName: string }[]) {
  const attributeToPropertyInputs: { [key: string]: string } = {};
  inputs.forEach(({ propName, templateName }) => {
    attributeToPropertyInputs[camelToDashCase(templateName)] = propName;
  });

  return attributeToPropertyInputs;
}

function initializeOutputs(outputs, instance): Observable<any> {
  const eventEmitters = outputs.map(({ propName, templateName }) => {
    const emitter = instance[propName] as EventEmitter<any>;
    return emitter.pipe(map((value: any) => ({ name: templateName, value })));
  });

  return merge(...eventEmitters);
}

function initializeComponent(element: HTMLElement, component, injector: Injector) {
  const childInjector = Injector.create({ providers: [], parent: injector });
  const componentFactory = getComponentFactory(component, injector);
  let componentRef = componentFactory.create(childInjector, [], element);
  componentRef.changeDetectorRef.detectChanges();
  const applicationRef = injector.get<ApplicationRef>(ApplicationRef);
  applicationRef.attachView(componentRef.hostView);

  return componentRef;
}
