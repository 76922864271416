import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'likefilter',
    pure: false
})
export class LikeFilterPipe implements PipeTransform {
    transform(items: any[]): any {
        if (!items) {
            return 0;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => item['feeling_status'] ===1).length;
    }
}