
import {Deserializable} from "./deserializable.model";
import { User } from "./User";
import { Lesson } from "./Lesson";
import { ElementFiles } from "./ElementFiles";
import {ElementText} from "./ElementText";

export class LessonDetail {

    lesson_detail__id:number;
    file:ElementFiles;
    title:ElementText;
    user_list:User[];
    lesson:Lesson;
    is_vedio:boolean =false;


    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }



