import { trigger, style, animate, transition, state,animation, keyframes ,useAnimation} from '@angular/animations';

const jackIn = animation([
    transition('* => jackInTheBox', [

        style('*'),

        animate('{{timing}} {{delay}} ease-in',

          keyframes([
            style({
              transform: 'scale(0.1) rotate(30deg)',
              transformOrigin: 'center bottom',
              opacity: 0,
              offset: 0
            }),
            style({
              transform: 'rotate(-10deg)',
              opacity: 0.7,
              offset: 0.5
            }),
            style({ transform: 'rotate(3deg)', offset: 0.7 }),
            style({ transform: 'scale(1)', opacity: 1, offset: 1 })
          ])

        )], { params: { timing: '1s', delay: '' }}
      )
  ]);
   const jackOut = animation([
    // just hide it
  ]);

  export const flipIn = animation([
    animate(
      "{{time}} cubic-bezier(0.785, 0.135, 0.15, 0.86)",
      keyframes([
        style({
          opacity: 1,
          transform: "perspective(400px) rotate3d(1, 0, 0, 90deg)",
          offset: 0
        }), // start state
        style({ transform: "perspective(400px)", offset: 1 })
      ])
    )
  ]);

  export const flipOut = animation([
    // just hide it
  ]);

export const carousel = [
    trigger('fade', [
        state('in', style({ 'opacity': '1' })),
        state('out', style({ 'opacity': '0' })),
        transition('* <=> *', [
            style({ 'opacity': '0' }),
            animate(1000)
        ])
    ]),
    trigger('flyInOut', [
        state('in', style({ transform: 'translateX(0)' })),
        transition('void => in', [
            style({ transform: 'translateX(-100%)' }),
            animate(5000)
        ])
    ]),
    trigger("slideAnimation", [

        transition("in => out", [
            useAnimation(flipIn, { params: { time: "700ms" } })
          ]),
        transition("out => in", [
             useAnimation(flipOut, { params: { time: "700ms" } })
           ])

    ])
];
