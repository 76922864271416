
import {Deserializable} from "./deserializable.model";
import { User } from "./User";
export class AssessmentsAnswers {

  assessmentsData_id:number;
  assessment_no:string;
  form_no:string;
  answers:string;
  answered_date:Date;
  answered_by:User;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
      }
  }
